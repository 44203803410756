import React from 'react';
import DropdownSelect from '../../dropdown/Dropdown';
import TextInput from '../../UIComponent/TextInput';
import ValidationText from '../../../utils/validation/ValidationText';

function CompanyInformation(props) {
    return (
        <>
            <div className="p-[25px]">
                <div className="grid grid-cols-12 gap-6 w-full ">
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                        <div className="grid grid-cols-12 gap-6 w-full ">
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                        <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                                            Company Name
                                        </h2>
                                        <div className="flex items-center">
                                            <TextInput
                                                placeholder=""
                                                name="companyName"
                                                value={props.companyInformation.companyName}
                                                identity="Company_Information"
                                                handleChange={props.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                        <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                                            Brand
                                            {/* <span className="text-[#AA3361]">*</span> */}
                                        </h2>
                                        <div className="flex items-center">
                                            <TextInput
                                                placeholder=""
                                                name="brand"
                                                value={props.companyInformation.brand}
                                                identity="Company_Information"
                                                handleChange={props.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                        <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                                            Designation
                                            {/* <span className="text-[#AA3361]">*</span> */}
                                        </h2>
                                        <div className="flex items-center">
                                            <TextInput
                                                placeholder=""
                                                name="designation"
                                                value={props.companyInformation.designation}
                                                identity="Company_Information"
                                                handleChange={props.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                        <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                                            Job Position <span className="text-[#AA3361]">*</span>
                                        </h2>
                                        <DropdownSelect
                                            // disabled={true}
                                            drpIdentity={"Job_Position"}
                                            optionArray={props.jobPositionType}
                                            setFilterParameters={props.setFilterParameters}
                                            value={props.companyInformation.jobPositionId}
                                        />
                                        <ValidationText error={props.validStateCompanyInformation.error.jobPositionId} />
                                    </div>
                                </div>
                            </div>
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                <div className="grid grid-cols-12 gap-6">
                                    {props.isIndividualMemberId !== "Ordinary Member" && props.isIndividualMemberId !== "Associate Member" &&
                                        <div className="col-span-12">
                                            <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                                                Industry
                                                {/* <span className="text-[#AA3361]">*</span> */}
                                            </h2>
                                            <TextInput
                                                placeholder=""
                                                name="industry"
                                                value={props.companyInformation.industry}
                                                identity="Company_Information"
                                                handleChange={props.handleChange}
                                            />
                                            {/* <DropdownSelect
                                            // disabled={true}
                                            drpIdentity={"Industry_Type"}
                                            optionArray={props.industryType}
                                            setFilterParameters={props.setFilterParameters}
                                            value={props.companyInformation.industryTypeId}
                                        /> */}
                                            {/* <ValidationText error={props.validStateCompanyInformation.error.industry} /> */}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="col-span-12">
                                        <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                                            A brief description of your company
                                        </h2>
                                        <textarea
                                            id="default"
                                            className="signup-text-area form-input rounded-none w-full shadow-red  text-lg"
                                            name="companyDiscription"
                                            rows="4"
                                            value={props.companyInformation.companyDiscription}
                                            onChange={(event) =>
                                                props.handleChange(event, "Company_Information")
                                            }
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="xl:col-span-12 lg:col-span-12 col-span-12 mt-[30px]">
                                <div className="flex items-center justify-end">

                                    <button
                                        className="btn btn-pink text-xl text-white font-semibold"
                                        onClick={() => props.addEditIndividualCompanyInformation()}
                                    >
                                        Save
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyInformation