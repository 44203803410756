import React from "react";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";
import DatePicker from "../../datePicker/DatePicker";
import moment from "moment";
import DropdownSelect from "../../dropdown/Dropdown";
import TextInput from "../../UIComponent/TextInput";
import { Selector } from "../../allTypesInputComponents/AllTypeInputComponents";
import ValidationText from "../../../utils/validation/ValidationText";

function CompanyProfile(props) {

  // let GetToKnowflag = false;
  // if (props.getToKnowUs.length > 0 && props?.companyProfile.knowAboutUsId > 0) {
  //   let arr2 = props?.getToKnowUs?.find((arr) => arr.id === props?.companyProfile.knowAboutUsId);
  //   GetToKnowflag = (arr2.name === "Other channel, please specify") ? true : false;

  // }

  return (
    <>

      <div className="p-[25px]">
        <div className="grid grid-cols-12 gap-6 w-full">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="grid grid-cols-12 gap-6 w-full ">
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                      Registered Company Name

                      <span className="text-[#AA3361]"> *</span>
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        name="companyName"
                        value={props.companyProfile.companyName}
                        identity="Company_Profile"
                        handleChange={props.handleChange}
                        onBlur={(e) => props.validateField("companyName", "Company_Profile")}
                      // disabled={true}
                      // isCurserAllowed={false}
                      />
                    </div>
                    <ValidationText error={props.validStateCompanyProfile.error.companyName} />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                      Brand Name
                      {/* <span className="text-[#AA3361]">*</span> */}
                    </h2>
                    <div className="flex items-center">
                      <TextInput
                        placeholder=""
                        name="brandName"
                        value={props.companyProfile.brandName}
                        identity="Company_Profile"
                        handleChange={props.handleChange}
                      // onBlur={(e) =>
                      //   props.validateField("brandName", "Company_Profile")
                      // }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                      Company Type
                      <span className="text-[#AA3361]"> *</span>
                    </h2>
                    <DropdownSelect
                      // disabled={true}
                      drpIdentity={"Company_Type"}
                      optionArray={props.companyType}
                      setFilterParameters={props.setFilterParameters}
                      value={props.companyProfile.companyTypeId}
                    // disabled
                    />
                    <ValidationText error={props.validStateCompanyProfile.error.companyTypeId} />
                  </div>
                </div>
              </div>
              {
                props && props.companyProfile.companyTypeId === 2 ?
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                          Business Registration Number <span className="text-[#AA3361]"> *</span>
                        </h2>
                        <div className="flex items-center">
                          <TextInput
                            placeholder=""
                            name="businessRegistrationNo"
                            value={props.companyProfile.businessRegistrationNo}
                            identity="Company_Profile"
                            handleChange={props.handleChange}
                            onBlur={(e) => props.validateField("businessRegistrationNo", "Company_Profile")}
                          // disabled={true}
                          // isCurserAllowed={false}
                          />
                        </div>
                        <ValidationText error={props.validStateCompanyProfile.error.businessRegistrationNo} />
                      </div>
                    </div>
                  </div>
                  :
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                          Company UEN <span className="text-[#AA3361]"> *</span>
                        </h2>
                        <div className="flex items-center">
                          <TextInput
                            placeholder=""
                            name="companyUEN"
                            value={props.companyProfile.companyUEN}
                            identity="Company_Profile"
                            handleChange={props.handleChange}
                            onBlur={(e) => props.validateField("companyUEN", "Company_Profile")}
                          // disabled={true}
                          // isCurserAllowed={false}
                          />
                        </div>
                        <ValidationText error={props.validStateCompanyProfile.error.companyUEN} />
                      </div>
                    </div>
                  </div>
              }
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <div className="grid grid-cols-12 gap-6">
                  <div className="col-span-12">
                    <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                      Incorporation Date
                      <span className="text-[#AA3361]"> *</span>
                    </h2>
                    <div className="">
                      <DatePicker
                        name="incorporationDate"
                        value={props.companyProfile.incorporationDate ? moment(props.companyProfile.incorporationDate).format("YYYY-MM-DD") : ''}
                        identity="Company_Profile"
                        handleChange={props.handleChange}
                        onBlur={() => props.validateField("incorporationDate", "Company_Profile")}
                      />
                    </div>
                    <ValidationText error={props.validStateCompanyProfile.error.incorporationDate} />
                  </div>
                </div>
              </div>
              {
                props && props.companyProfile.companyTypeId !== 1 ?
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-12">
                        <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                          Country of Incorporation
                          <span className="text-[#AA3361]"> *</span>
                        </h2>
                        {/* <MultiSelectDropdown
                      drpIdentity="Incorporation_Country"
                      options={props.countries}
                      // isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      onChange={props.handleChangeSelectorDropdown}
                      allowSelectAll={false}
                      value={props.countries.filter(
                        (country) =>
                          country.value ===
                          props.companyProfile
                            .countryOfIncorporationId
                      )}
                    /> */}
                        <DropdownSelect
                          // disabled={true}
                          drpIdentity={"Incorporation_Country"}
                          optionArray={props.countries}
                          setFilterParameters={props.setFilterParameters}
                          value={props.companyProfile.countryOfIncorporationId}
                        />
                        <ValidationText error={props.validStateCompanyProfile.error.countryOfIncorporationId} />
                      </div>
                    </div>
                  </div> : null}
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Company Address Line 1
                  <span className="text-[#AA3361]"> *</span>
                </h2>
                <div className="flex items-center">
                  <TextInput
                    placeholder=""
                    name="companyAddress1"
                    value={props.companyProfile.companyAddress1}
                    identity="Company_Profile"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validateField("companyAddress1", "Company_Profile")}
                  />
                </div>
                <ValidationText error={props.validStateCompanyProfile.error.companyAddress1} />
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Company Address Line 2
                  {/* <span className="text-[#AA3361]"> *</span> */}
                </h2>
                <div className="flex items-center">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="companyAddress2"
                    value={props.companyProfile.companyAddress2}
                    identity="Company_Profile"
                    handleChange={props.handleChange}
                  // onBlur={(e) => props.validateField("companyAddress2", "Company_Profile")}
                  />
                </div>
                {/* <ValidationText error={props.validStateCompanyProfile.error.companyAddress2} /> */}
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Postal Code
                  <span className="text-[#AA3361]"> *</span>
                </h2>
                <div className="flex items-center">
                  <TextInput
                    placeholder=""
                    type="text"
                    name="postalCode"
                    value={props.companyProfile.postalCode}
                    identity="Company_Profile"
                    handleChange={props.handleChange}
                    onBlur={(e) => props.validateField("postalCode", "Company_Profile")}
                  />
                </div>
                <ValidationText error={props.validStateCompanyProfile.error.postalCode} />
              </div>

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Country
                  <span className="text-[#AA3361]"> *</span>
                </h2>
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"Select_Country"}
                  optionArray={props.countries}
                  setFilterParameters={props.setFilterParameters}
                  value={props.companyProfile.countryId}
                />
                <ValidationText error={props.validStateCompanyProfile.error.countryId} />
              </div>

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Company Website
                  <span className="text-[#AA3361]"> *</span>
                </h2>
                <TextInput
                  placeholder=""
                  type="text"
                  name="website"
                  value={props.companyProfile.website}
                  identity="Company_Profile"
                  handleChange={props.handleChange}
                  onBlur={(e) => props.validateField("website", "Company_Profile")}
                />
                <ValidationText error={props.validStateCompanyProfile.error.website} />
              </div>

              {
                props && props.companyProfile.companyTypeId === 2 ?
                  null
                  :
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                      ACRA
                      <span className="text-[#AA3361]"> *</span>
                    </h2>
                    <form className="custom-uploader custom-file-upload-btn flex">
                      <div className=' font-bold whitespace-nowrap pt-2'>
                        <label
                          htmlFor={`Upload_ACRA_File`}
                          className="text-uplod text-pink2 cursor-pointer py-2 px-4 btn-pink-border"
                        >
                          Upload File
                        </label>
                        <input
                          className="sr-only"
                          // key={`FileQuestion${key}`}
                          id={`Upload_ACRA_File`}
                          type="file"
                          onChange={(event) => props.onFileChange(event, "Upload_ACRA_File")}
                        />
                      </div>
                    </form>
                    {
                      props && props.companyProfile.aCRAFilePath ?
                        (
                          <div className='mt-5 file-uploaded-txt flex items-center'>
                            <img src='/check-orange.png' className='w-[25px] mr-3' alt=''></img>
                            <span className='text-[#181818]'>
                              {props.companyProfile.aCRAFilePath}
                            </span>
                          </div>
                        )
                        :
                        null
                    }
                    {props && props.companyProfile.aCRAFilePath ?
                      < div
                        className='text-[#cd0000] xl:text-lg ml-5 underline cursor-pointer view_pdf'
                        onClick={() => props.downloadPdf()}
                      >
                        View PDF
                      </div>
                      : null}
                    <div className=" text-center">
                      <ValidationText error={props.validStateCompanyProfile.error.aCRAFilePath} />
                    </div>
                  </div>
              }
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  A brief description of your company
                  <span className="text-[#AA3361]"> *</span>
                </h2>
                <textarea
                  id="default"
                  className="signup-text-area form-input rounded-none w-full shadow-red h-auto text-lg"
                  name="description"
                  rows="5"
                  value={props.companyProfile.description}
                  onChange={(event) => props.handleChange(event, "Company_Profile")}
                  onBlur={(e) => props.validateField("description", "Company_Profile")}
                />
                <ValidationText error={props.validStateCompanyProfile.error.description} />
                <span className="text-right text-[16px] text-[#181818] font-medium block">0 / 100 words</span>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Company Annual Sales Turnover
                  <span className="text-[#AA3361]"> *</span>
                </h2>
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"Company_Annual_Turnover"}
                  optionArray={props.annualTurnOver}
                  setFilterParameters={props.setFilterParameters}
                  value={props.companyProfile.annualSalesTurnoverId}
                />
                <ValidationText error={props.validStateCompanyProfile.error.annualSalesTurnoverId} />
              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Employment Size
                  <span className="text-[#AA3361]"> *</span>
                </h2>
                <DropdownSelect
                  // disabled={true}
                  drpIdentity={"Employment_Size"}
                  optionArray={props.companyEmployeementSize}
                  setFilterParameters={props.setFilterParameters}
                  value={props.companyProfile.employmentSizeId}
                />
                <ValidationText error={props.validStateCompanyProfile.error.employmentSizeId} />
              </div>

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Types of Business / Services of your company
                  <span className="text-[#AA3361]"> *</span> {"(You can select more than one option) "}
                </h2>
                <Selector
                  // disabled={true}
                  isMulti
                  drpIdentity="businessTypeId"
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  options={props.companyBusinessType}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={true}
                  //setFilterParameters={props.setFilterParameters}
                  value={props.companyProfile.businessTypeIds}
                />
                <ValidationText error={props.validStateCompanyProfile.error.businessTypeIds} />
              </div>

              {
                props && props.companyProfile.isOtherBusinessType ?


                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                      Other Types of Business / Services of your company
                      <span className="text-[#AA3361]"> *</span>
                    </h2>
                    <TextInput
                      placeholder=""
                      type="text"
                      name="otherBusinessTypeIds"
                      value={props.companyProfile.otherBusinessTypeIds}
                      identity="Company_Profile"
                      handleChange={props.handleChange}
                      onBlur={(e) => props.validateField("otherBusinessTypeIds", "Company_Profile")}
                    />
                    <ValidationText error={props.validStateCompanyProfile.error.otherBusinessTypeIds} />
                  </div>
                  :
                  null
              }

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  What is your company interested in?
                  <span className="text-[#AA3361]"> *</span> {"(You can select more than one option) "}
                </h2>
                <Selector
                  // disabled={true}
                  isMulti
                  drpIdentity={"interestId"}
                  options={props.companyInterestType}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={true}
                  // setFilterParameters={props.setFilterParameters}
                  value={props.companyProfile.interestIds}
                />
                <ValidationText error={props.validStateCompanyProfile.error.interestIds} />
              </div>
              {
                props && props.companyProfile.isOtherCompanyInterested ?

                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                      Other interests
                      <span className="text-[#AA3361]"> *</span>
                    </h2>
                    <TextInput
                      placeholder=""
                      type="text"
                      name="otherCompanyIntereststed"
                      value={props.companyProfile.otherCompanyIntereststed}
                      identity="Company_Profile"
                      handleChange={props.handleChange}
                      onBlur={(e) => props.validateField("otherCompanyIntereststed", "Company_Profile")}
                    />
                    <ValidationText error={props.validStateCompanyProfile.error.otherCompanyIntereststed} />
                  </div>
                  :
                  null
              }

              <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  How did you hear about us?
                  <span className="text-[#AA3361]"> *</span>
                </h2>
                <Selector
                  // disabled={true}
                  // isMulti
                  drpIdentity={"knowAboutUsId"}
                  options={props.getToKnowUs}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  onChange={props.handleChangeMultiDropdown}
                  allowSelectAll={false}
                  // setFilterParameters={props.setFilterParameters}
                  value={props.getToKnowUs.filter(
                    (knowUs) =>
                      knowUs.value ===
                      props.companyProfile
                        .knowAboutUsId
                  )}
                // value={props.companyProfile.interestIds}
                />
                {/* <DropdownSelect
                  // disabled={true}
                  drpIdentity={"knowAboutUsId"}
                  optionArray={props.getToKnowUs}
                  setFilterParameters={props.setFilterParameters}
                  value={props.companyProfile.knowAboutUsId}
                /> */}
                <ValidationText error={props.validStateCompanyProfile.error.knowAboutUsId} />
              </div>

              {
                props && props.selectKnowAboutUsOthers ?

                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                      Other Channels
                      <span className="text-[#AA3361]"> *</span>
                    </h2>
                    <TextInput
                      placeholder=""
                      type="text"
                      name="knowAboutUsOthers"
                      value={props.companyProfile.knowAboutUsOthers}
                      identity="Company_Profile"
                      handleChange={props.handleChange}
                    // onBlur={(e) => props.validateField("knowAboutUsOthers", "Company_Profile")}
                    />
                    <ValidationText error={props.validStateCompanyProfile.error.knowAboutUsOthers} />
                  </div>
                  :
                  null
              }


              <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                <div className="flex items-center justify-end">
                  {props && props.isLoading === true ? (
                    <ButtonLoader />
                  ) : (
                    <button
                      className="btn btn-pink text-xl text-white font-semibold"
                      onClick={() => props.onSave()}
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="xl:col-span-12 md:col-span-12 col-span-12 2xl:mt-5 lg:mt-5 mt-5">
              <PointofContactInformation
                pointOfContactInformation={props.corporateMemberDetail.pointOfContactInformation}
                handleChange={props.handleChange.bind(this)}
                setFilterParameters={props.setFilterParameters.bind(this)}
                salutations={props.salutation}
                designations={props.designations}
                // organisationRoles={props.organisationRoles}
                validStatePointofContactInformation={props.validStatePointofContactInformation}
                validateField={props.validateField} />
            </div>
            <div className="xl:col-span-12 md:col-span-12 col-span-12 2xl:mt-5 lg:mt-5 mt-5">
              <FinanceDepartmentContact
                financeDepartment={props.corporateMemberDetail.financeDepartment}
                setFilterParameters={props.setFilterParameters.bind(this)}
                handleChange={props.handleChange.bind(this)}
                salutations={props.salutation}
                designations={props.designations}
                validStateFinanceDepartmentContact={props.validStateFinanceDepartmentContact}
                validateField={props.validateField}
              />
            </div> */}

          </div>
        </div>
      </div >
    </>
  );
}
export default CompanyProfile;