import moment from "moment";
import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import DropdownSelect from "../../components/dropdown/Dropdown";
import DropdownAction from "../../components/dropdown/DropdownAction";
import Table from "../../components/table/Table";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import Checkbox from "../../components/UIComponent/Checkbox";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import { isNullString } from "../../utils/Utils";
import { saveAs } from "file-saver";
import { encryptAES } from "../../utils/Encryption";
import { CommonSuccessMessages } from "../../utils/Messages";
import { formatPrice } from "../../components/common/FormatePrice";

export default class CreditNoteApproval extends Component {
  constructor(props) {
    super(props);
    this.financeService = new FinanceServices();
    this.swalServices = new SwalServices();
    this.state = {
      creditNoteStatusList: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        creditNoteStatusId: 0,
        creditNoteNumber: "",
        approvalEmailFilter: "",
        
      },
      actionDropdownApproved: ["View Credit Note"],
      actionDropdownForSent: ["Closed"],
      route: "",
      creditNoteList: [],
      totalResults: 0,
      selectedIdList: [],
      checkedRow: false,
      checkedHeader: false,
      isRejected: true,
      isApprove: true,
      isLoading: false
    };
  }


  getAllFinanceCreditNoteList = () => {
    this.setState({ isLoading: true });
    this.financeService.getAllFinanceCreditNoteList(this.state.pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let data = response.responseItem.responseContent.itemList;
        data.map((x) => {
          // x.creditNoteTotalRefund = (x.creditNoteTotalRefund).toLocaleString('en-IN');
          x.creditNoteTotalRefund = formatPrice(x.creditNoteTotalRefund);
          x.creditNoteOpenCreditAmout=formatPrice(x.creditNoteOpenCreditAmout);
          x.creditNoteMonth = moment(x.creditNoteMonth).format("DD MMM YYYY");
          x.isSelected = false;
        });
        this.setState({ creditNoteList: data, totalResults: response.responseItem.responseContent.totalCount });
      } else {
        this.swalServices.Error("Something went wrong.");
      }
      this.setState({ isLoading: false });
    })
  }

  getFinanceCreditNoteStatusList = () => {
    this.financeService.getFinanceCreditNoteStatusList().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ creditNoteStatusList: response.responseItem.responseContent }, () => { this.getAllFinanceCreditNoteList() });
      } else {
        this.getAllFinanceCreditNoteList()
        this.swalServices.Error("Something went wrong.");
      }
    })
  }


  financeApproveRejectCreditNoteApproval = (identity) => {
    let isCheckForApproval = true;
    let request = {
      updatedBy: 1,
      updatedAt: moment().format("yyyy-MM-DD"),
      updatedAppId: 114,
      financeCreditNoteId: []
    };
    if (identity === 'Reject') {
      request.creditNoteStatusId = 6;
    } else if (identity === 'Approve') {
      request.creditNoteStatusId = 2;
    }
    let selectedIdList = this.state.creditNoteList.filter(
      (get) => get.isSelected === true
    );
    selectedIdList = selectedIdList.map((p) => (p.financeCreditNoteId));
    request.financeCreditNoteId = [...selectedIdList];
    let cookie = getAuthProps();
    const adminUserName = (cookie?.userName) ? cookie.userName : '';
    if (adminUserName) {
      this.state.creditNoteList.map((x) => {
        if (x.isSelected === true) {
          if (x.approvalEmail.toLowerCase() !== adminUserName.toLowerCase()) {
            isCheckForApproval = false;
          }
        }
      });
    }
    this.swalServices
      .Confirm(
        // CommonSuccessMessages.Confirm_Question_Approve.replace("{0}", "Credit Note"),
        identity === 'Approve' ? CommonSuccessMessages.Confirm_Question_Approve.replace("{0}", "Credit Note") :
          CommonSuccessMessages.Reject_Question.replace("{0}", "Credit Note"),

        identity === 'Approve' ? CommonSuccessMessages.Confirm_Approve.replace("{0}", "Credit Note") :
          CommonSuccessMessages.Confirm_Reject.replace("{0}", "Credit Note"),

        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          if (isCheckForApproval) {
            this.financeService.financeApproveRejectCreditNoteApproval(request).then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({ isRejected: true, isApprove: true }, () => {
                  if (identity === 'Approve') {
                    selectedIdList.forEach(creditNoteId => {
                      this.getFinanceCreditNoteById(creditNoteId);
                    });
                    this.swalServices.Success("Selected credit note are approved");
                  } else {
                    this.getAllFinanceCreditNoteList()
                    this.swalServices.Success("Selected credit note are rejected");
                  }
                });
                //this.getAllFinanceCreditNoteList();
              } else {
                this.swalServices.Error("Something went wrong.");
              }
            })
          }
          else {
            let creditNoteNos = '';
            this.state.creditNoteList.map((x) => {
              if (x.isSelected === true) {
                if (x.approvalEmail !== adminUserName) {
                  let email = x.approvalEmail;
                  creditNoteNos = creditNoteNos.concat(`${email}, `);
                }
              }
            });
            this.swalServices.Error(`you are not authorized to approve or reject these credit notes ${creditNoteNos}.`);
          }
        }
      });
  }


  getFinanceCreditNoteById = (creditNoteId) => {
    this.financeService.getFinanceCreditNoteById(creditNoteId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        if (response.responseItem.responseContent && response.responseItem.responseContent.creditNoteTotalRefund === 0) {
          let data = response.responseItem.responseContent
          this.updateCreditNote(data)
        }
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }


  updateCreditNote = (data) => {
    let request = {
      financeCreditNoteId: data.financeCreditNoteId,
      creditNoteOpenCreditAmout: data.creditNoteOpenCreditAmout ? data.creditNoteOpenCreditAmout : 0,
      creditNoteStatusId: 5,
      creditNoteRefundAmount: 0,
      creditNoteRefundMethod: "",
      bankNameId: 0,
      OffsetInvoiceId: 0,
      invoiceNoForCreditNote: "",
      creditNoteOffsetInvoiceAmount: 0,
      createdBy: 1,
      createdAppId: 114,
      createdAt: new Date(),
      membershipPaymentTypeId: data.membershipPaymentTypeId,
      refundDate: new Date(),
      refundComment: ""
    };

    this.setState({ isLoaderUpdateCreditNote: true }, () => {
      this.financeService.financeUpdateCreditNote(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          // let data = response.responseItem.responseContent
        } else {
          this.swalServices.Error(response.message);
        }
      });
    });
  }



  financeUpdateStatusForCreditNote = (value) => {
    let request = {
      financeCreditNoteId: value,
      creditNoteStatusId: 5,
    };
    this.financeService.financeUpdateStatusForCreditNote(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Credit note status updated.");
        this.getAllFinanceCreditNoteList();
      } else {
        this.swalServices.Error("Something went wrong.");
      }
    })
  }
  financeGetCreditNoteInvoice = (value) => {
    let request = [];
    request.push(value);
    if (isNullString(value) !== "") {
      this.financeService.financeGetCreditNoteInvoice(request).then((response) => {
        if (response.data !== null && response.status === 200) {
          let formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")

          let filename = "";
          filename = `Credit-Note-${formattedDate}`;

          let file = new Blob([response.data], { type: "application/pdf" });

          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");

          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
    else {
      this.swalServices.Error("No Invoice is Available");
    }
  }

  componentDidMount() {
    this.getFinanceCreditNoteStatusList();
  }

  handleChange = (event) => {
    let { name, value } = event.target;
    let detail = { ...this.state.pagination };
    detail[name] = value;
    this.setState({ pagination: detail });
  }

  handleChangeInDropdown = (id, drpIdentity) => {
    let detail = { ...this.state.pagination };
    detail.creditNoteStatusId = id;
    this.setState({ pagination: detail });
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination }, () => { this.getAllFinanceCreditNoteList() });
  };

  actionClick = (index, value, option, event) => {
    if (option === "View Credit Note" || option === "Processing") {
      if (value && value > 0) {
        this.setState({
          route: `/financial/CreditNoteManagement/CreditNoteId?=${encryptAES(value)}`,
        });
      }
    }
    if (option === "Reject") {
      this.financeRejectCreditNoteApproval(value);
    }
    if (option === "Closed") {
      this.financeUpdateStatusForCreditNote(value);
    }
  };

  actions = (element, index, value) => {
    return element !== "financeCreditNoteId" ? null : (
      <td>
        <div className="relative w-full credit-note-action-dropdown">
          <ActionContainer>
            <>
              {(this.state.creditNoteList.find(x => x.financeCreditNoteId === value)).financeCreditNoteStatus === 'Pending Approval' ?
                // <DropdownAction
                //   disabled={true}
                // />
                <DropdownAction
                  key={index}
                  value={value}
                  id={index}
                  index={index}
                  options={this.state.actionDropdownApproved}
                  align="right"
                  onOptionClick={this.actionClick.bind(this)}
                />
                :
                (<>
                  {(this.state.creditNoteList.find(x => x.financeCreditNoteId === value)).financeCreditNoteStatus === 'Sent' ?
                    <DropdownAction
                      key={index}
                      value={value}
                      id={index}
                      index={index}
                      options={this.state.actionDropdownForSent}
                      align="right"
                      onOptionClick={this.actionClick.bind(this)}
                    />
                    :
                    (<>
                      {(this.state.creditNoteList.find(x => x.financeCreditNoteId === value)).financeCreditNoteStatus === 'Rejected' ?
                        <DropdownAction
                          disabled={true}
                        />
                        :
                        (<>
                          <DropdownAction
                            key={index}
                            value={value}
                            id={index}
                            index={index}
                            options={this.state.actionDropdownApproved}
                            align="right"
                            onOptionClick={this.actionClick.bind(this)}
                          />
                        </>)
                      }
                      {/* <DropdownAction
                      key={index}
                      value={value}
                      id={index}
                      index={index}
                      options={this.state.actionDropdownApproved}
                      align="right"
                      onOptionClick={this.actionClick.bind(this)}
                    /> */}
                    </>)
                  }
                </>)
              }
            </>
          </ActionContainer>
        </div>
      </td>
    );
  };

  select = (element, index, value) => {
    return element !== "selectRow" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575]">
        <input
          name="isRequireQuotation"
          type="checkbox"
          className="cursor-pointer h-6 w-6 rounded check-box-custom"
          onChange={() => this.selectSingleRows(value)}
          checked={this.state.creditNoteList[index].isSelected === true}
        />
      </td>
    );
  };

  selectSingleRows = (value) => {
    let getAllRows = [...this.state.creditNoteList];
    let check = getAllRows.find(x => x.financeCreditNoteId === value)
    let checkedHeader = true;
    if (check.isSelected) {
      getAllRows.map((x) => {
        if (x.financeCreditNoteId === value) {
          x.isSelected = false;
        }
      })
      this.setState({ isRejected: true, isApprove: true });
    } else {
      getAllRows.map((x) => {
        if (x.financeCreditNoteId === value) {
          x.isSelected = true;
        }
      })
    }
    let isCheck = false;
    getAllRows.map((x) => {
      if (x.isSelected === true) {
        if (x.financeCreditNoteStatus === 'Approved' || x.financeCreditNoteStatus === 'Rejected' || x.financeCreditNoteStatus === 'Closed' || x.financeCreditNoteStatus === 'Sent') {
          isCheck = true;
        } else {
          this.setState({ isRejected: false, isApprove: false });
        }
      } else {
        checkedHeader = false;
      }
    })

    if (isCheck) {
      this.setState({ isRejected: isCheck, isApprove: isCheck });
    }
    this.setState({ creditNoteList: getAllRows, checkedHeader: checkedHeader });
  }

  selectAllRows = () => {
    let getAllRows = [...this.state.creditNoteList];
    let isAnyApproveReject = 0;
    if (this.state.checkedHeader) {
      getAllRows.map((item) => {
        item.isSelected = false;
      });
      this.setState({ creditNoteList: getAllRows, checkedHeader: false });
    } else {
      getAllRows.map((item) => {
        item.isSelected = true;
        if (item.financeCreditNoteStatus === 'Approved' || item.financeCreditNoteStatus === 'Rejected') {
          isAnyApproveReject += 1;
        }
      });
      if (isAnyApproveReject > 0) {
        this.setState({ isRejected: true, isApprove: true });
      } else {
        this.setState({ isRejected: false, isApprove: false });
      }
      this.setState({ creditNoteList: getAllRows, checkedHeader: true });
    }
  }

  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section finance-body-sec">
        <div className="bg-white pb-2 mt-10">
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10">
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl theme-color mb-2 font-bold">Status</h2>
              <DropdownSelect
                drpIdentity="Status"
                optionArray={this.state.creditNoteStatusList}
                setFilterParameters={this.handleChangeInDropdown.bind(this)}
                value={this.state.pagination.creditNoteStatusId}
              />
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl theme-color mb-2 font-bold">Credit Note Number</h2>
              <input
                id="default"
                name="creditNoteNumber"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.pagination.creditNoteNumber}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl theme-color mb-2 font-bold">Search</h2>
              <input
                id="default"
                name="searchText"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.pagination.searchText}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
              <h2 className="text-xl theme-color mb-2 font-bold">Approval Email</h2>
              <input
                id="default"
                name="approvalEmailFilter"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                value={this.state.pagination.approvalEmailFilter}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6 w-full items-end px-8 pb-12">
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              <div className="mt-8 flex items-center justify-end w-full">
                <button className="btn btn-pink text-xl border text-white "
                  onClick={() => { this.getAllFinanceCreditNoteList() }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Table Section Start */}
        <div className="mt-10 credit-note-approval-table">
          <Table
            columns={[
              { name: "selectRow", title: <Checkbox handleCheckbox={() => this.selectAllRows()} checkedHeader={this.state.checkedHeader} /> },
              { name: "creditNoteNumber", title: "Credit Note Number" },
              { name: "invoiceNum", title: "Invoice Number" },
              { name: "eventName", title: "Event Name" },
              { name: "companyName", title: "Company Name" },
              { name: "contactPerson", title: "Contact Person" },
              { name: "creditNoteTotalRefund", title: "Credit Amount" },
              { name: "creditNoteMonth", title: "Created Date" },
              { name: "creditNoteOpenCreditAmout", title: "Open Credit" },
              { name: "financeCreditNoteStatus", title: "Status" },
              { name: "approvalEmail", title: "Approval Email" },
              { name: "financeCreditNoteId", title: "Actions" },
            ]}
            rows={this.state.creditNoteList}
            isLoading={this.state.isLoading}
            sortingColumns={["invoiceNum","eventName","creditNoteMonth","financeCreditNoteStatus"]}
            pagination={this.state.pagination}
            totalResultes={this.state.totalResults}
            totalCount={this.state.totalResults}
            setPagination={this.setPagination.bind(this)}
            customScope={[
              {
                column: "financeCreditNoteId",
                renderTableData: this.actions.bind(this),
              },
              {
                column: "selectRow",
                renderTableData: this.select.bind(this),
                valueColumnName: "financeCreditNoteId",
              },
            ]}
            isRequiredButton1={true}
            buttonText1="Reject"
            button1Click={() => { this.financeApproveRejectCreditNoteApproval("Reject") }}
            button1Disable={this.state.isRejected}
            isRequiredButton2={true}
            buttonText2="Approve"
            button2Click={() => { this.financeApproveRejectCreditNoteApproval("Approve") }}
            button2Disable={this.state.isApprove}
          />
        </div>
        {/*Table Section End */}
      </div>
    );
  }
}
