import React from "react";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";

function Finance(props) {
  return (
    <>
      <div className="p-[25px]">
        <div className="w-full ">
          <label
            className="text-xl text-[#181818] 2xl:mb-1  font-medium flex items-center min-h-30"
            htmlFor="reminderEmail"
          >
            Send Reminder Email for Invoice
            {/* <span className="text-[#AA3361]">*</span>{" "} */}
          </label>
          <div className="flex items-center justify-start">
            <div className="my-3 mr-20">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="sendInvoiceReminder"
                  id="sendInvoiceReminder"
                  className="form-radio"
                  value={true}

                  onChange={(event) =>
                    props.handleRadioButtonsFinanceCard(event)
                  }
                  checked={
                    props.corporateFinance
                      .sendInvoiceReminder === "true"
                  }
                />
                <span className="text-xl  font-medium text-[#181818] ml-2">Yes</span>
              </label>
            </div>

            <div className="m-3">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="sendInvoiceReminder"
                  id="sendInvoiceReminder"
                  className="form-radio"
                  value={false}
                  onChange={(event) =>
                    props.handleRadioButtonsFinanceCard(event)
                  }
                  checked={
                    props.corporateFinance
                      .sendInvoiceReminder === "false"
                  }
                />
                <span className="text-xl  font-medium text-[#181818] ml-2">No</span>
              </label>
            </div>
          </div>
        </div>
        <div className="w-full mt-4">
          <label
            className="text-xl text-[#181818] 2xl:mb-1 font-medium flex items-center min-h-30"
            htmlFor="creditTermsId"
          >
            Credit Limit Term
            {/* <span className="text-[#AA3361]">*</span>{" "} */}
          </label>
          <div className="flex items-center justify-start">
            <div className="my-3 mr-10">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="creditTermsId"
                  id="creditTermsId"
                  className="form-radio"
                  value={1}
                  onChange={(event) =>
                    props.handleRadioButtonsFinanceCard(event)
                  }
                  checked={
                    props.corporateFinance
                      .creditTermsId === 1
                  }
                />
                <span className="text-xl  font-medium text-[#181818] ml-2">Immediate</span>
              </label>
            </div>

            <div className="my-3 mr-10">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="creditTermsId"
                  id="creditTermsId"
                  className="form-radio"
                  value={2}
                  onChange={(event) =>
                    props.handleRadioButtonsFinanceCard(event)
                  }
                  checked={
                    props.corporateFinance
                      .creditTermsId === 2
                  }
                />
                <span className="text-xl  font-medium text-[#181818] ml-2">7 days</span>
              </label>
            </div>
            <div className="my-3 mr-10">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="creditTermsId"
                  id="creditTermsId"
                  className="form-radio"
                  value={3}
                  onChange={(event) =>
                    props.handleRadioButtonsFinanceCard(event)
                  }
                  checked={
                    props.corporateFinance
                      .creditTermsId === 3
                  }
                />
                <span className="text-xl  font-medium text-[#181818] ml-2">14 days</span>
              </label>
            </div>
            <div className="my-3 mr-10">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="creditTermsId"
                  id="creditTermsId"
                  className="form-radio"
                  value={4}
                  onChange={(event) =>
                    props.handleRadioButtonsFinanceCard(event)
                  }
                  checked={
                    props.corporateFinance
                      .creditTermsId === 4
                  }
                />
                <span className="text-xl  font-medium text-[#181818] ml-2">30 days</span>
              </label>
            </div>
            <div className="my-3 mr-10">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="creditTermsId"
                  id="creditTermsId"
                  className="form-radio"
                  value={5}
                  onChange={(event) =>
                    props.handleRadioButtonsFinanceCard(event)
                  }
                  checked={
                    props.corporateFinance
                      .creditTermsId === 5
                  }
                />
                <span className="text-xl  font-medium text-[#181818] ml-2">60 days</span>
              </label>
            </div>
          </div>
          {/* Save Button */}
          <div className="grid grid-cols-12 gap-6 pb-10">
            <div className="xl:col-span-12 lg:col-span-12 col-span-12 px-10">
              <a
                className="flex items-center justify-end"
              //href="/CreateCorporateMembers"
              >
                {
                  props && props.isLoading === true ?
                    (
                      <ButtonLoader />
                    )
                    :
                    (
                      <button
                        className="w-32 px-7 py-3 btn-sm btn-pink text-xl text-white font-semibold"
                        onClick={() => props.onSave()}
                      >
                        Save
                    </button>
                    )
                }
              </a>
            </div>
        </div>
        </div>
      </div>

    </>
  );
}

export default Finance;
