import PropTypes from "prop-types";
import moment from "moment";
import { default as ReactSelect } from "react-select";
import { multiselectDropdownCustomStyles } from "../allTypesInputComponents/CommonStyle";

/* All Types Of Input  Prasad - 09/08/2023 */

// Checkbox Input
export const CheckboxInput = (props) => {
    return (
        <input
            className={props.className ? props.className : `cursor-pointer text-[#e1e1e1] focus:ring-[#e1e1e1] rounded check-box-custom h-8 w-8 m-2 ${props.class}`}
            id={props.id}
            type="checkbox"
            name={props.name}
            value={props.value}
            checked={props.checked}
            onChange={(event) => props.handleChange(event, props.identity)}
            disabled={props.disabled === true ? true : false}
        />
    );
}

// Text Input
export const TextInput = (props) => {
    return (
        <input
            className={`appearance-none w-full  ${props.className} ${props.disabled && props.disabled === true ? 'bg-[#e9e9ea] cursor-not-allowed' : 'custom-input'}`}
            placeholder={props.placeholder}
            type="text"
            name={props.name}
            value={props.value}
            identity={props.identity ? props.identity : null}
            onChange={(event) => props.handleChange(event, props.identity)}
            onBlur={props.onBlur ? () => props.onBlur() : null}
            disabled={props.disabled === true ? true : false}
            autoComplete={props.autoComplete ? props.autoComplete : "off"}
        />
    );
}

// Date Input
export const DateInput = (props) => {
    return (
        <input
            className={props.className ? props.className : `w-full custom-input mt-5 full-width-input custom-mobile-icon-set text-left ${props.disabled && props.disabled === true ? 'bg-[#f2f2f2]' : 'form-input'}`}
            type="date"
            name={props.name}
            max={moment(new Date()).format("yyyy-MM-DD")}
            min="1900-01-01"
            value={props.value}
            identity={props.identity ? props.identity : null}
            onChange={(event) => props.handleChange(event, props.identity)}
            placeholder={props.placeholder ? props.placeholder : null}
            onBlur={props.onBlur ? () => props.onBlur() : null}
            disabled={props.disabled === true ? true : false}
        />
    );
}

export const MonthYear = (props) => {
    return (
        <input
            className={props.className ? props.className : `w-full custom-input mt-5 full-width-input custom-mobile-icon-set text-left ${props.disabled && props.disabled === true ? 'bg-[#f2f2f2]' : 'form-input'}`}
            type="month"
            name={props.name}
            // max={moment(new Date()).format("yyyy-MM")}
            min="01-1900"
            value={props.value}
            identity={props.identity ? props.identity : null}
            onChange={(event) => props.handleChange(event, props.identity)}
            placeholder={props.placeholder ? props.placeholder : null}
            onBlur={props.onBlur ? () => props.onBlur() : null}
            disabled={props.disabled === true ? true : false}
        />
    );
}
// Number Input
export const NumberInput = (props) => {
    return (
        <input
            className={`appearance-none w-full custom-input ${props.className}`}
            placeholder={props.placeholder}
            type="number"
            name={props.name}
            value={props.value}
            onChange={(event) => props.handleChange(event)}
            onBlur={props.onBlur ? () => props.onBlur() : null}
            disabled={props.disabled === true ? true : false}
            autoComplete={props.autoComplete ? props.autoComplete : "off"}
            maxLength={props.maxLength}
            onWheel={(e) => e.target.blur()}
            max={props.max}
            min={props.min}
        />
    );
}

// Password Input
export const PasswordInput = (props) => {
    return (
        <input
            className={`appearance-none w-full custom-input ${props.className}`}
            placeholder={props.placeholder}
            type="password"
            name={props.name}
            value={props.value}
            onChange={(event) => props.handleChange(event, props.identity)}
            onBlur={props.onBlur ? () => props.onBlur() : null}
            disabled={props.disabled === true ? true : false}
            autoComplete={props.autoComplete ? props.autoComplete : "off"}
        />
    );
}

// Radio Input
export const RadioButton = (props) => {
    return (
        <div className="signup-radio">
            <input
                type="radio"
                name={props.name}
                id={props.id}
                className={`form-radio ${props.className ? props.className : "form-radio-blue"}`}
                value={props.value}
                onChange={(event) => props.handleRadioButtons(event)}
                checked={props.checked}
            />
        </div>
    );
};

// Multiselect Dropdown
export const MultiSelectDropdown = (props) => {
    if (props.allowSelectAll) {
        return (
            <span
                className="d-inline-block custom-input "

                data-toggle="popover"
                data-trigger="focus"
                data-content="Please selecet account(s)"
            >
                <ReactSelect className="custom-checkbox-select"
                    styles={multiselectDropdownCustomStyles}
                    isDisabled={props.disabled ? true : false}
                    {...props}
                    options={[props.allOption, ...props.options]}
                    onChange={(selected) => {
                        if (
                            selected !== null &&
                            selected.length > 0 &&
                            selected[selected.length - 1].value === props.allOption.value
                        ) {
                            return props.onChange(props.options, props.drpIdentity);
                        }
                        return props.onChange(selected, props.drpIdentity);
                    }}
                    placeholder='Select'
                />
            </span>
        );
    }

    return (
        <span
            className="d-inline-block custom-input"
            data-toggle="popover"
            data-trigger="focus"
            data-content="Please selecet account(s)"
        >
            <ReactSelect className="custom-checkbox-select"
                styles={multiselectDropdownCustomStyles}
                isDisabled={props.disabled ? true : false}
                {...props}
                // options={[props.singleSelect, ...props.options]}
                onChange={(selected) => {
                    if (
                        selected !== null &&
                        selected.length > 0 &&
                        selected[selected.length - 1].value === props.allOption.value
                    ) {
                        return props.onChange(props.options, props.drpIdentity);
                    }
                    return props.onChange(selected, props.drpIdentity);
                }}
                placeholder='Select'
            />
        </span>
    );
};

MultiSelectDropdown.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};
MultiSelectDropdown.defaultProps = {
    allOption: {
        label: "Select all",
        value: "*"
    },
    singleSelect: {
        label: "Select",
        value: 0
    }
};

// Selector Dropdown
export const Selector = (props) => {
    if (props.allowSelectAll) {
        return (
            <span
                className=""
                data-toggle="popover"
                data-trigger="focus"
                data-content="Please selecet account(s)"
            >
                <ReactSelect className="custom-checkbox-select"
                    styles={multiselectDropdownCustomStyles}
                    {...props}
                    options={[props.allOption, ...props.options]}
                    onChange={(selected) => {
                        if (
                            selected !== null &&
                            selected.length > 0 &&
                            selected[selected.length - 1].value === props.allOption.value
                        ) {
                            return props.onChange(props.options, props.drpIdentity);
                        }
                        return props.onChange(selected, props.drpIdentity);
                    }}
                    placeholder='Select'
                />
            </span>
        );
    }

    return (
        <span
            className="d-inline-block  custom-input"
            data-toggle="popover"
            data-trigger="focus"
            data-content="Please selecet account(s)"
        >
            <ReactSelect className="custom-checkbox-select"
                styles={multiselectDropdownCustomStyles}
                {...props}
                options={[props.singleSelect, ...props.options]}
                onChange={(selected) => {
                    if (
                        selected !== null &&
                        selected.length > 0 &&
                        selected[selected.length - 1].value === props.allOption.value
                    ) {
                        return props.onChange(props.options, props.drpIdentity);
                    }
                    return props.onChange(selected, props.drpIdentity);
                }}
                placeholder='Select'
            />
        </span>
    );
};

Selector.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};
Selector.defaultProps = {
    allOption: {
        label: "Select all",
        value: "*"
    },
    singleSelect: {
        label: "Select",
        value: 0
    },
    numberSelect: {
        label: "0",
        value: 1
    }
};