import React from "react";
import { components } from "react-select";
import Selector from "../dropdown/commonDropdown/Selector";
import RadioButton from "./RadioButton";
import ValidationText from "../../utils/validation/ValidationText";
import ToggleButton from "../common/ToggleButton";
import DropdownSelect from "../dropdown/Dropdown";

const EditDetailsColumn2 = (props) => {
    return (
        <>
            <div className="text-[#181818] text-[18px]">
                <h2 className="text-[18px] text-[#181818] font-bold mb-2">
                    Entry Topic <span className="text-[#AA3361]">*</span> {"(You can select more than one option) "}
                </h2>
                <Selector
                    options={props.categoryEntry}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    // components={{
                    //     Option,
                    // }}
                    onChange={props.handleChangeInDropdown}
                    allowSelectAll={true}
                    value={props.categoryCampaignItem.selectedCategoryEntryIds}
                    drpIdentity="CategoryEntry"
                />

                <div>
                    <ValidationText
                        error={props.validState.error.selectedCategoryEntryIds}
                    />
                </div>
            </div>
            <div className="text-[#181818] text-[18px] ">
                <h2 className="font-bold mb-2">
                    Campaign <span className="text-[#AA3361]">*</span>
                </h2>
                <Selector
                    options={props.campaignList}
                    // isMulti
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // components={{
                    //   Option,
                    // }}
                    onChange={props.handleChangeInDropdown}
                    allowSelectAll={false}
                    value={props.campaignList.find(
                        (item) =>
                            item.value === props.categoryCampaignItem.selectedCampaignId
                    )}
                    drpIdentity="CampaignName"
                />
                {/* <Selector
                    options={props.campaignList}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                        Option,
                    }}
                    onChange={props.handleChangeInDropdown}
                    allowSelectAll={true}
                    value={props.categoryCampaignItem.selectedCampaignIds}
                    drpIdentity="CampaignName"
                /> */}
                <div>
                    <ValidationText
                        error={props.validState.error.selectedCampaignId}
                    />
                </div>
            </div>
            <div className="text-[#181818] text-[18px]">
                <h2 className="font-bold mb-2">
                    Meta Title
                </h2>
                <input
                    className="w-full"
                    placeholder=""
                    type="text"
                    name="metaTitle"
                    value={props.categoryCampaignItem.metaTitle ? props.categoryCampaignItem.metaTitle : ''}
                    onChange={(event) => props.handleChange(event)}
                />
            </div>
            <div className="text-[#181818] text-[18px]">
                <h2 className="font-bold mb-2">
                    Meta Keyword
                </h2>
                <input
                    className="w-full"
                    placeholder=""
                    type="text"
                    name="metaKeyword"
                    value={props.categoryCampaignItem.metaKeyword ? props.categoryCampaignItem.metaKeyword : ""}
                    onChange={(event) => props.handleChange(event)}
                />
            </div>
            <div className="text-[#181818] text-[18px]">
                <h2 className="font-bold mb-2">
                    Meta Description <span className="text-[#AA3361]">*</span>
                </h2>
                <textarea
                    className="w-full h-[120px]"
                    placeholder=""
                    id="meta_description"
                    name="metaDescription"
                    rows="2"
                    value={props.categoryCampaignItem.metaDescription ? props.categoryCampaignItem.metaDescription : ""}
                    onChange={(event) => props.handleChange(event)}
                    onBlur={() => props.validateField("metaDescription")}
                />
                <div>
                    <ValidationText
                        error={props.validState.error.metaDescription}
                    />
                </div>
            </div>

            <div className="text-[#181818] text-[18px]">
                {/* <ToggleButton
                    label="Featured"
                    setToggle={props.categoryCampaignItem.isFeatured}
                    onHandleToggle={props.filterByFeatured}
                    index={0}
                    identity="Featured"
                //disabled={this.state.checkFeatured}
                /> */}

                {/* <label className="text-sm text-[#181818]">
                    3 out of 7 slots left.
                </label> */}
            </div>
            <div className="text-[#181818] text-[18px]">
                <ToggleButton
                    label="Reminder Email"
                    setToggle={props.categoryCampaignItem.isReminderEmail}
                    onHandleToggle={props.filterByFeatured}
                    index={1}
                    identity="ReminderEmail"
                //disabled={this.state.checkFeatured}
                />
            </div>
            <div className="text-[#181818] text-xl">
                Visibility
                {/* <ToggleButton
                    label="Visibility"
                    setToggle={props.visibility}
                    onHandleToggle={props.filterByFeatured}
                    index={2}
                    identity="Visibility"
                //disabled={this.state.checkFeatured}
                /> */}
            </div>
            <div>
                <RadioButton
                    radioLabel="Hidden"
                    name="visibility-radio"
                    id="isHidden"
                    value={props.categoryCampaignItem.isHidden}
                    handleRadioButtons={props.handleRadioButtons}
                    checked={props.categoryCampaignItem.isHidden === true}
                />
            </div>

            <div>
                <RadioButton
                    radioLabel="Private"
                    name="visibility-radio"
                    id="isPrivate"
                    value={props.categoryCampaignItem.isPrivate}
                    handleRadioButtons={props.handleRadioButtons}
                    checked={props.categoryCampaignItem.isPrivate === true}
                />
            </div>
            <div>
                <RadioButton
                    radioLabel="Public"
                    name="visibility-radio"
                    id="isPublic"
                    value={props.categoryCampaignItem.isPublic}
                    handleRadioButtons={props.handleRadioButtons}
                    checked={props.categoryCampaignItem.isPublic === true}
                />
            </div>
            <div className="text-[#181818] text-[18px] ">
                <h2 className="font-bold mb-2">
                    Deferred  Income Account Code <span className="text-[#AA3361]">*</span>
                </h2>
                {/* <Selector
                    options={props.financeClassCodes}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // onChange={props.handleChangeInDropdown}
                    allowSelectAll={false}
                    // value={props.campaignList.find(
                    //     (item) =>
                    //         item.value === props.categoryCampaignItem.selectedCampaignId
                    // )}
                    drpIdentity="FinanceClassCodes"
                /> */}
                <DropdownSelect
                    drpIdentity="financeAccountCodes"
                    optionArray={props.financeAccountCodes}
                    setFilterParameters={props.handleChangeInCodesDropdown}
                    value={props.categoryCampaignItem.financeAccountCodeId}
                    onBlur={() => props.validateField("financeAccountCodeId")}
                />
                <div>
                    <ValidationText
                        error={props.validState.error.financeAccountCodeId}
                    />
                </div>
            </div>
            <div className="text-[#181818] text-[18px] ">
                <h2 className="font-bold mb-2">
                    Deferred Income Class Code <span className="text-[#AA3361]">*</span>
                </h2>
                {/* <Selector
                    options={props.financeClassCodes}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // onChange={props.handleChangeInDropdown}
                    allowSelectAll={false}
                    // value={props.campaignList.find(
                    //     (item) =>
                    //         item.value === props.categoryCampaignItem.selectedCampaignId
                    // )}
                    drpIdentity="FinanceClassCodes"
                /> */}
                <DropdownSelect
                    drpIdentity="financeClassCodes"
                    optionArray={props.financeClassCodes}
                    setFilterParameters={props.handleChangeInCodesDropdown}
                    value={props.categoryCampaignItem.financeClassCodeId}
                    onBlur={() => props.validateField("financeClassCodeId")}
                />
                <div>
                    <ValidationText
                        error={props.validState.error.financeClassCodeId}
                    />
                </div>
            </div>
            <div className="text-[#181818] text-[18px] ">
                <h2 className="font-bold mb-2">
                    Revenue Account Code <span className="text-[#AA3361]">*</span>
                </h2>
                {/* <Selector
                    options={props.financeClassCodes}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // onChange={props.handleChangeInDropdown}
                    allowSelectAll={false}
                    // value={props.campaignList.find(
                    //     (item) =>
                    //         item.value === props.categoryCampaignItem.selectedCampaignId
                    // )}
                    drpIdentity="FinanceClassCodes"
                /> */}
                <DropdownSelect
                    drpIdentity="revenueAccountCodes"
                    optionArray={props.financeAccountCodes}
                    setFilterParameters={props.handleChangeInCodesDropdown}
                    // setFilterParameters={this.handleChangeInDropdown.bind(this)}
                    value={props.categoryCampaignItem.revenueAccountCodeId}
                    onBlur={() => props.validateField("revenueAccountCodeId")}
                />
                <div>
                    <ValidationText
                        error={props.validState.error.revenueAccountCodeId}
                    />
                </div>
            </div>
            <div className="text-[#181818] text-[18px] ">
                <h2 className="font-bold mb-2">
                    Revenue Class Code <span className="text-[#AA3361]">*</span>
                </h2>
                {/* <Selector
                    options={props.financeClassCodes}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    // onChange={props.handleChangeInDropdown}
                    allowSelectAll={false}
                    // value={props.campaignList.find(
                    //     (item) =>
                    //         item.value === props.categoryCampaignItem.selectedCampaignId
                    // )}
                    drpIdentity="FinanceClassCodes"
                /> */}
                <DropdownSelect
                    drpIdentity="revenueClassCodes"
                    optionArray={props.financeClassCodes}
                    setFilterParameters={props.handleChangeInCodesDropdown}
                    value={props.categoryCampaignItem.revenueClassCodeId}
                    onBlur={() => props.validateField("revenueClassCodeId")}
                />
                <div>
                    <ValidationText
                        error={props.validState.error.revenueClassCodeId}
                    />
                </div>
            </div>
        </>
    )
}
export default EditDetailsColumn2;