import moment from "moment";
import React, { Component } from "react";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import PageLoader from "../../components/common/pageLoader/PageLoader";

export default class XeroInvoiceExtraction extends Component {
  constructor(props) {
    super(props);
    this.financeServices = new FinanceServices();
    this.swalServices = new SwalServices();
    this.state = {
      XeroInvoiceExtractionRecordRequest: {
        startDate: "",
        endDate: "",
      },
      isLoading: false,
      validState: {
        isValid: true,
        error: {},
      },
      validationRule: {
        startDate: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "Start date for transaction report"),
          },
        ],
        endDate: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "End date for transaction report"),
          },
          {
            type: "minEndDate",
            minEndDate: "startDate",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "greater end date")
          }
        ],
      },
    };
  }

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.XeroInvoiceExtractionRecordRequest,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.XeroInvoiceExtractionRecordRequest,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'startDate') {
      let date = this.state.XeroInvoiceExtractionRecordRequest
      date.startDate = value
      this.setState({ startDate: date })
    }
    if (name === 'endDate') {
      let date = this.state.XeroInvoiceExtractionRecordRequest
      date.endDate = value
      this.setState({ endDate: date })
    }
  }




  downloadSheetForXeroInvoiceExtractionRecords = () => {
    let isValidateAllFields = this.isValidateAllFields()
    if (isValidateAllFields) {
      if (this.state.XeroInvoiceExtractionRecordRequest.startDate && this.state.XeroInvoiceExtractionRecordRequest.endDate) {
        this.setState({ isLoading: true });
        let request = {};
        request.startDate = moment(this.state.XeroInvoiceExtractionRecordRequest.startDate).format("DD MMM YYYY");
        request.endDate = moment(this.state.XeroInvoiceExtractionRecordRequest.endDate).format("DD MMM YYYY");
        this.financeServices.downloadSheetForXeroInvoiceExtractionRecords(request).then((response) => {
          if (response.status === 200 && response.data != null) {
            if (response.data?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
              let blob = new Blob([response.data])
              saveAs(blob, `XeroInvoiceReport_${request.startDate}_${request.endDate}` + ".xlsx");
              this.setState({ isLoading: false });
              this.swalServices.Success("Xero invoice extraction report is generated.");
            }
            else {
              this.setState({ isLoading: false });
              this.swalServices.Error("No records available between these dates.");
            }
          }
          else {
            this.swalServices.Error(response.message);
            this.setState({ isLoading: false });
          }

        });
      }
    }
  }

  render() {
    return (
      <div className="main-body-section finance-body-sec">
        <div className="bg-white">
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 py-10">
            <div className="col-span-3">
              <label
                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                htmlFor="default"
              >
                Start Date<span className="text-[#C00000]">*</span>
              </label>
              <div className="">
                <input
                  id="startDate"
                  name="startDate"
                  className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="date"
                  value={moment(this.state.XeroInvoiceExtractionRecordRequest.startDate).format("yyyy-MM-DD")}
                  onChange={(event) => this.handleChange(event)}
                  onBlur={() => this.validateField("startDate")}
                />
              </div>
              <ValidationText error={this.state.validState.error.startDate} />
            </div>
            <div className="col-span-3">
              <label
                className="block text-xl font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                htmlFor="default"
              >
                End Date<span className="text-[#C00000]">*</span>
              </label>
              <div className="">
                <input
                  id="endDate"
                  name="endDate"
                  className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                  type="date"
                  value={moment(this.state.XeroInvoiceExtractionRecordRequest.endDate).format("yyyy-MM-DD")}
                  onChange={(event) => this.handleChange(event)}
                  onBlur={() => this.validateField("endDate")}
                // min={this.state.transactionRecordRequest.startDate}
                />
              </div>
              <ValidationText error={this.state.validState.error.endDate} />
            </div>

            {this.state.isLoading ? <ButtonLoader /> :
              <div className="col-span-4">
                <button className="btn btn-red text-xl border text-white mt-8" onClick={() => this.downloadSheetForXeroInvoiceExtractionRecords()}>
                  Download Excel
                </button>
              </div>
            }
          </div>
          {this.state.isLoading ?
            <PageLoader /> : null}

        </div>
      </div >

    );
  }
}
