export default function ActionEditButton(props) {
    return (
        <button className='action-btn mr-1 flex items-center' aria-controls="add-edit-modal" id={props.index} key={props.id}
            onClick={(e) => props.onActionClick(props.value, props.index,e)}
        >
            <svg className="menu-svg" width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88">
                <g transform="translate(0.000000,88.000000) scale(0.100000,-0.100000)">
                    <path d="M645 871 c-11 -5 -142 -132 -290 -282 l-271 -274 -41 -145 c-23 -80 -38 -149 -34 -153 5 -4 74 11 153 34 l146 41 282 283 c332 333 318 302 197 422 -72 72 -105 89 -142 74z m123 -105 c40 -41 72 -79 72 -85 0 -16 -90 -111
                            -104 -111 -6 0 -49 38 -96 85 l-84 85 49 50 c28 29 58 50 70 50 14 0 49 -28 93 -74z m-198 -91 l34 -36 -191 -191 -191 -191 -16 32 c-10 19 -24 31 -36 31 -11 0 -20 3 -20 7 0 9 372 383 380 383 4 0 21 -16 40 -35z m103 -103 l37 -38
                            -194 -187 c-107 -103 -197 -187 -200 -187 -3 0 -6 8 -6 19 0 11 -12 23 -30 31 -16 7 -30 15 -30 19 0 7 373 381 380 381 3 0 23 -17 43 -38z m-488 -320 c12
                            -21 36 -45 58 -57 32 -17 37 -24 35 -49 -3 -25 -9 -31 -48 -42 l-45 -14 -51 53 -51 52 14 48 c12 41 18 47 41 47 21 0 32 -8 47 -38z m-51 -171 c-3 -3 -23 -11 -45 -17 -44 -13 -45 -12 -28 50 l11 38 34 -33 c18 -18 31 -35 28 -38z" fill="#6e6e6e" />
                </g>
            </svg>
            <p className='ml-2 tool-tip-txt text-[#181818]'>
                {props.text}
            </p>
        </button>
    )
}
