import React from "react";
import ModalBasic from "../../pages/component/ModalBasic";

export default function EventRegistrationApprovalDetailsModel(props) {
  return (
    <div className="event-registration-popup">
      <ModalBasic
        id="cancel-booking-modal"
        className="cancel-booking-modal"
        modalOpen={props.setRegistrationApprovalModel}
        setModalOpen={props.setOpenModal}
        title="Event Registration Approval Details"
      >
        <div className="bg-white mb-10 lg:px-[50px] px-[25px]">
          <div className="lg:flex bloxk items-center">
            <h2 className="lg:text-[18px] text-[16px] theme-color font-bold mb-1 lg:w-[30%]">Event Name :</h2>
            <h2 className="lg:text-[18px] text-[16px] theme-color mb-1 lg:w-[70%] ">
              {props.singleEventApprovalDetails.eventName}
            </h2>
          </div>
          <div className="lg:flex items-center">
            <h2 className="lg:text-[18px] text-[16px] theme-color font-bold mb-1 lg:w-[30%]">Event Date :</h2>
            <h2 className="lg:text-[18px] text-[16px] theme-color mb-1 lg:w-[70%]">
              {props.singleEventApprovalDetails.timeSlotDate}
            </h2>
          </div>
          <div className="lg:flex items-center">
            <h2 className="lg:text-[18px] text-[16px] theme-color font-bold mb-1 lg:w-[30%]">
              Contact Person :
            </h2>
            <h2 className="lg:text-[18px] text-[16px] theme-color mb-1 lg:w-[70%]">
              {props.singleEventApprovalDetails.contactPerson}
            </h2>
          </div>
          <div className="lg:flex items-center">
            <h2 className="lg:text-[18px] text-[16px] theme-color font-bold mb-1 lg:w-[30%]">Email :</h2>
            <h2 className="lg:text-[18px] text-[16px] theme-color mb-1 lg:w-[70%]">
              {props.singleEventApprovalDetails.email}
            </h2>
          </div>
          <div className="lg:flex items-center">
            <h2 className="lg:text-[18px] text-[16px] theme-color font-bold mb-1 lg:w-[30%]">Company Name :</h2>
            <h2 className="lg:text-[18px] text-[16px] theme-color mb-1 lg:w-[70%]">
              {props.singleEventApprovalDetails.companyName}
            </h2>
          </div>
          <div className="lg:flex items-center">
            <h2 className="lg:text-[18px] text-[16px] theme-color font-bold mb-1 lg:w-[30%]">Job Title :</h2>
            <h2 className="lg:text-[18px] text-[16px] theme-color mb-1 lg:w-[70%]">
              {props.singleEventApprovalDetails.title}
            </h2>
          </div>
          <div className="lg:flex items-center">
            <h2 className="lg:text-[18px] text-[16px] theme-color font-bold mb-1 lg:w-[30%]">Status :</h2>
            <h2 className="lg:text-[18px] text-[16px] theme-color mb-1 lg:w-[70%]">
              {props.singleEventApprovalDetails.bookingStatus}
            </h2>
          </div>
          {/* <div className="flex items-center">
            <h2 className="text-xl theme-color font-bold mb-1 w-[30%]">
              Registration Date :
            </h2>
            <h2 className="text-xl theme-color mb-1 w-[70%]">
              {props.singleEventApprovalDetails.registrationDate}
            </h2>
          </div> */}
        </div>
      </ModalBasic>
    </div>
  );
}
