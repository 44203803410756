import React, { Component } from "react";
import Selector from "../../components/dropdown/commonDropdown/Selector";
import Table from "../../components/table/Table";
import BatchJobExtractionServices from "../../services/axiosServices/apiServices/BatchJobExtractionServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";

export default class ChartsOfAccounts extends Component {
  constructor(props) {
    super(props);
    this.batchJobExtractionServices = new BatchJobExtractionServices();
    this.swalServices = new SwalServices();
    this.state = {
      itemizedList: [],
      accountCodeList: [
        {
          value: 1,
          label: "Active",
        },
        {
          value: 2,
          label: "Inactive"
        }
      ],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        filterByType: 0,
        filterByStatus: 0,
        itemisedItem: "",
        accountCode: "",
        classCode: "",
        status: "",
      },
      chartsOfAccountCodeList: [],
      totalCount: 0,
      accountCodeDetails: {
        accountCodeId: 0,
        itemisedItemId: 0,
        description: "",
        accountCode: "",
        classCode: "",
        defferedAccountCode: "",
        deferredClassCode: "",
        isDefferedIncome: "",
        isActive: false,
      },
      isLoading: false,
      validState: {
        isValid: true,
        error: {},
      },
      validationRule: {
        itemisedItemId: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "itemized item"),
          },
        ],
        description: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "description"),
          },
        ],
        classCode: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "classCode"),
          },
        ],
        accountCode: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "accountCode"),
          },
        ],
        deferredClassCode: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "deferred class code"),
          },
        ],
        defferedAccountCode: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "deferred account code"),
          },
        ],

      },
    };
  }
  componentDidMount() {
    this.getAllDropdownsForBatchJob();
  }
  getAllChartsOfAccountCodeList = () => {
    this.setState({ isLoading: true });
    let pagination = { ...this.state.pagination };
    this.batchJobExtractionServices.getAllChartsOfAccountCodeList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;
        this.setState({
          chartsOfAccountCodeList: details,
          totalCount: response.responseItem.responseContent.totalCount,
        });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }
  getAllDropdownsForBatchJob = () => {
    this.batchJobExtractionServices.getAllDropdownsForBatchJob().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        let itemizedListArr =
          response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "AccountCodeItems"
          );
        itemizedListArr = itemizedListArr.map((p) => ({
          value: p.id,
          label: p.name,
        }));
        this.setState({ itemizedList: itemizedListArr }, () => this.getAllChartsOfAccountCodeList());
      }
      else {
        this.getAllChartsOfAccountCodeList();
        this.swalServices.Error(response.message);
      }
    })
  }
  addEditChartsOfAccountCode = () => {
    if (this.isValidateAllFields()) {
      let request = { ...this.state.accountCodeDetails }
      this.batchJobExtractionServices.addEditChartsOfAccountCode(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          let resetAccountCodeDetails = {
            accountCodeId: 0,
            itemisedItemId: 0,
            description: "",
            accountCode: "",
            classCode: "",
            defferedAccountCode: "",
            deferredClassCode: "",
            isDefferedIncome: "",
            isActive: false,
          };
          this.setState({ accountCodeDetails: resetAccountCodeDetails });

          if (request.accountCodeId && request.accountCodeId > 0) {
            this.swalServices.Success("Account code updated successfully");
            this.getAllChartsOfAccountCodeList();
          } else {
            this.swalServices.Success("Account code inserted successfully");
            this.getAllChartsOfAccountCodeList();
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
      })
    }
  }

  handleChange = (event, identity) => {
    const value = event.target.value;
    const name = event.target.name;
    if (identity === "AccountCodeDetails") {
      let detail = { ...this.state.accountCodeDetails };
      detail[name] = value;
      this.setState({ accountCodeDetails: detail });
    }
    if (identity === "Pagination") {
      let detail = { ...this.state.pagination };
      detail[name] = value;
      this.setState({ pagination: detail });
    }
  };

  handleChangeInDropdown(selected, drpIdentity) {
    if (drpIdentity === "ItemizedItemForAccountCodeDetails") {
      let details = this.state.accountCodeDetails;
      details.itemisedItemId = selected.value;
      this.setState({ accountCodeDetails: details }, () => {
        this.validateField("itemisedItemId");
      });
    }
    if (drpIdentity === "ItemizedItemForPagination") {
      let pag = this.state.pagination;
      if (selected.value > 0) {
        pag.itemisedItem = selected.label;
      } else {
        pag.itemisedItem = "";
      }
      this.setState({ pagination: pag });
    }
    if (drpIdentity === "Status") {
      let page = this.state.pagination;
      if (selected.value > 0) {
        if (selected.label === "Active") {
          page.status = 1;
        } else {
          page.status = 0;
        }
      } else {
        page.status = "";
      }
      this.setState({ pagination: page });
    }
  };

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.accountCodeDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };
  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.accountCodeDetails,
      this.state.validationRule,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  editDetails = (codeId) => {
    if (codeId > 0) {
      let found = this.state.chartsOfAccountCodeList.find(x => x.accountCodeId === codeId);
      if (found) {
        let resetDetails = {
          accountCodeId: 0,
          itemisedItemId: 0,
          description: "",
          accountCode: "",
          classCode: "",
          defferedAccountCode: "",
          deferredClassCode: "",
          isDefferedIncome: "",
          isActive: false,
        };
        let resetValidState = {
          isValid: true,
          error: {},
        };
        this.setState({ accountCodeDetails: resetDetails, validState: resetValidState }, () => {
          let details = {};
          details.accountCodeId = found.accountCodeId;
          details.accountCode = found.accountCode;
          details.defferedAccountCode = found.defferedAccountCode;
          details.classCode = found.classCode;
          details.deferredClassCode = found.deferredClassCode;
          details.itemisedItemId = found.itemisedItemId;
          details.description = found.description;
          details.isActive = found.isActive;
          this.setState({ accountCodeDetails: details });
        });
      }
    }
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllChartsOfAccountCodeList();
  };
  actions = (element, index, value) => {
    return element !== "accountCodeId" ? null : (
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap pointer">
        <div className="flex items-center relative">
          <button
            className="action-btn mr-4 flex items-center"
            aria-controls="add-edit-modal"
            onClick={() => this.editDetails(value)}
          >
            <p className="ml-2 tool-tip-txt underline text-[#AA3361]">Edit</p>
          </button>
        </div>
      </td>
    );
  };

  render() {
    return (
      <div className="main-body-section finance-body-sec">
        {/* Section 1 Start */}
        <div className="bg-white pb-2">
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">
            <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
              <h2 className="text-xl theme-color mb-2 font-bold">Itemized Item</h2>
              <Selector
                options={this.state.itemizedList}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}

                onChange={this.handleChangeInDropdown.bind(this)}
                allowSelectAll={false}
                value={this.state.itemizedList.filter(item => item.value === this.state.accountCodeDetails.itemisedItemId)}
                drpIdentity="ItemizedItemForAccountCodeDetails"
              />
              <ValidationText
                error={this.state.validState.error.itemisedItemId}
              />
            </div>
            <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
              <h2 className="text-xl theme-color mb-2 font-bold">Description</h2>
              {/* <Selector
                options={this.state.descriptionList}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                
                // onChange={this.handleChangeInDropdown.bind(this)}
                allowSelectAll={false}
                // value={this.state.pagination.FilterByAgency}
                // value={this.state.agencyList.find(item => item.value === this.state.pagination.FilterByAgency.id)}
                drpIdentity="AgencyFilter"
              /> */}

              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                name="description"
                value={this.state.accountCodeDetails.description}
                onChange={(e) => this.handleChange(e, "AccountCodeDetails")}
                onBlur={() => this.validateField("description")}
              />
              <ValidationText
                error={this.state.validState.error.description}
              />
            </div>

            <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
              <h2 className="text-xl theme-color mb-2 font-bold">Revenue Account Code</h2>
              {/* <Selector
                options={this.state.accountCodeList}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                
                // onChange={this.handleChangeInDropdown.bind(this)}
                allowSelectAll={false}
                // value={this.state.pagination.FilterByAgency}
                // value={this.state.agencyList.find(item => item.value === this.state.pagination.FilterByAgency.id)}
                drpIdentity="AgencyFilter"
              /> */}
              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                name="accountCode"
                value={this.state.accountCodeDetails.accountCode}
                onChange={(e) => this.handleChange(e, "AccountCodeDetails")}
                onBlur={() => this.validateField("accountCode")}
              />
              <ValidationText
                error={this.state.validState.error.accountCode}
              />
            </div>
            {/* <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
              <h2 className="text-xl theme-color mb-1">Deferred Income</h2>
              <div className="mt-5 flex items-center">
                <label className="flex items-center mr-8">
                  <input
                    type="radio"
                    name="radio-buttons"
                    id="isPage"
                    className="form-radio"
                  // value={props.resource.isPage}
                  // onChange={(event) => props.handleRadioButtons(event)}
                  // checked={props.resource.isPage === true}
                  />
                  <span className="text-xl  font-medium theme-color ml-2">
                    Yes
                  </span>
                </label>
                <label className="flex items-center ml-8">
                  <input
                    type="radio"
                    name="radio-buttons"
                    id="isPage"
                    className="form-radio"
                  // value={props.resource.isPage}
                  // onChange={(event) => props.handleRadioButtons(event)}
                  // checked={props.resource.isPage === true}
                  />
                  <span className="text-xl  font-medium theme-color ml-2">
                    No
                  </span>
                </label>
              </div>
            </div> */}


            <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
              <h2 className="text-xl theme-color mb-2 font-bold">Deferred Account Code</h2>

              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                name="defferedAccountCode"
                value={this.state.accountCodeDetails.defferedAccountCode}
                onChange={(e) => this.handleChange(e, "AccountCodeDetails")}
                onBlur={() => this.validateField("defferedAccountCode")}
              />
              <ValidationText
                error={this.state.validState.error.defferedAccountCode}
              />
            </div>

            <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
              <h2 className="text-xl theme-color mb-2 font-bold"> Revenue Class Code</h2>
              {/* <Selector
                options={this.state.classCodeList}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                
                // onChange={this.handleChangeInDropdown.bind(this)}
                allowSelectAll={false}
                // value={this.state.pagination.FilterByAgency}
                // value={this.state.agencyList.find(item => item.value === this.state.pagination.FilterByAgency.id)}
                drpIdentity="AgencyFilter"
              /> */}
              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                name="classCode"
                value={this.state.accountCodeDetails.classCode}
                onChange={(e) => this.handleChange(e, "AccountCodeDetails")}
                onBlur={() => this.validateField("classCode")}
              />
              <ValidationText
                error={this.state.validState.error.classCode}
              />
            </div>
            <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
              <h2 className="text-xl theme-color mb-2 font-bold">Deferred Class Code</h2>
              {/* <Selector
                options={this.state.deferredIncomeList}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                
                // onChange={this.handleChangeInDropdown.bind(this)}
                allowSelectAll={false}
                // value={this.state.pagination.FilterByAgency}
                // value={this.state.agencyList.find(item => item.value === this.state.pagination.FilterByAgency.id)}
                drpIdentity="AgencyFilter"
              /> */}
              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                name="deferredClassCode"
                value={this.state.accountCodeDetails.deferredClassCode}
                onChange={(e) => this.handleChange(e, "AccountCodeDetails")}
                onBlur={() => this.validateField("deferredClassCode")}
              />
              <ValidationText
                error={this.state.validState.error.deferredClassCode}
              />
            </div>
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              <div className="mt-4 flex items-center justify-end w-full">
                <button className="btn btn-pink text-xl border text-white "
                  onClick={() => this.addEditChartsOfAccountCode()}>
                  Add/Save
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Section 1 End */}

        {/* Section 2 Start */}
        <div className="bg-white pb-2 mt-10">
          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full mb-3">
              <h2 className="text-xl theme-color mb-2 font-bold">Item</h2> {"(You can select more than one option) "}
              <Selector
                options={this.state.itemizedList}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}

                onChange={this.handleChangeInDropdown.bind(this)}
                allowSelectAll={false}
                value={this.state.itemizedList.find(item => item.label === this.state.pagination.itemisedItem)}
                drpIdentity="ItemizedItemForPagination"
              />
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full mb-3">
              <h2 className="text-xl theme-color mb-2 font-bold">Account Code</h2>
              {/* <Selector
                options={this.state.descriptionList}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                
                // onChange={this.handleChangeInDropdown.bind(this)}
                allowSelectAll={false}
                // value={this.state.pagination.FilterByAgency}
                // value={this.state.agencyList.find(item => item.value === this.state.pagination.FilterByAgency.id)}
                drpIdentity="AgencyFilter"
              /> */}
              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                name="accountCode"
                value={this.state.pagination.accountCode}
                onChange={(e) => this.handleChange(e, "Pagination")}
              />
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full mb-3">
              <h2 className="text-xl theme-color mb-2 font-bold">Class Code</h2>
              {/* <Selector
                options={this.state.classCodeList}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                
                // onChange={this.handleChangeInDropdown.bind(this)}
                allowSelectAll={false}
                // value={this.state.pagination.FilterByAgency}
                // value={this.state.agencyList.find(item => item.value === this.state.pagination.FilterByAgency.id)}
                drpIdentity="AgencyFilter"
              /> */}

              <input
                id="default"
                className="form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                name="classCode"
                value={this.state.pagination.classCode}
                onChange={(e) => this.handleChange(e, "Pagination")}
              />
            </div>
            <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full mb-3">
              <h2 className="text-xl theme-color mb-2 font-bold">Status</h2> {"(You can select more than one option) "}
              <Selector
                options={this.state.accountCodeList}
                // isMulti
                closeMenuOnSelect={true}
                hideSelectedOptions={false}

                onChange={this.handleChangeInDropdown.bind(this)}
                allowSelectAll={false}
                value={this.state.accountCodeList.find(item => item.label === this.state.pagination.status)}
                drpIdentity="Status"
              />
            </div>
            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              <div className="mt-4 flex items-center justify-end w-full">
                <button className="btn btn-pink text-xl border text-white "
                  onClick={() => {
                    let det = { ...this.state.pagination }
                    det.pageNo = 1;
                    det.pageSize = 10;
                    this.setState({ pagination: det }, () => {
                      this.getAllChartsOfAccountCodeList();
                    })
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Section 2 End */}

        {/* Section 3 Start */}
        <div className="mt-10 charts-of-accounts-tble">
          <Table
            columns={[
              { name: "accountCodeItemName", title: "Itemised Item" },
              { name: "description", title: "Xero Description" },
              { name: "accountCode", title: "Account Code" },
              { name: "classCode", title: "Linked Class Code" },
              //{ name: "deferredIncome", title: "Deferred Income" },
              { name: "defferedAccountCode", title: "Deferred Account Code" },
              { name: "accountStatusName", title: "Status" },
              { name: "accountCodeId", title: "Actions" },
            ]}
            rows={this.state.chartsOfAccountCodeList}
            isLoading={this.state.isLoading}
            sortingColumns={["accountCodeItemName", "description","accountCode","defferedAccountCode","classCode"]}
            pagination={this.state.pagination}
            totalResultes={this.state.totalCount}
            totalCount={this.state.totalCount}
            setPagination={this.setPagination.bind(this)}
            customScope={[
              {
                column: "accountCodeId",
                renderTableData: this.actions.bind(this),
              },
            ]}
          />
        </div>
        {/* Section 3 End */}
      </div>
    );
  }
}
