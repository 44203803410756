import React from 'react'
import { useLocation } from 'react-router-dom';

const Breadcome = (props) => {

  const location = useLocation()
  const newStr = location.pathname.replace('/', '');
  const breadCrumbs = props.componentRoutes.filter(x => x.path === newStr);

  return (
    <div>
      {breadCrumbs.map((bread, index) => (
        <div className='breadcome-sec flex items-center justify-between px-7 py-4' key={index}>
          <div className='left-title-sec'>
            <p className='text-4xl font-medium text-[#fff]'>
              {bread.title}
            </p>
          </div>

          {bread.previousPath ?
            <div className='right-links-sec'>
              <ul className='flex items-center'>
                <li>
                  <a href='#/'>
                    <svg className='breadcrumb-menu-svg' id="Capa_1" width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 60 60">
                      <path d="M48.014,42.889l-9.553-4.776C37.56,37.662,37,36.756,37,35.748v-3.381c0.229-0.28,0.47-0.599,0.719-0.951
                        c1.239-1.75,2.232-3.698,2.954-5.799C42.084,24.97,43,23.575,43,22v-4c0-0.963-0.36-1.896-1-2.625v-5.319
                      c0.056-0.55,0.276-3.824-2.092-6.525C37.854,1.188,34.521,0,30,0s-7.854,1.188-9.908,3.53C17.724,6.231,17.944,9.506,18,10.056
                      v5.319c-0.64,0.729-1,1.662-1,2.625v4c0,1.217,0.553,2.352,1.497,3.109c0.916,3.627,2.833,6.36,3.503,7.237v3.309
                      c0,0.968-0.528,1.856-1.377,2.32l-8.921,4.866C8.801,44.424,7,47.458,7,50.762V54c0,4.746,15.045,6,23,6s23-1.254,23-6v-3.043
                      C53,47.519,51.089,44.427,48.014,42.889z" fill="#fff" />
                    </svg>
                  </a>
                </li>
                <li>
                  <span className='px-2 leading-normal	 text-2xl text-[#fff]'>/</span>
                </li>
                <li>
                  <a href='#/' className='text-xl text-[#fff]'>
                    {bread.previousPath}
                  </a>
                </li>
                <li>
                  <span className='px-2 leading-normal	 text-2xl text-[#fff]'>/</span>
                </li>
                <li>
                  <a href='#/' className='text-xl text-[#fff]'>
                    {bread.title}
                  </a>
                </li>

              </ul>
            </div>
            : null

          }
        </div>
      ))}
    </div>
  );
};

export default Breadcome;
