import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Table from "../../components/table/Table";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import SwalServices from "../../services/swalServices/SwalServices";
import EntryManagementServices from "../../services/axiosServices/apiServices/EntryManagementServices";
import moment from "moment";
import DropdownAction from "../../components/dropdown/DropdownAction";
import { saveAs } from "file-saver";

export default class FormQuestionAnswer extends Component {

  constructor(props) {
    super(props)
    this.swalServices = new SwalServices();
    this.entryServices = new EntryManagementServices();
    this.state = {
      route: "",
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
      },
      totalResultes: "",
      isLoading: false,
      eventList: [],
      eventStatus: [],
      eventId: 0,
      actionDropdown2: ["Download Data"],
      setCancelAllBookingsModel: false,
      cancelBookingDetails: {},


    }
  }

  // Methods Section
  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getFormQuestionAnswerEntryDetailList(newPagination);
  };

  actions = (element, index, value) => {
    return element !== "eventId" ? null : (
      <td>
      <ActionContainer>

        <DropdownAction
          key={index}
          value={value}
          id={index}
          index={index}
          options={this.state.actionDropdown2}
          align="right"
          onOptionClick={this.actionClick.bind(this)}
        />


      </ActionContainer>
      </td>
    );
  };




  componentDidMount() {

    this.getFormQuestionAnswerEntryDetailList();
  }
  getFormQuestionAnswerEntryDetailList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.entryServices.getFormQuestionAnswerEntryDetailList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let eventArr = response.responseItem.responseContent.itemList;

        const swapValue = (details) => {
          Object.keys(details).forEach(key => {
            if (details[key].eventDate !== null) {
              details[key].eventDate = moment(details[key].eventDate).format("DD MMM YYYY");
            }
          });
        };
        swapValue(eventArr);
        this.setState({ eventList: eventArr });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount, });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  getAllFormQuestionAnswerListByEventId = (eventId, eventName = "") => {
    this.setState({ isLoading: true });
    let request = [eventId];

    this.entryServices.getAllFormQuestionAnswerListByEventId(request).then((response) => {
      if (response.status === 200 && response.data != null) {
        let blob = new Blob([response.data])
        saveAs(blob, `${eventName}_Feedback_Question` + ".xlsx");
        this.setState({ isLoading: false });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };


  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    detail.pageSize = 10;
    detail.searchText = value;
    this.setState({ pagination: { ...detail } });

  }

  actionClick = (index, value, option, event) => {
    if (option === "Download Data") {
      let found = this.state.eventList[index];
      if (found) {
        this.getAllFormQuestionAnswerListByEventId(found.eventId, found.eventName);
      }

    }
  };

  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section">
        <div className="custom-card shadow-lg">
          <div className="filter-info grid grid-cols-12 gap-6">
            <div className="filter-types xl:col-span-5 lg:col-span-5 col-span-12 pr-12">
              <label
                className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                htmlFor="default"
              >
                Search by Event Name
              </label>
              <input
                id="eventName"
                name="eventName"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>

            <div className="xl:col-span-2 lg:col-span-2 col-span-12">
              <div className="flex items-end justify-end h-full pb-1">
                <button
                  className="btn btn-pink text-white"
                  onClick={() => this.getFormQuestionAnswerEntryDetailList()}
                >
                  <span className="text-xl text-white font-semibold tracking-normal">
                    Search
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Event list section start */}
        <div className="grid grid-cols-6 gap-4 pt-10 px-8">
          <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#181818]">
            {`Total:${this.state.eventList.length}`}
          </h2>
        </div>
        <main className="pb-10">
          <div className="w-full mx-auto">
            <div className="relative">
              <div className="event-feedback-question-details">
                <div className=" mt-6">
                  <Table
                    columns={[
                      { name: "eventName", title: "Event Name" },
                      { name: "eventDate", title: "Event Date" },
                      { name: "eventTypeName", title: "Type" },
                      { name: "booking", title: "Booking" },
                      { name: "eventId", title: "Action" },
                    ]}
                    pagination={this.state.pagination}
                    rows={this.state.eventList}
                    sortingColumns={["eventName", "eventDate", "eventTypeName"]}
                    isLoading={this.state.isLoading}
                    totalResultes={this.state.totalResultes}
                    totalCount={this.state.totalResultes}
                    setPagination={this.setPagination.bind(this)}
                    customScope={[
                      {
                        column: "eventId",
                        renderTableData: this.actions.bind(this),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>



      </div>
    );
  }
}