import React from "react";

const CalculationRow = (props) => {
  const handleChange = (event, year, name) => {
    props.handleChangeInPriceTable(
      event.target.value,
      event.target.name,
      year,
      name
    );
  };

  const grantApproval = (name, year, tableName, approval) => {
    props.grantApproval(name, year, tableName, approval);
  };

  return (
    <div className="grid grid-cols-12 gap-4 pb-4 tbl-detail-list pricing-table-sec items-center">
      <div className="col-span-2">
        <label className="2xl:text-[14px] lg:text-[13px]">
          {props.rowTitle}
        </label>
      </div>
      {
        (props.tableName !== "PercentageDiscountTable" && props.isDeleted) ?
          <div className={`col-span-3 ${(props.tableName === "PercentageDiscountTable" || props.tableName === "AllowableDiscountTable") ? "percentage-sign delete-text-line-before" : "dollar-sign delete-text-line-after"}`}>
            <input
              disabled={!!((props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true))}
              className={`2xl:text-[14px] lg:text-[13px] text-center text-[#181818] ${((props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true)) ? "disabled-input-table" : 'noClass'}`}
              type="text"
              name="entranceFee"
              value={props.entranceFee}
              id={`EntranceFee${props.tableName}_${props.keyId}`}
              onChange={(event) =>
                handleChange(event, props.yearIndex, props.tableName)
              }
              key={`EntranceFee${props.tableName}_${props.keyId}`}
            />
          </div>
          :
          <div className={`col-span-3 ${(props.tableName === "PercentageDiscountTable" || props.tableName === "AllowableDiscountTable") ? "percentage-sign" : "dollar-sign"}`}>
            <input
              disabled={!!((props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true))}
              className={`2xl:text-[14px] lg:text-[13px] text-center text-[#181818] ${((props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true)) ? "disabled-input-table" : 'noClass'}`}
              type="text"
              name="entranceFee"
              value={props.entranceFee}
              id={`EntranceFee${props.tableName}_${props.keyId}`}
              onChange={(event) =>
                handleChange(event, props.yearIndex, props.tableName)
              }
              key={`EntranceFee${props.tableName}_${props.keyId}`}
            />
          </div>
      }
      {
        (props.tableName !== "PercentageDiscountTable" && props.isDeleted) ?
          <div className={`col-span-3 ${(props.tableName === "PercentageDiscountTable" || props.tableName === "AllowableDiscountTable") ? "percentage-sign delete-text-line-before" : "dollar-sign delete-text-line-after"}`}>
            <input
              disabled={!!((props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true))}
              className={`2xl:text-[14px] lg:text-[13px] text-center text-[#181818] ${(props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true) ? "disabled-input-table" : 'noClass'}`}
              type="text"
              name="annualFee"
              value={props.annualFee}
              onChange={(event) =>
                handleChange(event, props.yearIndex, props.tableName)
              }
            />
          </div>
          :
          <div className={`col-span-3 ${(props.tableName === "PercentageDiscountTable" || props.tableName === "AllowableDiscountTable") ? "percentage-sign" : "dollar-sign"}`}>
            <input
              disabled={!!((props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true))}
              className={`2xl:text-[14px] lg:text-[13px] text-center text-[#181818] ${(props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true) ? "disabled-input-table" : 'noClass'}`}
              type="text"
              name="annualFee"
              value={props.annualFee}
              onChange={(event) =>
                handleChange(event, props.yearIndex, props.tableName)
              }
            />
          </div>
      }
      {/* {
        (props.tableName !== "PercentageDiscountTable" && props.isDeleted) ?
          <div className={`col-span-2 ${(props.tableName === "PercentageDiscountTable" || props.tableName === "AllowableDiscountTable") ? "percentage-sign delete-text-line-before" : "dollar-sign delete-text-line-after"}`}>
            <input
              disabled={!!((props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true))}
              className={`2xl:text-[14px] lg:text-[13px] text-center text-[#181818] ${(props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true) ? "disabled-input-table" : 'noClass'}`}
              type="text"
              name="includedChapter"
              value={props.includedChapter}
              onChange={(event) =>
                handleChange(event, props.yearIndex, props.tableName)
              }
            />
          </div>
          :
          <div className={`col-span-2`}>
            <input
              disabled={!!((props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true))}
              className={`2xl:text-[14px] lg:text-[13px] text-center text-[#181818] ${(props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true) ? "disabled-input-table" : 'noClass'}`}
              type="text"
              name="includedChapter"
              value={props.includedChapter}
              onChange={(event) =>
                handleChange(event, props.yearIndex, props.tableName)
              }
            />
          </div>
      } */}
      {/* {
        (props.tableName !== "PercentageDiscountTable" && props.isDeleted) ?
          <div className={`col-span-2 ${(props.tableName === "PercentageDiscountTable" || props.tableName === "AllowableDiscountTable") ? "percentage-sign delete-text-line-before" : "dollar-sign delete-text-line-after"}`}>
              <input
                disabled={!!((props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true))}
                className={`2xl:text-[14px] lg:text-[13px] text-center text-[#181818] ${(props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true) ? "disabled-input-table" : 'noClass'}`}
                type="text"
                name="newChapterPrice"
                value={props.newChapterPrice}
                onChange={(event) =>
                  handleChange(event, props.yearIndex, props.tableName)
                }
              />
          </div>
          :
          <div className={`col-span-2 ${(props.tableName === "PercentageDiscountTable" || props.tableName === "AllowableDiscountTable") ? "percentage-sign" : "dollar-sign"}`}>
            <input
              disabled={!!((props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true))}
              className={`2xl:text-[14px] lg:text-[13px] text-center text-[#181818] ${(props.disabled === true) || (props.tableName === "PercentageDiscountTable" && props.data.isDeleted === true) ? "disabled-input-table" : 'noClass'}`}
              type="text"
              name="newChapterPrice"
              value={props.newChapterPrice}
              onChange={(event) =>
                handleChange(event, props.yearIndex, props.tableName)
              }
            />
          </div>
      } */}
      <div className="col-span-2">
        <div className="flex flex-row space-x-12">
          {
            props?.showStatus &&
            (props.tableName === "PercentageDiscountTable" ||
              props.tableName === "BasePriceTable") ? (
            <label
              className={
                props.status !== "Ok" && props.status !== "Approval Given"
                  ? "text-[#AA3361]"
                  : "text-[#7A9763]"
              }
            >
              {props.status}
              {/* {props.isDeleted ? "deleted" : "N"} */}
            </label>
          ) : null}
          {
            props?.showButtons &&
            props.tableName === "PercentageDiscountTable" &&
            (props.status === "Required Approval" ||
              props.status === "Approval Given" ||
              props.status === "Rejected") ? (
            <div className="flex flex-row space-x-12">
              <button
                className="text-base text-white font-bold bg-[#7C8F63] approve-icon-bg"
                onClick={(event) =>
                  grantApproval(
                    event.target.name,
                    props.yearIndex,
                    props.tableName,
                    "Approval Given"
                  )
                }
              >
                <img src="/approve-sign.png" className="approve-icons approve-icon" alt=""/>
              </button>
              <button
                className="text-base text-white font-bold bg-[#AA3361] approve-icon-bg"
                onClick={(event) =>
                  grantApproval(
                    event.target.name,
                    props.yearIndex,
                    props.tableName,
                    "Rejected"
                  )
                }
              >
                {/* Reject */}
                <img src="/reject-icon.png" className="approve-icons approve-icon" alt=""/>
              </button>
            </div>
          ) : null}

          {/* {props && !props.disabled && props.status === "Ok" ?
                (
                    <label>Ok</label>
                ) 
                : props.status === "RequiredAprroval" ?
                (
                 <div className="flex flex-row space-x-12">
                        <label className="text-base text-[#AA3361]">Required Approval</label>
                        {
                            props.feeApprove === "Approve" ?
                                (
                                    <div className="flex flex-row space-x-12">
                                        <button className="text-base text-white font-bold bg-[#7C8F63] px-10">Approve</button>
                                        <button className="text-base text-white font-bold bg-[#AA3361] px-10">Reject</button>
                                    </div>
                                )
                                :
                                null
                        }
                    </div>
                    )
                     : props.status === "GivenAprroval" ?
                    (<label className="text-base text-[#7C8F63]">Given Approval</label>)
                    :
                    null
                } */}
        </div>
      </div>
    </div>
  );
};
export default CalculationRow;
