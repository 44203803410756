import React, { Component } from "react";
import EngagementTrackingNotes from "../../../components/membership/individualMembers/EngagementTrackingNotes";
import IndividualaccountInformation from "../../../components/membership/individualMembers/IndividualaccountInformation";
import IndividualMembershipSubscription from "../../../components/membership/individualMembers/IndividualMembershipSubscription";
import IndividualOtherInformation from "../../../components/membership/individualMembers/IndividualOtherInformation";
import IndividualInformation from "../../../components/membership/individualMembers/IndividualInformation";
import EventsBookings from "../../../components/membership/individualMembers/EventsBookings";
import IndividualFinancialsRecord from "../../../components/membership/individualMembers/IndividualFinancialsRecord";
import IndividualInstitutionInformation from "../../../components/membership/individualMembers/IndividualInstitutionInformation";
import CompanyInformation from "../../../components/membership/individualMembers/CompanyInformation";
import IndividualMemberService from "../../../services/axiosServices/apiServices/IndividualMemberService";
import SwalServices from "../../../services/swalServices/SwalServices";
import {
  validate,
  isValidForm,
} from "../../../utils/validation/CommonValidator";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";
import { checkParameters } from "../../../utils/Encryption";
import { Navigate } from "react-router-dom";
import {
  CommonValidationMessages,
  ErrorMessage,
  SuccessMessage,
} from "../../../utils/Messages";
import {
  CompanyInformationValidationRules,
  OtherInformationValidationRules,
  IndividualInformationValidationRules,
  InstituteInformationValidationRules,
  IndEngagementTrackingValidationRules,
} from "../../../utils/validation/AllValidationRules";
import MembershipCorporateServices from "../../../services/axiosServices/apiServices/MembershipCorporateServices";
import moment from "moment";
import { saveAs } from "file-saver";
import {
  addEditCreditNoteValRules,
  addEditCreditNoteValRules2,
} from "../corporateMembers/CorporateValidations";
import {
  convertToDecimal,
  isNullBoolean,
  isNullString,
} from "../../../utils/Utils";
import { monthDifference } from "../../../utils/DateUtils";
import FinanceServices from "../../../services/axiosServices/apiServices/FinanceServices";

export default class CreateIndividualMembers extends Component {
  constructor(props) {
    super(props);
    this.membershipCorporateServices = new MembershipCorporateServices();
    this.individualMemberService = new IndividualMemberService();
    this.financeService = new FinanceServices();
    this.swalServices = new SwalServices();
    this.state = {
      adminId: 0,
      open: 1,
      engagementTrackingList: [],
      status: [],
      reason: [],
      updated_By: [],
      recorded_by: [],
      followUp_By: [],
      engagementType: [],
      membershipType: [],
      filterMembershipTypeList: [],
      openSection: "IAI",
      billingCategory: [],
      jobPositionType: [],
      industryType: [],
      companyInterestType: [],
      preferredChannelType: [],
      eNewsType: [],
      getToKnowUs: [],
      salutation: [],
      yearOfBirthType: [],
      nationalityType: [],
      institutes: [],
      studiesType: [],
      pursuingQualifiedType: [],
      yearOfCompletedType: [],
      corporateMemberId: 0,
      individualMemberId: 0,
      isCorporate: false,
      isIndividualMemberId: 0,
      // Individual Account Information Object
      siisIndividualAccountInformation: {
        individualMemberId: 0,
        corporateMemberId: 0,
        individualSIISAccountId: 0,
        accountStatus: 0,
        individualJoinedDate: "",
        siisIndividualId: "",
        updatedAt: "",
        updatedBy: 0,
        markForDeleted: false,
        deletionDate: "",
        deletionReasonId: 0,
      },
      // Engagement Tracking and Notes Object
      engagementTrackingAndNotes: {
        individualMemberId: 0,
        individualEngagementId: 0,
        corporateEngagementTypeId: 0,
        engagementDate: "",
        engagementDescription: "",
        corporateEngagementRecordedById: 0,
        corporateEngagementFollowUpById: 0,
        updatedBy: 0,
        updatedAt: "",
      },
      //  Membership Subscription Object
      membershipSubscription: {
        membershipTypeId: 0,
        billingCategoryId: 0,
        companyCategoryId: 0,
        corporateMemberRecordTypeId: 0,
        corporateMemberBillingCategoryId: 0,
        individualMemberId: 0,
        individualSubscriptionId: 0,
        isSubscriptionAccountStatus: 0,
        subscriptionEndDate: "",
        subscriptionJoinDate: "",
        subscriptionLastUpdatedDate: "",
        subscriptionReasonLeaving: 0,
        subscriptionRenewalNotes: "",
        updatedById: 0,
      },
      // Company Information Object
      companyInformation: {
        corporateMemberId: 0,
        companyName: "",
        brand: "",
        designation: "",
        jobPositionId: 0,
        industry: "",
        industryTypeId: 0,
        companyDiscription: "",
        updatedBy: 1,
        updatedAt: null,
      },
      // Other Information Object
      otherInformation: {
        individualMemberId: 0,
        individualOtherInformationId: 0,
        interestedInId: "",
        otherCompanyInterests: null,
        preferredChanneId: "",
        eNewsLetterId: "",
        selectedInterestedIns: [],
        selectedCommunicationChannels: [],
        selectedEnewsletters: [],
        aboutUsId: 0,
        knowAboutUsOthers: null,
        companyFacebook: "",
        companyInstagram: "",
        companyLinkedIn: "",
        updatedBy: 1,
        updatedAt: null,
        otherCompanyInterestsFlag: false,
      },
      // Individual Information Object
      individualInformation: {
        corporateMemberId: 0,
        salutationId: 0,
        firstName: "",
        lastName: "",
        yearOfBirth: "",
        mobileNumber: "",
        emailAddress: "",
        nationalityId: 0,
      },
      // Institution Information Object
      institutionInformation: {
        corporateMemberId: 0,
        individualMemberId: 0,
        instituteName: "",
        instituteWebsite: "",
        countryOfStudiesId: 0,
        pursuingQualificationId: 0,
        specialisation: "",
        yearOfCommencementId: 0,
        yearOfCompletionId: 0,
        individualInstituteInformation: 0,
        isOtherInstitute: false,
        instituteId: 0,
        otherInstituteName: "",
      },
      individualCreditNotePaymentInfo: {},
      cancellationReason: [],
      isOtherCancellationReason: false,
      isLoading: false,
      addEditCreditNote: {
        membershipPaymentId: 0,
        entranceRefund: 0,
        annualRefund: 0,
        chapterRefund: 0,
        subTotalRefund: 0,
        gstRefund: 0,
        discountRefund: 0,
        totalRefund: 0,
        paidTotalAmount : 0,
        cancellationReason: "",
        creditNoteMonth: "",
        compareEntranceRefund: 0,
        compareAnnualRefund: 0,
        compareChapterRefund: 0,
        membershipPaymentTypeId: 0
   

      },

      // Communication Object
      sfcCommunication: {
        isGeneralNewsletter: false,
        individualMemberId: 0,
      },
      sfcIndividualCommunication: {
        isGeneralNewsletter: false,
        individualMemberId: 0,
      },
      isViewOnly: false,
      // Financial Record List
      financialRecord: [],
      // Event Booking List
      eventBookingList: [],

      otherInstituteNameValidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "other institute name"
          ),
        },
      ],
      matriculationNumbervalidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "matriculation number"
          ),
        },
      ],
      knowAboutUsOtherValidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "other channels"
          ),
        },
      ],
      othersInterestsValidationRule: [
        {
          type: "require",
          message: CommonValidationMessages.FieldRequired.replace(
            "{0}",
            "other company interests"
          ),
        },
      ],
      // Valid State object Declaration Section
      validStateCompanyInformation: {
        isValid: true,
        error: {},
      },
      validStateOtherInformation: {
        isValid: true,
        error: {},
      },
      validStateIndividualInformation: {
        isValid: true,
        error: {},
      },
      validStateInstituteInformation: {
        isValid: true,
        error: {},
      },
      validCreditNote: {
        isValid: true,
        error: {},
      },
      validCreditNote2: {
        isValid: true,
        error: {},
      },
      validStateEngagementTracking: {
        isValid: true,
        error: {},
      },
      individualFinanceInfo: [],
    };
  }

  openSection = (section) => {
    this.setState({
      openSection: this.state.openSection === section ? "" : section,
    });
    if (section === "CAI") {
      this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
    } else if (section === "engagementtrackingnotes") {
      this.getOrganisationBasicInformationById();
    } else if (section === "individualmembershipsubscription") {
      this.getChapterInformationById();
      this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
    } else if (section === "companyprofile") {
      this.getOrganisationProfileById();
    }
    // else if (section === "individiualcompanyinformation") {
    //   this.getOrganisationBussinessDetailsById();
    // }
    // else if (section === "otherinformation") {
    //   this.getOrganisationBussinessDetailsById();
    // }
    // else if (section === "individualinformation") {
    //   this.getOrganisationBussinessDetailsById();
    // }
    // else if (section === "institutioninformation") {
    //   this.getOrganisationBussinessDetailsById();
    // }
    // else if (section === "communication") {
    //   this.getOrganisationBussinessDetailsById();
    // }
    // else if (section === "financialsrecord") {
    //   this.getOrganisationBussinessDetailsById();
    // }
    // else if (section === "eventsbookings") {
    //   this.getOrganisationBussinessDetailsById();
    // }
    else if (section === "financialsrecord") {
      this.getIndividualFinancialInfo(this.state.individualMemberId);
    }
    this.setState({
      openSection: this.state.openSection === section ? "" : section,
    });
  };
  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);
    // Check for View Only
    let checkRead = window.location.search;
    let getRead = checkRead.split("=");
    let checkFinal = getRead[3];
    if (checkFinal === "ViewIndi") {
      this.setState({ isViewOnly: true });
    }

    let memberTypeId = getRead[2];

    let memberId = checkParameters(memberTypeId);
    this.setState({ isIndividualMemberId: memberId });

    let individualId = params.get("");
    let loginUser = getAuthProps();
    if (loginUser && loginUser.adminUserId && loginUser.adminUserId > 0) {
      this.setState({ adminId: loginUser.adminUserId });
    } else {
      this.setState({ adminId: 0 });
    }

    if (
      individualId &&
      (individualId = checkParameters(individualId, "Number"))
    ) {
      this.setState({ individualMemberId: individualId }, () => {
        this.getAllDropdownForIndividualMember();
      });
    } else {
      this.getAllDropdownForIndividualMember();
    }
  };

  /* -----API Method Declaration Section Start----- */

  // Get All Individual Dropdown Method Start
  getAllDropdownForIndividualMember = () => {
    this.individualMemberService
      .getAllDropdownForIndividualMember()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let status = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "AccountStatus"
          );
          status = status.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let reason = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "ReasonForLeaving"
          );
          reason = reason.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let updated_By = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "By"
          );
          updated_By = updated_By.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let recorded_by = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "By"
          );
          recorded_by = recorded_by.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let followUp_By = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "By"
          );
          followUp_By = followUp_By.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let engagement = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "EngagementType"
          );
          engagement = engagement.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let membership = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "MembershipTypes"
          );
          membership = membership.map((p) => ({
            id: p.id,
            name: p.name,
          }));

          let filterMemebrshipType = membership.filter((item) => item.id !== 3);

          membership = membership.filter(s => s.id === 4 || s.id === 5 || s.id === 6);
          let billingCategory = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "BillingCategory"
          );
          billingCategory = billingCategory.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let jobPosition = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "JobFunction"
          );
          jobPosition = jobPosition.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let industry = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "IndustryType"
          );
          industry = industry.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let interestedType = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "CompanyInterestType"
          );
          interestedType = interestedType.map((p) => ({
            value: p.id,
            label: p.name,
          }));
          let preferredType = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "PreferredChannelOfCommunication"
          );
          preferredType = preferredType.map((p) => ({
            value: p.id,
            label: p.name,
          }));
          let eNews = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Enewsletters"
          );
          eNews = eNews.map((p) => ({
            value: p.id,
            label: p.name,
          }));
          let hearAboutUs = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "GetToKnowUs"
          );
          hearAboutUs = hearAboutUs.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let salutation = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Salutation"
          );
          salutation = salutation.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let yearOfBirth = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Years"
          );
          yearOfBirth = yearOfBirth.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let nationality = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "NationalityType"
          );
          nationality = nationality.map((p) => ({
            id: p.id,
            name: p.name,
          }));

          //Institutes
          let institutes = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Institutes"
          );
          institutes.map(function (item) {
            return delete item["dropdownName"];
          });
          institutes = institutes.map((p) => ({
            id: p.id,
            name: p.name,
          }));

          let studies = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Countries"
          );
          studies = studies.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          let pursuingQualified = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "PursuingQualifications"
          );
          pursuingQualified = pursuingQualified.map((P) => ({
            id: P.id,
            name: P.name,
          }));
          let yearOfCompleted = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Years"
          );
          yearOfCompleted = yearOfCompleted.map((p) => ({
            id: p.id,
            name: p.name,
          }));
          this.setState(
            {
              status: status,
              reason: reason,
              updated_By: updated_By,
              recorded_by: recorded_by,
              followUp_By: followUp_By,
              engagementType: engagement,
              membershipType: membership,
              filterMembershipTypeList: filterMemebrshipType,
              billingCategory: billingCategory,
              jobPositionType: jobPosition,
              industryType: industry,
              companyInterestType: interestedType,
              preferredChannelType: preferredType,
              eNewsType: eNews,
              salutation: salutation,
              getToKnowUs: hearAboutUs,
              yearOfBirthType: yearOfBirth,
              nationalityType: nationality,
              studiesType: studies,
              pursuingQualifiedType: pursuingQualified,
              yearOfCompletedType: yearOfCompleted,
              institutes: institutes,
            },
            () => {
              if (
                this.state.individualMemberId != null &&
                this.state.individualMemberId > 0
              ) {
                this.getIndividualSIISInformation(
                  this.state.individualMemberId
                );
              }
            }
          );
        } else {
          if (
            this.state.individualMemberId != null &&
            this.state.individualMemberId > 0
          ) {
            this.getIndividualSIISInformation(this.state.individualMemberId);
          }
          this.swalServices.Error(response.message);
        }
      });
  };

  // Section - 1 = Individual Account Information
  getIndividualSIISInformation = (id) => {
    if (id > 0) {
      this.individualMemberService
        .getIndividualSIISInformation(id)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.message == null)
              this.setState({
                siisIndividualAccountInformation:
                  response.responseItem.responseContent,
                corporateMemberId:
                  response.responseItem.responseContent.corporateMemberId,
              });
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  // Section - 2 = Engagement Tracking and Notes
  getIndividualEngagementTrackingList = (id) => {
    if (id > 0) {
      this.individualMemberService
        .getIndividualEngagementTrackingList(id)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.setState({
              engagementTrackingList: response.responseItem.responseContent,
            });
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  onEdit = (id) => {
    this.getIndividualEngagementTrackingById(id);
  };
  getIndividualEngagementTrackingById = (id) => {
    this.individualMemberService
      .getIndividualEngagementTrackingById(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.setState({
            engagementTrackingAndNotes: response.responseItem.responseContent,
          });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };
  // Section - 3 = Individuial Membership Sunscrition
  getIndividualMemberSubscription = (individualMemberId) => {
    if (individualMemberId != null && individualMemberId > 0) {
      this.individualMemberService
        .getIndividualMemberSubscription(individualMemberId)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent != null) {
              let data = { ...this.state.membershipSubscription };
              let resp = response.responseItem.responseContent;
              // data.companyCategoryId = 0,
              data.corporateMemberBillingCategoryId =
                resp.corporateMemberBillingCategoryId;
              data.membershipTypeId = resp.membershipTypeId;
              data.subscriptionEndDate = resp.subscriptionEndDate;
              data.subscriptionJoinDate = resp.subscriptionJoinDate;
              // data.individualMemberId = 0,
              // data.individualSubscriptionId = 0,
              // data.isSubscriptionAccountStatus = 0,
              // data.subscriptionLastUpdatedDate = "",
              // data.subscriptionReasonLeaving = 0,
              // data.subscriptionRenewalNotes = "",
              // data.updatedById = 0,
              this.setState({ membershipSubscription: data });
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
      // }
      //  else {
      //   let res = { ...this.state.membershipSubscription };
      //   res.membershipTypeId = 0;
      //   this.setState({ membershipSubscription: res });
    }
  };
  // Section - 4 = Company Infomation
  getOrganisationProfileById = (id, isCorporate) => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId != null
    ) {
      // let request = new Object();
      // request.id = id;
      // request.isCorporate = isCorporate;
      let request = {
        id: id,
        isCorporate: isCorporate
      }
      // = [id, isCorporate];
      this.membershipCorporateServices
        .getOrganisationProfileById(request)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            let res = { ...this.state.companyInformation };
            res.companyName = response.responseItem.responseContent.companyName;
            res.brand = response.responseItem.responseContent.brandName;
            res.designation = response.responseItem.responseContent.designation;;
            res.jobPositionId = response.responseItem.responseContent.jobPositionId;
            //res.industryTypeId = response.responseItem.responseContent.industryId;
            res.industry = response.responseItem.responseContent.industry;
            res.companyDiscription = response.responseItem.responseContent.companyDescription;
            this.setState({ companyInformation: res });
            //   let detail = { ...this.state.corporateMemberDetail };
            //   let data = detail.companyProfile;
            //   let resp = response.responseItem.responseContent;
            //   data.companyName = resp.companyName;
            //   data.brandName = resp.brandName;
            //   data.companyTypeId = resp.companyTypeId;
            //   data.companyUEN = resp.companyUEN;
            //   data.incorporationDate = resp.incorporationDate;
            //   data.countryOfIncorporationId = resp.countryOfIncorporationId;
            //   data.companyAddress1 = resp.companyAddress1;
            //   data.companyAddress2 = resp.companyAddress2;
            //   data.postalCode = resp.postalCode;
            //   data.countryId = resp.countryId;
            //   data.companyWebsite = resp.companyWebsite;
            //   data.aCRAFilePath = resp.acraCertificatePath;
            //   data.description = resp.companyDescription;
            //   data.companyAnnualId = resp.companyAnnualSalesTurnoverId;
            //   data.employmentSizeId = resp.companyEmploymentSizeId;
            //   data.selectedBusinessIds = resp.typeOfBusinessId;
            //   data.selectedInterestedIds = resp.companyInterestedId;
            //   data.corporateMemberId = resp.corporateMemberId;
            //   data.companyProfileId = resp.companyProfileId;
            //   let array = (data.selectedBusinessIds && data.selectedBusinessIds !== '0') ? data.selectedBusinessIds.split(",") : [];
            //   data.businessTypeIds = [];
            //   array.forEach(topic => {
            //     data.businessTypeIds.push(this.state.companyBusinessType.find(topics => topics.value === Number(topic)));
            //   })
            //   array = (data.selectedInterestedIds && data.selectedInterestedIds !== '0') ? data.selectedInterestedIds.split(',') : [];
            //   data.interestIds = [];
            //   array.forEach(topic => {
            //     data.interestIds.push(this.state.companyInterestType.find(topics => topics.value === Number(topic)));
            //   });
            //   this.setState({ corporateMemberDetail: detail });
            // }
            // else {
            //   this.swalServices.Error(response.message);
            //  let detail = { ...this.state.otherInformation };
            // let data = detail.otherInformation;
            // let resp = response.responseItem.responseContent;
            // data.individualOtherInformationId = resp.individualOtherInformationId;
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
    }
  };
  // Section - 5 = Other Information
  getIndividualOthersInformationById = (id) => {
    // if (this.state.individualMemberId != null && this.state.individualMemberId > 0) {
    this.individualMemberService
      .getIndividualOthersInformationById(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let data = { ...this.state.otherInformation };
            // let resp = response.responseItem.responseContent[0];

            data.individualOtherInformationId =
              response.responseItem.responseContent.individualOtherInformationId;
            data.interestedInId =
              response.responseItem.responseContent.interestedInId;
            data.otherCompanyInterests =
              response.responseItem.responseContent.otherCompanyInterests;
            data.preferredChanneId =
              response.responseItem.responseContent.preferredChannelOfCommunicationId;
            data.eNewsLetterId =
              response.responseItem.responseContent.subscribeEnewsletterId;
            data.aboutUsId = response.responseItem.responseContent.getToKnowId;
            data.knowAboutUsOthers =
              response.responseItem.responseContent.knowAboutUsOthers;
            data.companyFacebook =
              response.responseItem.responseContent.companyFacebook;
            data.companyInstagram =
              response.responseItem.responseContent.companyInstagram;
            data.companyLinkedIn =
              response.responseItem.responseContent.companyLinkedIn;

            let array =
              data.interestedInId && data.interestedInId !== "0"
                ? data.interestedInId.split(",")
                : [];
            data.selectedInterestedIns = [];
            array.forEach((topic) => {
              data.selectedInterestedIns.push(
                this.state.companyInterestType.find(
                  (topics) => topics.value === Number(topic)
                )
              );
            });

            data.selectedInterestedIns.map((x) => {
              if (x.label === "Others,please specify") {
                return data.otherCompanyInterestsFlag = true;
              } else {
                return data.otherCompanyInterestsFlag = false;
              }
            });


            array =
              data.preferredChanneId && data.preferredChanneId !== "0"
                ? data.preferredChanneId.split(",")
                : [];
            data.selectedCommunicationChannels = [];
            array.forEach((topic) => {
              data.selectedCommunicationChannels.push(
                this.state.preferredChannelType.find(
                  (topics) => topics.value === Number(topic)
                )
              );
            });

            array =
              data.eNewsLetterId && data.eNewsLetterId !== "0"
                ? data.eNewsLetterId.split(",")
                : [];
            data.selectedEnewsletters = [];
            array.forEach((topic) => {
              data.selectedEnewsletters.push(
                this.state.eNewsType.find(
                  (topics) => topics.value === Number(topic)
                )
              );
            });
            // this.setState({ otherInformation: data });

            this.setState({ otherInformation: data });
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    // }
    //  else {
    //   let res = { ...this.state.membershipSubscription };
    //   res.membershipTypeId = 0;
    //   this.setState({ membershipSubscription: res });
    // }
  };
  // Section - 6 = Individual Information
  getIndividualInformationByIndividualId = (id) => {
    // if (this.state.individualMemberId != null && this.state.individualMemberId > 0) {
    this.individualMemberService
      .getIndividualInformationByIndividualId(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let data = { ...this.state.individualInformation };
            let resp = response.responseItem.responseContent;

            data.salutationId = resp.salutionId;
            data.firstName = resp.firstName;
            data.lastName = resp.lastName;
            data.yearOfBirth = resp.yearOfBirth;
            data.mobileNumber = resp.mobileNo;
            data.emailAddress = resp.email;
            data.nationalityId = resp.nationalityId;

            this.setState({ individualInformation: data });
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    // }
    //  else {
    //   let res = { ...this.state.membershipSubscription };
    //   res.membershipTypeId = 0;
    //   this.setState({ membershipSubscription: res });
    // }
  };
  // Section - 7 = Institute Information
  getIndividualInstituteInformationDetailsById = (id) => {
    // if (this.state.individualMemberId != null && this.state.individualMemberId > 0) {
    this.individualMemberService
      .getIndividualInstituteInformationDetailsById(id)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let data = { ...this.state.institutionInformation };
            let resp = response.responseItem.responseContent[0];
            if (resp) {
              data.individualMemberId = resp.individualMemberId;
              data.instituteName = resp.instituteName;
              data.instituteWebsite = resp.instituteWebsite;
              data.countryOfStudiesId = resp.countryOfStudiesId;
              data.pursuingQualificationId = resp.pursuingQualificationId;
              data.specialisation = resp.specialisation;
              data.yearOfCommencementId = resp.yearOfCommencementId;
              data.yearOfCompletionId = resp.yearOfCompletionId;
              data.instituteId = resp.instituteId;
              if (data.instituteId > 0) {
                let instituteName = this.state.institutes.filter(
                  (instiLabel) => instiLabel.id === data.instituteId
                );
                let instituteLabel = instituteName[0].name;
                if (instituteLabel === "Others, please specify") {
                  data.isOtherInstitute = true;
                } else {
                  data.isOtherInstitute = false;
                }
              }
              data.otherInstituteName = resp.otherInstituteName;
              data.individualInstituteInformation =
                resp.individualInstituteInformation;
              this.setState({ institutionInformation: data });
            }
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    // }
    //  else {
    //   let res = { ...this.state.membershipSubscription };
    //   res.membershipTypeId = 0;
    //   this.setState({ membershipSubscription: res });
    // }
  };
  // Section - 8 = Communication
  getIndividualCommunication = (id) => {
    if (id > 0) {
      this.individualMemberService
        .getIndividualCommunication(id)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent != null) {
              let res = { ...this.state.sfcIndividualCommunication };
              res.isGeneralNewsletter =
                response.responseItem.responseContent.isGeneralNewsLetter;
              this.setState({ sfcIndividualCommunication: res });
            }
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  // Section - 9 = Financials Record

  // Section - 10 =  Event Bookings
  getBookingListForIndividualMemberById = (individualId) => {
    if (individualId > 0) {
      let request = {};
      request.MemberId = individualId;
      request.isCorporate = false;
      this.individualMemberService
        .getBookingListForIndividualMemberById(request)
        .then((response) => {
          if (response.statusCode === 200 && response != null) {
            if (response.responseItem.responseContent != null) {
              if (response.responseItem.responseContent.length > 0) {
                let resData = response.responseItem.responseContent;
                let newArr = [];
                resData.map((x) => {
                  if (x.isGuest !== true) {
                    x.guestList = [];
                  }
                  return newArr.push(x);
                });
                newArr.map((x) => {
                  return resData.filter(
                    (guest) => (guest.bookingAttendeeInformationId !==
                      x.bookingAttendeeInformationId && guest.bookingId === x.bookingId) && guest.isGuest === true
                  )
                });
                this.setState({ eventBookingList: newArr });
              } else {
                this.setState({ eventBookingList: [] });
              }
            }
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };

  // Add Edit Srction-1 = Individual Account Information
  addEditIndividualSIISInformation = () => {
    let request = this.state.siisIndividualAccountInformation;
    request.individualMemberId = this.state.individualMemberId;
    this.individualMemberService
      .addEditIndividualSIISInformation(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (
            response.responseItem.responseContent.keyId !==
            this.state.individualMemberId
          ) {
            this.setState(
              {
                individualMemberId: response.responseItem.responseContent.keyId,
              },
              () =>
                this.getIndividualSIISInformation(this.state.individualMemberId)
            );
          }
          this.swalServices.Success(
            "Membership ID Information has been added successfully."
          );
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };
  // Add Edit Srction-2 = Engagement Tracking and Notes
  addEditEngagementTracking = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      if (this.isValidateAllFields("Engagement_Tracking")) {
        let request = this.state.engagementTrackingAndNotes;
        request.individualMemberId = this.state.individualMemberId;
        request.updatedBy = this.state.adminId ? this.state.adminId : 0;
        this.individualMemberService
          .addEditEngagementTracking(request)
          .then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Engagement tracking and notes updated successfully.");
              this.getIndividualEngagementTrackingList(
                this.state.individualMemberId
              );
              this.resetData("Engagement_Tracking");
            } else {
              this.swalServices.Error(response.message);
            }
          });
      }
    } else {
      this.swalServices.Error(
        "Please enter first Membership ID individual account information."
      );
    }
  };
  // Add Edit Srction-3 = Individual Membership Subscription
  addEditIndividualMemberSubscription = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      // if (this.isValidateAllFields("Company_Information")) {
      let request = this.state.membershipSubscription;
      request.individualMemberId = this.state.individualMemberId;
      this.individualMemberService
        .addEditIndividualMemberSubscription(request)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success("Individual membership subscription updated successfully.");
            // this.resetData("Company_Information");
          } else {
            this.swalServices.Error(response.message);
          }
        });
      // }
    } else {
      this.swalServices.Error(
        "Please enter first Membership ID individual account information."
      );
    }
  };
  // Add Edit Srction-4 = Company Information
  addEditIndividualCompanyInformation = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      if (this.isValidateAllFields("Company_Information")) {
        let request = this.state.companyInformation;
        request.individualMemberId = this.state.individualMemberId;
        request.brandName = request.brand;
        request.companyDescription = request.companyDiscription;
        this.individualMemberService
          .addEditIndividualCompanyInformation(request)
          .then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Company information updated successfully.");
              // this.resetData("Company_Information");
            } else {
              this.swalServices.Error(response.message);
            }
          });
      }
    } else {
      this.swalServices.Error(
        "Please enter first Membership ID individual account information."
      );
    }
  };
  // Add Edit Srction-5 = Other Inforamtion
  addEditIndividualOtherInformation = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      // if (this.isValidateAllFields("Other_Information")) {
      let request = { ...this.state.otherInformation };

      request.individualMemberId = this.state.individualMemberId;

      if (
        request.selectedInterestedIns &&
        request.selectedInterestedIns.length > 0
      ) {
        let arr1 = request.selectedInterestedIns.map(function (items) {
          return items.value;
        });
        request.interestedInId = arr1.join([","]);
      } else {
        request.interestedInId = "";
      }

      if (
        request.selectedCommunicationChannels &&
        request.selectedCommunicationChannels.length > 0
      ) {
        let arr1 = request.selectedCommunicationChannels.map(function (items) {
          return items.value;
        });
        request.preferredChanneId = arr1.join([","]);
      } else {
        request.preferredChanneId = "";
      }

      if (
        request.selectedEnewsletters &&
        request.selectedEnewsletters.length > 0
      ) {
        let arr1 = request.selectedEnewsletters.map(function (items) {
          return items.value;
        });
        request.eNewsLetterId = arr1.join([","]);
      } else {
        request.eNewsLetterId = "";
      }

      request.interestedId = request.interestedInId;
      request.preferredChannelId = request.preferredChanneId;
      request.getToKnowId = request.aboutUsId;


      if (this.isValidateAllFields("Other_Information")) {
        this.setState({ isLoading: true });

        this.individualMemberService
          .addEditIndividualOtherInformation(request)
          .then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              if (response.responseItem.responseContent.errorMessage === "") {
                let resp = this.state.otherInformation;

                resp.individualOtherInformationId =
                  response.responseItem.responseContent.keyId;

                this.setState({ otherInformation: resp }, () => {
                  this.swalServices.Success("Other information updated successfully");
                  this.getIndividualOthersInformationById(
                    this.state.individualMemberId,
                    true
                  );
                });
              } else {
                this.swalServices.Error(
                  ErrorMessage.Already_Exist.replace("{0}", "otherInformation")
                );
              }
            } else {
              this.swalServices.Error(ErrorMessage.DefaultMessage);
            }
            this.setState({ isLoading: false });
          });
      } else {
        this.swalServices.Warning("Please fill all mandatory fields");
      }
    } else {
      this.swalServices.Error(
        "Please enter first Membership ID individual account information."
      );
    }
  };
  // Add Edit Srction-6 = Individual Information
  updateIndividualInformationByIndividualId = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      if (this.isValidateAllFields("Individual_Information")) {
        let request = {};
        let data = this.state.individualInformation;
        request.individualMemberId = this.state.individualMemberId;
        request.salutionId = data.salutationId;
        request.firstName = data.firstName;
        request.lastName = data.lastName;
        request.yearOfBirth = data.yearOfBirth;
        request.contactNo = data.mobileNumber;
        request.nationalityId = data.nationalityId;
        request.email = data.emailAddress;
        request.updatedAt = new Date();
        request.updatedBy = 1;

        this.individualMemberService
          .updateIndividualInformationByIndividualId(request)
          .then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success(
                "Individual information inserted successfully."
              );
              // this.resetData("Individual_Information");
            } else {
              this.swalServices.Error(response.message);
            }
          });
      }
    } else {
      this.swalServices.Error(
        "Please enter first Membership ID individual account information."
      );
    }
  };
  // Add Edit Srction-7 = Institute Information
  addEditIndividualInstituteInformationDetails = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      if (this.isValidateAllFields("Institute_Information")) {
        let request = { ...this.state.institutionInformation };

        request.individualMemberId = this.state.individualMemberId;
        //request.individualInstituteInformation = request.individualInstituteInformationId
        // request.individualMemberId = this.state.individualMemberId;

        this.individualMemberService
          .addEditIndividualInstituteInformationDetails(request)
          .then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              this.swalServices.Success("Engagement inserted successfully.");
              // this.resetData("Institute_Information");
            } else {
              this.swalServices.Error(response.message);
            }
          });
      }
    } else {
      this.swalServices.Error(
        "Please enter first Membership ID individual account information."
      );
    }
  };
  // Add Edit Srction-8 = Communication
  addEditCommunicationProfile = () => {
    if (
      this.state.individualMemberId > 0 &&
      this.state.individualMemberId !== null
    ) {
      let request = this.state.sfcIndividualCommunication;
      request.individualMemberId = this.state.individualMemberId;
      this.individualMemberService
        .addEditCommunicationProfile(request)
        .then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success(
              "Individual communication profile updated successfully."
            );
            // this.getIndividualCommunication(this.state.individualMemberId);
          } else {
            this.swalServices.Error(response.message);
          }
        });
    } else {
      this.swalServices.Error(
        "Please enter first Membership ID individual account information."
      );
    }
  };

  /* ------ Download Invoice Receipt PDF Booking ------ */
  getInvoiceReceiptPdfByBookingId = (bookingId, type) => {
    let reqParams = [bookingId, type];
    this.individualMemberService
      .getInvoiceReceiptPdfByBookingId(reqParams)
      .then((response) => {
        if (response != null) {
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");
          var filename = "Booking";
          if (type === "Receipt") {
            filename = `Booking Receipt ${formattedDate}`;
          } else if (type === "Invoice") {
            filename = `Booking Invoice ${formattedDate}`;
          }
          var file = new Blob([response.data], { type: "application/pdf" });

          // const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          //  const pdfWindow = window.open();
          //  pdfWindow.location.href = fileURL;

          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };

  /* ----- Internal Method Declaration Section Start ----- */
  //Handle Change For Inputs Method Start
  handleChange(event, identity) {
    let details0 = this.state.siisIndividualAccountInformation;
    let details1 = this.state.engagementTrackingAndNotes;
    let details2 = this.state.companyInformation;
    let details3 = this.state.otherInformation;
    let details4 = this.state.individualInformation;
    let details5 = this.state.institutionInformation;
    let details6 = this.state.sfcIndividualCommunication;

    const value = event.target.value;
    const name = event.target.name;
    if (identity === "SIISInformation") {
      let detail = this.state.siisIndividualAccountInformation;
      detail[name] = value;
      this.setState({ siisIndividualAccountInformation: { ...detail } });
    } else if (identity === "Individual_Account_Info") {
      details0[name] = value;
      this.setState({ engagementTrackingAndNotes: details0 });
    } else if (identity === "Engagement_Tracking") {
      details1[name] = value;
      this.setState({ engagementTrackingAndNotes: details1 });
    } else if (identity === "Company_Information") {
      details2[name] = value;
      this.setState({ companyInformation: details2 });
    } else if (identity === "Other_Information") {
      details3[name] = value;
      this.setState({ otherInformation: details3 });
    } else if (identity === "Individual_Information") {

      details4[name] = value;
      this.setState({ individualInformation: details4 });
    } else if (identity === "Institute_Information") {
      details5[name] = value;
      this.setState({ institutionInformation: details5 });
    }

    // Handle Redio/Checkboxes Data
    else if (identity === "SFC_Communication") {
      details6[name] = !details6[name];
      this.setState({ sfcIndividualCommunication: details6 });
    } else if (identity === "CreditNote") {
      let detail = { ...this.state.addEditCreditNote };
      let val = value;
      let usedMonth = 0;
      let totalMonth = monthDifference(
        this.state.individualCreditNotePaymentInfo.subcriptionEndDate,
        this.state.individualCreditNotePaymentInfo.subscriptionStartDate,
        false
      );
      //let data = { ...this.state.corporateCreditNotePaymentInfo };
      if (name === "creditNoteMonth") {
        if (detail.paymentStatus !== "Pending") {
          detail[name] = val;
          usedMonth = monthDifference(
            detail.creditNoteMonth,
            this.state.individualCreditNotePaymentInfo.subscriptionStartDate,
            false
          );
          let paidMonth = (totalMonth - usedMonth) / totalMonth;

          detail.entranceRefund =
            this.state.individualCreditNotePaymentInfo.entranceFee;
          detail.annualRefund =
            this.state.individualCreditNotePaymentInfo.annualFee;
          detail.chapterRefund =
            this.state.individualCreditNotePaymentInfo.addtionalChapterFee;

          detail.entranceRefund = parseFloat(
            (paidMonth * detail.entranceRefund).toFixed(2)
          );
          detail.annualRefund = parseFloat(
            (paidMonth * detail.annualRefund).toFixed(2)
          );
          detail.chapterRefund = parseFloat(
            (paidMonth * detail.chapterRefund).toFixed(2)
          );

          detail.subTotalRefund = parseFloat(
            (
              parseFloat(detail.entranceRefund) +
              parseFloat(detail.annualRefund) +
              parseFloat(detail.chapterRefund)
            ).toFixed(2)
          );
          detail.gstRefund = parseFloat(
            (
              parseFloat(detail.subTotalRefund) *
              (this.state.gstRate / 100)
            ).toFixed(2)
          );
          detail.totalRefund = parseFloat(
            (
              parseFloat(detail.subTotalRefund) +
              parseFloat(detail.gstRefund) -
              parseFloat(detail.discountRefund)
            ).toFixed(2)
          );
        }
      } else if ((val = convertToDecimal(value)) || val === 0) {
        // if(data.entranceFee < val ){
        detail[name] = val;
        this.validFinanceCreditNoteForOtherReason(name, detail);
        // }
        //detail[name] = val;
        detail.subTotalRefund = parseFloat(
          (
            parseFloat(detail.entranceRefund) +
            parseFloat(detail.annualRefund) +
            parseFloat(detail.chapterRefund)
          ).toFixed(2)
        );
        detail.gstRefund = parseFloat(
          (
            parseFloat(detail.subTotalRefund) *
            (this.state.gstRate / 100)
          ).toFixed(2)
        );
        detail.totalRefund = parseFloat(
          (
            parseFloat(detail.subTotalRefund) +
            parseFloat(detail.gstRefund) -
            parseFloat(detail.discountRefund)
          ).toFixed(2)
        );
        detail.cancellationReason = value;
      }
      this.setState({ addEditCreditNote: { ...detail } });
    }
  }
  // Handle Open Each Section Method Start
  handleOpen = (value) => {
    if (value === 2) {
      this.getIndividualEngagementTrackingList(this.state.individualMemberId);
    } else if (value === 3) {
      this.getIndividualMemberSubscription(this.state.individualMemberId);
    } else if (value === 4) {
      this.getOrganisationProfileById(this.state.individualMemberId, false);
    } else if (value === 5) {
      this.getIndividualOthersInformationById(this.state.individualMemberId);
    } else if (value === 6) {
      this.getIndividualInformationByIndividualId(
        this.state.individualMemberId
      );
    } else if (value === 7) {
      this.getIndividualInstituteInformationDetailsById(
        this.state.individualMemberId
      );
    } else if (value === 8) {
      this.getIndividualCommunication(this.state.individualMemberId);
    } else if (value === 9) {
      // this.getBookingListForIndividualMemberById(this.state.individualMemberId);
    } else if (value === 10) {
      this.getBookingListForIndividualMemberById(this.state.individualMemberId);
    }
    this.setState({ open: this.state.open === value ? 0 : value });
  };
  // Handle Change Multiselect Dropdowns
  handleChangeMultiDropdown = (selected, identity) => {
    if (identity === "Interested_In") {
      let otherDetail = this.state.otherInformation;
      otherDetail.selectedInterestedIns = selected;

      otherDetail.selectedInterestedIns.map((x) => {
        if (x.label === "Others,please specify") {
          return otherDetail.otherCompanyInterestsFlag = true;
        } else {
          return otherDetail.otherCompanyInterestsFlag = false;
        }
      });

      this.setState({ otherInformation: otherDetail });
    } else if (identity === "Communication_Channel") {
      let otherDetail = this.state.otherInformation;
      otherDetail.selectedCommunicationChannels = selected;
      this.setState({ otherInformation: otherDetail });
    } else if (identity === "E_Newsletter") {
      let otherDetail = this.state.otherInformation;
      otherDetail.selectedEnewsletters = selected;
      this.setState({ otherInformation: otherDetail });
    }
    // else if (identity === "Designation_KeyRoles") {
    //   let profile = this.state.corporateMemberDetail;
    //   profile.organisationKeyRoleDetail.selectedDesignation = selected;
    //   this.setState({ corporateMemberDetail: profile });
    // }
    // else if (identity === "Org_OrganisationRoles") {
    //   let profile = this.state.corporateMemberDetail;
    //   profile.organisationKeyRoleDetail.selectedOrganisationRole = selected;
    //   this.setState({ corporateMemberDetail: profile });
    // }
  };
  // Handle Change on All Dropdown Method Start
  setFilterParameters(id, drpIdentity) {
    let details1 = this.state.siisIndividualAccountInformation;
    let details2 = this.state.engagementTrackingAndNotes;
    let details3 = this.state.membershipSubscription;
    let details4 = this.state.companyInformation;
    let details5 = this.state.otherInformation;
    let details6 = this.state.individualInformation;
    let details7 = this.state.institutionInformation;
    let cancel_reason = this.state.cancellationReason;

    /* Individual Account Information */
    if (drpIdentity === "Account_Status") {
      details1["accountStatus"] = id;
      // this.validateSIISIndividualAccount("accountStatus");
    } else if (drpIdentity === "Reason_For_Leaving") {
      details1["deletionReasonId"] = id;
      // this.validateSIISIndividualAccount("accountStatus");
    } else if (drpIdentity === "Updated_By") {
      details1["updatedBy"] = id;
      // this.validateSIISIndividualAccount("updatedBy");
    } else if (drpIdentity === "Engagement_Type") {
      /* Engagement Tracking and Notes */
      details2["corporateEngagementTypeId"] = id;
      // this.validateSIISIndividualAccount("updatedBy");
    } else if (drpIdentity === "RecordedBy_Engagement") {
      details2["corporateEngagementRecordedById"] = id;
      this.validateField("corporateEngagementRecordedById", "Engagement_Tracking");
    } else if (drpIdentity === "Follow_Up_By") {
      details2["corporateEngagementFollowUpById"] = id;
      // this.validateSIISIndividualAccount("updatedBy");
    } else if (drpIdentity === "Membership_Type") {
      /* Individual Membership Subscription */
      details3["membershipTypeId"] = id;
      this.setState({ isIndividualMemberId: id });
      // this.validateSIISIndividualAccount("updatedBy");
    } else if (drpIdentity === "Billing_Category") {
      details3["corporateMemberBillingCategoryId"] = id;
      // this.validateSIISIndividualAccount("updatedBy");
    } else if (drpIdentity === "Job_Position") {
      /* Company Information  */
      details4["jobPositionId"] = id;
      this.validateField("jobPositionId", "Company_Information");
    } else if (drpIdentity === "Industry_Type") {
      details4["industryTypeId"] = id;
      this.validateField("industryTypeId", "Company_Information");
    } else if (drpIdentity === "Interested_In") {
      /* Other Information */
      details5["interestedInId"] = id;
      this.validateField("interestedInId", "Other_Information");
    } else if (drpIdentity === "Communication_Channel") {
      details5["preferredChanneId"] = id;
      this.validateField("preferredChanneId", "Other_Information");
    } else if (drpIdentity === "E_Newsletter") {
      details5["eNewsLetterId"] = id;
      this.validateField("eNewsLetterId", "Other_Information");
    } else if (drpIdentity === "Get_To_Know") {
      details5["aboutUsId"] = id;
      this.validateField("aboutUsId", "Other_Information");
    } else if (drpIdentity === "Select_Salutation") {
      /* Individual Information */
      details6["salutationId"] = id;
      this.validateField("salutationId", "Individual_Information");
    } else if (drpIdentity === "Select_Nationality") {
      details6["nationalityId"] = id;
      this.validateField("nationalityId", "Individual_Information");
    } else if (drpIdentity === "Select_Institute") {
      /* Institute Information */
      details7["instituteId"] = id;
      if (id > 0) {
        let instituteName = this.state.institutes.filter(
          (instiLabel) => instiLabel.id === id
        );
        let instituteLabel = instituteName[0].name;
        if (instituteLabel === "Others, please specify") {
          details7["isOtherInstitute"] = true;
        } else {
          details7["isOtherInstitute"] = false;
        }
      }
      this.validateField("instituteId", "Institute_Information");
    } else if (drpIdentity === "Country_Studies") {
      details7["countryOfStudiesId"] = id;
      this.validateField("countryOfStudiesId", "Institute_Information");
    } else if (drpIdentity === "Pursuing_Qualification") {
      details7["pursuingQualificationId"] = id;
      this.validateField("pursuingQualificationId", "Institute_Information");
    } else if (drpIdentity === "Year_Commencement") {
      details7["yearOfCommencementId"] = id;
      // this.validateField("yearOfCommencementId", "Institute_Information");
    } else if (drpIdentity === "Year_Completion") {
      details7["yearOfCompletionId"] = id;
      this.validateField("yearOfCompletionId", "Institute_Information");
    } else if (drpIdentity === "cancel_reason") {
      //let othersArr = cancel_reason.filter((x) => x.id == id).find(({ name }) => name === "Others");
      //this.setState({ isOtherCancellationReason: othersArr !== null && othersArr !== undefined ? true : false });
      let data = { ...this.state.addEditCreditNote };
      let details = { ...this.state.individualCreditNotePaymentInfo };
      let findData = cancel_reason.filter((x) => x.id === id);
      if (findData.length > 0) {
        details.cancellationReason = findData[0].id;
        if (findData[0].name === "Others") {
          data.cancellationReason = "";
          this.setState({ isOtherCancellationReason: true });
          this.validFinanceCreditNoteForOtherReason("cancellationReason", data);
        } else {
          data.cancellationReason = findData[0].name;
          this.setState({ isOtherCancellationReason: false });
        }
      } else {
        data.cancellationReason = "";
        details.cancellationReason = 0;
        this.setState({
          isOtherCancellationReason: false,
          individualCreditNotePaymentInfo: details,
        });
      }
      this.validateFinanceCreditNote("cancellationReason", details);
      this.setState({
        addEditCreditNote: data,
        individualCreditNotePaymentInfo: details,
      });
    }

    this.setState({
      siisIndividualAccountInformation: details1,
      engagementTrackingAndNotes: details2,
      membershipSubscription: details3,
      companyInformation: details4,
      otherInformation: details5,
      individualInformation: details6,
      institutionInformation: details7,
    });
  }
  //Validation Method Declaration Section Start
  validateField = (key, section) => {
    if (section === "Company_Information") {
      const returnValidState = validate(
        key,
        this.state.companyInformation,
        CompanyInformationValidationRules,
        this.state.validStateCompanyInformation
      );
      this.setState({ validStateCompanyInformation: returnValidState });
    } else if (section === "Other_Information") {
      let vaidateAllFields = { ...OtherInformationValidationRules };
      if (this.state.otherInformation.aboutUsId === 6) {
        vaidateAllFields.knowAboutUsOthers =
          this.state.knowAboutUsOtherValidationRule;
      } else {
        let otherInfoDetail = this.state.otherInformation;
        otherInfoDetail.knowAboutUsOthers = null;
        this.setState({ otherInformation: otherInfoDetail });
      }

      const returnValidState = validate(
        key,
        this.state.otherInformation,
        vaidateAllFields,
        this.state.validStateOtherInformation
      );
      this.setState({ validStateOtherInformation: returnValidState });
    } else if (section === "Individual_Information") {
      const returnValidState = validate(
        key,
        this.state.individualInformation,
        IndividualInformationValidationRules,
        this.state.validStateIndividualInformation
      );
      this.setState({ validStateIndividualInformation: returnValidState });
    } else if (section === "Institute_Information") {
      const returnValidState = validate(
        key,
        this.state.institutionInformation,
        InstituteInformationValidationRules,
        this.state.validStateInstituteInformation
      );
      this.setState({ validStateInstituteInformation: returnValidState });
    }
    else if (section === "Engagement_Tracking") {
      const returnValidState = validate(
        key,
        this.state.engagementTrackingAndNotes,
        IndEngagementTrackingValidationRules,
        this.state.validStateEngagementTracking
      );
      this.setState({ validStateEngagementTracking: returnValidState });
    }
  };

  isValidateAllFields = (section) => {
    if (section === "Company_Information") {
      const newValidState = isValidForm(
        this.state.companyInformation,
        CompanyInformationValidationRules,
        this.state.validStateCompanyInformation
      );
      this.setState({ validStateCompanyInformation: newValidState });
      return newValidState.isValid;
    } else if (section === "Engagement_Tracking") {
      const newValidState = isValidForm(
        this.state.engagementTrackingAndNotes,
        IndEngagementTrackingValidationRules,
        this.state.validStateEngagementTracking
      );
      this.setState({ validStateEngagementTracking: newValidState });
      return newValidState.isValid;
    } else if (section === "Other_Information") {
      let vaidateAllFields = { ...OtherInformationValidationRules };
      if (this.state.otherInformation.aboutUsId === 6) {
        vaidateAllFields.knowAboutUsOthers =
          this.state.knowAboutUsOtherValidationRule;
      } else {
        let otherInfoDetail = this.state.otherInformation;
        otherInfoDetail.knowAboutUsOthers = null;
        this.setState({ otherInformation: otherInfoDetail });
      }
      const hasInterest = this.state.otherInformation.selectedInterestedIns.some(x => x.label === "Others,please specify");
      if (!hasInterest) {
        const objectToRemove = {
          "type": "require",
          "message": "Please select interest"

        };
        const filteredSelectedInterestedIns = vaidateAllFields.otherCompanyInterests.filter(item => {
          return item.type !== objectToRemove.type || item.message !== objectToRemove.message;
        });
        vaidateAllFields = {
          ...vaidateAllFields,
          otherCompanyInterests: filteredSelectedInterestedIns

        };
      }
      const newValidState = isValidForm(
        this.state.otherInformation,
        vaidateAllFields,
        this.state.validStateOtherInformation
      );
      this.setState({ validStateOtherInformation: newValidState });
      return newValidState.isValid;
    } else if (section === "Individual_Information") {
      const newValidState = isValidForm(
        this.state.individualInformation,
        IndividualInformationValidationRules,
        this.state.validStateIndividualInformation
      );
      this.setState({ validStateIndividualInformation: newValidState });
      return newValidState.isValid;
    } else if (section === "Institute_Information") {
      let instituteValidation = { ...InstituteInformationValidationRules };
      if (this.state.membershipTypeId === 5) {
        if (this.state.institutionInformation.isOtherInstitute === true) {
          instituteValidation.otherInstituteName =
            // this.state.otherInstituteName = this.state.otherInstituteNameValidationRule;
            this.setState({ otherInstituteName: this.state.otherInstituteNameValidationRule })
        }
        instituteValidation.countryOfStudiesId =
          this.state.countryOfStudiesIdValRule;
        instituteValidation.pursuingQualificationId =
          this.state.pursuingQualificationIdValRule;
        instituteValidation.specialisation = this.state.specialisationValRule;
        instituteValidation.yearOfCommencementId =
          this.state.yearOfCommencementIdValRule;
        instituteValidation.yearOfCompletionId =
          this.state.yearOfCompletionIdValRule;
        instituteValidation.matriculationNumber =
          this.state.matriculationNumbervalidationRule;
      } else if (this.state.membershipTypeId === 6) {
        if (this.state.institutionInformation.isOtherInstitute === true) {
          instituteValidation.otherInstituteName =
            this.state.otherInstituteNameValidationRule;
        }
        instituteValidation.jobPositionId = this.state.jobPositionIdValRule;
        instituteValidation.designation = this.state.designationValRule;
      }
      const newValidState = isValidForm(
        this.state.institutionInformation,
        InstituteInformationValidationRules,
        this.state.validStateInstituteInformation
      );
      this.setState({ validStateInstituteInformation: newValidState });
      return newValidState.isValid;
    }
  };
  //Validation Method Declaration Section End

  isRedirectOnRequest = () => {
    this.state.isViewOnly
      ? this.setState({
        route: "/Membership/MembershipTaskList/individualTaskList",
      })
      : this.setState({ route: "/Membership/ViewIndividualMembers" });
  };

  getIndividualFinanceReceiptInvoice = (receiptInvoiceId, isReceipt) => {
    if (receiptInvoiceId > 0 && receiptInvoiceId != null) {
      let request = [
        this.state.individualMemberId,
        receiptInvoiceId,
        isReceipt,
      ];
      this.individualMemberService
        .getIndividualFinanceReceiptInvoice(request)
        .then((response) => {
          if (response.data !== null && response.status === 200) {
            var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

            var filename = "";
            if (isReceipt === true) {
              filename = `Receipt-${formattedDate}`;
            } else {
              filename = `Invoice-${formattedDate}`;
            }
            var file = new Blob([response.data], { type: "application/pdf" });

            URL.createObjectURL(file);
            window.open(URL.createObjectURL(file), "_blank");

            saveAs(file, filename + ".pdf");
          } else {
            this.swalServices.Error(response.message);
          }
        });
    } else {
      this.swalServices.Warning(
        "Please enter Membership ID corporate account information."
      );
    }
  };
  getIndividualFinancialInfo = (individualMemberId) => {
    if (individualMemberId > 0 && individualMemberId != null) {
      this.individualMemberService
        .getIndividualFinancialInfo(individualMemberId)
        .then((response) => {
          if (
            response.statusCode === 200 &&
            response.responseItem.responseContent != null
          ) {
            if (response.responseItem.responseContent.length > 0) {
              this.setState({
                individualFinanceInfo: response.responseItem.responseContent,
              });
            } else {
              this.swalServices.Alert("No records found.");
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
    }
  };
  financeGetCreditNoteInvoiceForIndividual = (value) => {
    let request = [];
    request.push(value);
    if (value !== "") {
      this.individualMemberService
        .financeGetCreditNoteInvoiceForIndividual(request)
        .then((response) => {
          if (response.data !== null && response.status === 200) {
            var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

            var filename = "";
            filename = `Credit-Note-${formattedDate}`;

            var file = new Blob([response.data], { type: "application/pdf" });

            URL.createObjectURL(file);
            window.open(URL.createObjectURL(file), "_blank");

            saveAs(file, filename + ".pdf");
          } else {
            this.swalServices.Error(response.message);
          }
        });
    } else {
      this.swalServices.Error("Credit Note is not available");
    }
  };
  financeGetCreditNoteInvoice = (value) => {
    let request = [];
    request.push(value);
    if (isNullString(value) !== "") {
      this.financeService
        .financeGetCreditNoteInvoice(request)
        .then((response) => {
          if (response.data !== null && response.status === 200) {
            let formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

            let filename = "";
            filename = `Credit-Note-${formattedDate}`;

            let file = new Blob([response.data], { type: "application/pdf" });

            URL.createObjectURL(file);
            window.open(URL.createObjectURL(file), "_blank");

            saveAs(file, filename + ".pdf");
          } else {
            this.swalServices.Error(response.message);
          }
        });
    } else {
      this.swalServices.Error("No Invoice is Available");
    }
  };
  getCreditNoteInfoForIndividualMember = (
    membershipPaymentId,
    paymentStatus,
    isReceipt
  ) => {
    if (this.state.individualMemberId > 0 && membershipPaymentId > 0) {
      let request = [this.state.individualMemberId, membershipPaymentId];
      this.individualMemberService
        .getCreditNoteInfoForIndividualMember(request)
        .then((response) => {
          if (
            response.statusCode === 200 &&
            response.responseItem.responseContent != null
          ) {
            this.setState({
              individualCreditNotePaymentInfo:
                response.responseItem.responseContent,
            });
            let creditNote = { ...this.state.addEditCreditNote };
            if (
              paymentStatus === "Pending" &&
              isNullBoolean(isReceipt) === false
            ) {
              creditNote.entranceRefund = 0;
              creditNote.annualRefund = 0;
              creditNote.chapterRefund = 0;
              creditNote.subTotalRefund = 0;
              creditNote.gstRefund = 0;
              creditNote.discountRefund = 0;
              creditNote.totalRefund = response.responseItem.responseContent
                .paidPriceByReconciliation
                ? response.responseItem.responseContent
                  .paidPriceByReconciliation
                : 0;
              //creditNote.totalRefund = 0;
              creditNote.compareEntranceRefund = 0;
              creditNote.compareAnnualRefund = 0;
              creditNote.compareChapterRefund = 0;
              creditNote.creditNoteMonth = response.responseItem.responseContent
                .subscriptionStartDate
                ? response.responseItem.responseContent.subscriptionStartDate
                : new Date(); // moment();
              creditNote.paidTotalAmount =   response.responseItem.responseContent
              .paidPriceByReconciliation
              ? response.responseItem.responseContent
                .paidPriceByReconciliation
              : 0;
              creditNote.compareEntranceRefund = response.responseItem.responseContent.entranceFee ;
              creditNote.compareAnnualRefund  = response.responseItem.responseContent.annualFee;
            } else {
              creditNote.entranceRefund =
                response.responseItem.responseContent.entranceFee;
              creditNote.annualRefund =
                response.responseItem.responseContent.annualFee;
              creditNote.chapterRefund =
                response.responseItem.responseContent.addtionalChapterFee;
              creditNote.subTotalRefund =
                response.responseItem.responseContent.subTotalAmount;
              creditNote.gstRefund =
                response.responseItem.responseContent.gstAmount;
              creditNote.discountRefund =
                response.responseItem.responseContent.discountedAmount;
              creditNote.totalRefund =
                response.responseItem.responseContent.totalAmount;
              creditNote.compareEntranceRefund =
                response.responseItem.responseContent.entranceFee;
              creditNote.compareAnnualRefund =
                response.responseItem.responseContent.annualFee;
              creditNote.compareChapterRefund =
                response.responseItem.responseContent.addtionalChapterFee;
              creditNote.creditNoteMonth = ""; //response.responseItem.responseContent.subscriptionStartDate ? response.responseItem.responseContent.subscriptionStartDate : new Date();// moment();
            }
            creditNote.membershipPaymentTypeId =
              response.responseItem.responseContent.membershipPaymentTypeId;
            creditNote.paymentStatus = paymentStatus;
            creditNote.paidTotalAmount =   response.responseItem.responseContent
            .paidPriceByReconciliation
            ? response.responseItem.responseContent
              .paidPriceByReconciliation
            : 0;
            this.setState({
              addEditCreditNote: creditNote,
              gstRate: response.responseItem.responseContent.gstRate,
            });
          } else {
            this.swalServices.Error(response.message);
          }
        });
    }
  };
  getCancellationReasonForCreditNote = () => {
    this.individualMemberService
      .getCancellationReasonForCreditNote()
      .then((response) => {
        if (
          response.statusCode === 200 &&
          response.responseItem.responseContent != null &&
          response.responseItem.responseContent.length > 0
        ) {
          let cancelReason = response.responseItem.responseContent;
          this.setState({ cancellationReason: cancelReason });
        } else {
          this.swalServices.Error(response.message);
        }
      });
  };
  isValidFinanceCreditNoteForOtherReason = () => {
    const newValidState = isValidForm(
      this.state.addEditCreditNote,
      addEditCreditNoteValRules2,
      this.state.validCreditNote2
    );
    this.setState({ validCreditNote2: newValidState });
    return newValidState.isValid;
  };
  addFinanceCreditNoteForIndividual = () => {
    if (this.isValidFinanceCreditNote()) {
      if (this.isValidFinanceCreditNoteForOtherReason()) {
        if(this.state.addEditCreditNote.paidTotalAmount >= this.state.addEditCreditNote.totalRefund){

        this.setState({ isLoading: true });
        let request = {
          individualMemberId: this.state.individualMemberId,
          bookingId: this.state.addEditCreditNote.membershipPaymentId,
          isIndividualMember: true,
          creditNoteMonth:
            this.state.addEditCreditNote.membershipPaymentTypeId === 1 ||
              this.state.addEditCreditNote.membershipPaymentTypeId === 2 ||
              this.state.addEditCreditNote.membershipPaymentTypeId === 3
              ? this.state.addEditCreditNote.creditNoteMonth
              : this.state.individualCreditNotePaymentInfo.subcriptionEndDate,
              
          creditNoteTotalRefund: this.state.addEditCreditNote.totalRefund,
          creditNoteStatusId: 1,
          creditNoteItem: [],
          creditNoteCancellationReason:
            this.state.addEditCreditNote.cancellationReason,
          CreatedAppId: 114,
          CreatedAt: moment(),
          CreatedBy: 1,
          creditSubTotalAmount: this.state.addEditCreditNote.subTotalRefund,
          creditNoteGSTAmount: this.state.addEditCreditNote.gstRefund,
          creditNoteDiscountAmount: this.state.addEditCreditNote.discountRefund
            ? this.state.addEditCreditNote.discountRefund
            : 0,
          membershipPaymentTypeId:
            this.state.addEditCreditNote.membershipPaymentTypeId
        };
        let creditNoteItemList = [];
        let stardDate =
          this.state.individualCreditNotePaymentInfo.subscriptionStartDate;
        let endDate =
          this.state.individualCreditNotePaymentInfo.subcriptionEndDate;
        let membershipTypeName =
          this.state.individualCreditNotePaymentInfo.membershipTypeName;
        creditNoteItemList.push({
          creditNoteItem: `${membershipTypeName} - Entrance Fee`,
          creditNoteItemAmount: this.state.addEditCreditNote.entranceRefund,
        });
        creditNoteItemList.push({
          creditNoteItem: `${membershipTypeName} - Annual Fee (${moment(
            stardDate
          ).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`,
          creditNoteItemAmount: this.state.addEditCreditNote.annualRefund,
        });
        //creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Additional Chapter Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.chapterRefund });
        // creditNoteItemList.push({ creditNoteItem: "Membership Subtotal Amount", creditNoteItemAmount: this.state.addEditCreditNote.subTotalRefund });
        // creditNoteItemList.push({ creditNoteItem: "Membership GST Amount", creditNoteItemAmount: this.state.addEditCreditNote.gstRefund });
        // creditNoteItemList.push({ creditNoteItem: "Membership Discounted Amount", creditNoteItemAmount: this.state.addEditCreditNote.discountRefund });
        // creditNoteItemList.push({ creditNoteItem: "Membership Total Amount", creditNoteItemAmount: this.state.addEditCreditNote.totalRefund });

        request.creditNoteItem = creditNoteItemList;
        this.individualMemberService
          .addFinanceCreditNoteForIndividual(request)
          .then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
              if (response.responseItem.responseContent.errorMessage === "") {
                this.swalServices.Success(
                  SuccessMessage.Insert_Success.replace("{0}", "Credit Note")
                );
                this.getIndividualFinancialInfo(this.state.individualMemberId);
                //this.addCreditNoteInvoiceRecordsForBatchJob(response.responseItem.responseContent.keyId);
              } else {
                this.swalServices.Error(
                  ErrorMessage.Already_Exist.replace("{0}", "Credit note")
                );
              }
            } else {
              this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
          });
        }
        else{
          this.swalServices.Warning("Refund Amount Must be Less then Total Amount of Paid");
          this.setState({ isLoading: false });
        }
      }
    }
  };
  isValidFinanceCreditNote = () => {
    const newValidState = isValidForm(
      this.state.individualCreditNotePaymentInfo,
      addEditCreditNoteValRules,
      this.state.validCreditNote
    );
    this.setState({ validCreditNote: newValidState });
    return newValidState.isValid;
  };
  validateFinanceCreditNote = (key, providedState) => {
    providedState = providedState
      ? providedState
      : this.state.individualCreditNotePaymentInfo;
    const newValidState = validate(
      key,
      providedState,
      addEditCreditNoteValRules,
      this.state.validCreditNote
    );
    this.setState({ validCreditNote: newValidState });
  };
  validFinanceCreditNoteForOtherReason = (key, providedState) => {
    providedState = providedState
      ? providedState
      : this.state.addEditCreditNote;
    const newValidState = validate(
      key,
      providedState,
      addEditCreditNoteValRules2,
      this.state.validCreditNote2
    );
    this.setState({ validCreditNote2: newValidState });
  };
  resetData = (identity) => {
    let reset1 = this.state.engagementTrackingAndNotes;
    if (identity === "Engagement_Tracking") {
      reset1.individualMemberId = 0;
      reset1.individualEngagementId = 0;
      reset1.corporateEngagementTypeId = 0;
      reset1.engagementDate = "";
      reset1.engagementDescription = "";
      reset1.corporateEngagementRecordedById = 0;
      reset1.corporateEngagementFollowUpById = 0;
      reset1.updatedBy = 0;
      reset1.updatedAt = "";
    }
    this.setState({ engagementTrackingAndNotes: reset1 });
  }
  render(props) {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    const { isIndividualMemberId } = this.state;
    return (
      <div className="main-body-section accordian-body">
        <div className="">
          {/* Account Information */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="IAI"
                type="checkbox"
              // name="corporate-d"
              // value={"SIISAccountInformation"}
              // checked={this.state.openSection === "SIISAccountInformation"}
              // onClick={() => this.openSection("SIISAccountInformation")}
              // onChange={() => { }}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="IAI"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Individual Account Information
                </span>
                {/* <div className="flex items-left justify-between text-[18px] text-[#181818]">
                  Corporate ID: S12345
                </div> */}
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                <IndividualaccountInformation
                  status={this.state.status}
                  reason={this.state.reason}
                  updated_By={this.state.updated_By}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  siisIndividualAccountInformation={
                    this.state.siisIndividualAccountInformation
                  }
                  addEditIndividualSIISInformation={this.addEditIndividualSIISInformation.bind(
                    this
                  )}
                // validState={this.state.validStateSIIS}
                // validate={this.validateSIISIndividualAccount.bind(this)}
                />
              </div>
            </div>
          </div>
          {/* Account Information */}

          {/* Engagement Tracking and Notes */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="engagementtrackingnotes"
                type="checkbox"
                // name="corporate-d"
                // value={"SIISAccountInformation"}
                // checked={this.state.openSection === "SIISAccountInformation"}
                onClick={() => this.handleOpen(2)}
              // onChange={() => { }}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="engagementtrackingnotes"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Engagement Tracking and Notes
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                <EngagementTrackingNotes
                  engagementType={this.state.engagementType}
                  recorded_by={this.state.recorded_by}
                  followUp_By={this.state.followUp_By}
                  isCorporate={false}
                  engagementTrackingList={this.state.engagementTrackingList}
                  engagementTrackingAndNotes={
                    this.state.engagementTrackingAndNotes
                  }
                  validState={this.state.validStateEngagementTracking}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  onEdit={this.onEdit.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  addEditEngagementTracking={this.addEditEngagementTracking.bind(
                    this
                  )}
                />
              </div>
            </div>
          </div>
          {/* Engagement Tracking and Notes */}

          {/* Membership Subscription */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="individualmembershipsubscription"
                type="checkbox"
                // name="corporate-d"
                // value={"SIISAccountInformation"}
                // checked={this.state.openSection === "SIISAccountInformation"}
                onClick={() => this.handleOpen(3)}
              // onChange={() => { }}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="individualmembershipsubscription"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Individual Membership Subscription
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                <IndividualMembershipSubscription
                  membershipType={this.state.membershipType}
                  billingCategory={this.state.billingCategory}
                  membershipSubscription={this.state.membershipSubscription}
                  filterMembershipTypeList={this.state.filterMembershipTypeList}
                  handleChange={this.handleChange.bind(this)}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  addEditIndividualMemberSubscription={this.addEditIndividualMemberSubscription.bind(
                    this
                  )}
                />
              </div>
            </div>
          </div>
          {/* Membership Subscription */}
          {isIndividualMemberId !== "Associate Educator Member" &&
            isIndividualMemberId !== "Associate Student Member" ? (
            <>
              {/* Company Profile */}
              <div className="bg-white border border-[#c9c9c9] mb-3">
                <div className="tab w-full border-t">
                  <input
                    className="absolute opacity-0 toggle-main"
                    id="individiualcompanyinformation"
                    type="checkbox"
                    // name="corporate-d"
                    // value={"SIISAccountInformation"}
                    // checked={this.state.openSection === "SIISAccountInformation"}
                    onClick={() => this.handleOpen(4)}
                  // onChange={() => { }}
                  />
                  <label
                    className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                    htmlFor="individiualcompanyinformation"
                  >
                    <span className="small-title font-bold theme-color pl-5">
                      Company Information
                    </span>
                  </label>
                  <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                    <CompanyInformation
                      jobPositionType={this.state.jobPositionType}
                      industryType={this.state.industryType}
                      companyInformation={this.state.companyInformation}
                      validStateCompanyInformation={
                        this.state.validStateCompanyInformation
                      }
                      isIndividualMemberId={isIndividualMemberId}
                      handleChange={this.handleChange.bind(this)}
                      setFilterParameters={this.setFilterParameters.bind(this)}
                      addEditIndividualCompanyInformation={this.addEditIndividualCompanyInformation.bind(
                        this
                      )}
                    />
                  </div>
                </div>
              </div>
              {/* Company Profile */}
            </>
          ) : null}
          {/* Other Information */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="otherinformation"
                type="checkbox"
                // name="corporate-d"
                // value={"SIISAccountInformation"}
                // checked={this.state.openSection === "SIISAccountInformation"}
                onClick={() => this.handleOpen(5)}
              // onChange={() => { }}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="otherinformation"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Other Information
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                <IndividualOtherInformation
                  companyInterestType={this.state.companyInterestType}
                  preferredChannelType={this.state.preferredChannelType}
                  eNewsType={this.state.eNewsType}
                  getToKnowUs={this.state.getToKnowUs}
                  otherInformation={this.state.otherInformation}
                  validStateOtherInformation={
                    this.state.validStateOtherInformation
                  }
                  handleChange={this.handleChange.bind(this)}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  validateField={this.validateField.bind(this)}
                  handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(
                    this
                  )}
                  addEditIndividualOtherInformation={this.addEditIndividualOtherInformation.bind(
                    this
                  )}
                />
              </div>
            </div>
          </div>
          {/* Other Information */}

          {/* Individual Information */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="individualinformation"
                type="checkbox"
                // name="corporate-d"
                // value={"SIISAccountInformation"}
                // checked={this.state.openSection === "SIISAccountInformation"}
                onClick={() => this.handleOpen(6)}
              // onChange={() => { }}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="individualinformation"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Individual Information
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                <IndividualInformation
                  salutation={this.state.salutation}
                  nationalityType={this.state.nationalityType}
                  individualInformation={this.state.individualInformation}
                  validStateIndividualInformation={
                    this.state.validStateIndividualInformation
                  }
                  handleChange={this.handleChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  updateIndividualInformationByIndividualId={this.updateIndividualInformationByIndividualId.bind(
                    this
                  )}
                />
              </div>
            </div>
          </div>
          {/* Individual Information  */}

          {isIndividualMemberId === "Associate Educator Member" ||
            isIndividualMemberId === "Associate Student Member" ? (
            <>
              {/* Institution Information */}
              <div className="bg-white border border-[#c9c9c9] mb-3">
                <div className="tab w-full border-t">
                  <input
                    className="absolute opacity-0 toggle-main"
                    id="communication"
                    type="checkbox"
                    // name="corporate-d"
                    // value={"SIISAccountInformation"}
                    // checked={this.state.openSection === "SIISAccountInformation"}
                    onClick={() => this.handleOpen(7)}
                  // onChange={() => { }}
                  />
                  <label
                    className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                    htmlFor="communication"
                  >
                    <span className="small-title font-bold theme-color pl-5">
                      Institution Information
                    </span>
                  </label>
                  <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                    <IndividualInstitutionInformation
                      institutes={this.state.institutes}
                      studiesType={this.state.studiesType}
                      pursuingQualifiedType={this.state.pursuingQualifiedType}
                      yearOfCompletedType={this.state.yearOfCompletedType}
                      institutionInformation={this.state.institutionInformation}
                      validStateInstituteInformation={
                        this.state.validStateInstituteInformation
                      }
                      handleChange={this.handleChange.bind(this)}
                      validateField={this.validateField.bind(this)}
                      setFilterParameters={this.setFilterParameters.bind(this)}
                      addEditIndividualInstituteInformationDetails={this.addEditIndividualInstituteInformationDetails.bind(
                        this
                      )}
                    />
                  </div>
                </div>
              </div>
              {/* Institution Information */}
            </>
          ) : null}
          {/* Communication */}
          {/* <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="communication"
                type="checkbox"
                // name="corporate-d"
                // value={"SIISAccountInformation"}
                // checked={this.state.openSection === "SIISAccountInformation"}
                onClick={() => this.handleOpen(8)}
              // onChange={() => { }}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="communication"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Communication
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                <Communication
                  sfcCommunication={this.state.sfcCommunication}
                  sfcIndividualCommunication={
                    this.state.sfcIndividualCommunication
                  }
                  handleChange={this.handleChange.bind(this)}
                  addEditCommunicationProfile={this.addEditCommunicationProfile.bind(
                    this
                  )}
                />
              </div>
            </div>
          </div> */}
          {/* Communication */}

          {/* Financials Record */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="financialsrecord"
                type="checkbox"
                name="corporate-d"
                value={"financialsrecord"}
                checked={this.state.openSection === "financialsrecord"}
                onClick={() => this.openSection("financialsrecord")}
              // onChange={() => { }}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="financialsrecord"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Financials Record
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                <IndividualFinancialsRecord
                  individualFinanceInfo={this.state.individualFinanceInfo}
                  getIndividualFinanceReceiptInvoice={this.getIndividualFinanceReceiptInvoice.bind(
                    this
                  )}
                  financeGetCreditNoteInvoiceForIndividual={this.financeGetCreditNoteInvoiceForIndividual.bind(
                    this
                  )}
                  getCreditNoteInfoForIndividualMember={this.getCreditNoteInfoForIndividualMember.bind(
                    this
                  )}
                  individualCreditNotePaymentInfo={
                    this.state.individualCreditNotePaymentInfo
                  }
                  getCancellationReasonForCreditNote={this.getCancellationReasonForCreditNote.bind(
                    this
                  )}
                  cancellationReason={this.state.cancellationReason}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  isOtherCancellationReason={
                    this.state.isOtherCancellationReason
                  }
                  addEditCreditNote={this.state.addEditCreditNote}
                  handleChange={this.handleChange.bind(this)}
                  addFinanceCreditNoteForIndividual={this.addFinanceCreditNoteForIndividual.bind(
                    this
                  )}
                  validate={this.validateFinanceCreditNote.bind(this)}
                  validCreditNote={this.state.validCreditNote}
                  validate2={this.validFinanceCreditNoteForOtherReason.bind(
                    this
                  )}
                  validCreditNote2={this.state.validCreditNote2}
                  isLoading={this.state.isLoading}
                />
              </div>
            </div>
          </div>
          {/* Financials Record  */}

          {/* Event Booking */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="eventsbookings"
                type="checkbox"
                // name="corporate-d"
                // value={"SIISAccountInformation"}
                // checked={this.state.openSection === "SIISAccountInformation"}
                onClick={() => this.handleOpen(10)}
              // onChange={() => { }}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                htmlFor="eventsbookings"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Events Bookings
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                <EventsBookings
                  eventBookingList={this.state.eventBookingList}
                  getInvoiceReceiptPdfByBookingId={this.getInvoiceReceiptPdfByBookingId.bind(
                    this
                  )}
                  financeGetCreditNoteInvoice={this.financeGetCreditNoteInvoice.bind(
                    this
                  )}
                />
              </div>
            </div>
          </div>
          {/* Event Booking */}
        </div>
        <div className="flex items-center justify-between mt-6">
          <button
            className="btn btn-gray text-xl text-white"
            onClick={() => this.isRedirectOnRequest()}
          >
            Back
          </button>
          {/* <NavLink
              to="/Membership/ViewIndividualMembers"
              className="btn btn-gray text-xl text-white"
            >
              {" "}
              Back
            </NavLink> */}
          <button
            className="text-lg font-bold btn btn-pink text-white"
          // onClick={() => this.addMembershipPricing()}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}
