import React, { Component } from "react";

export default class Dashboard extends Component {
  render() {
    return (
      <div className="main-body-section bg-white h-auto justify-between">

      </div>
    );
  }
}
