import React from "react";
import ActionDeleteButton from "../UIComponent/ActionTableComponent/ActionDeleteButton";

function CreateAccountCodeTable(props) {
  return (
    <div>
      <div className="mb-2 overflow-x-auto">
        <table className="stretegic-member-tbl w-full mx-auto my-5 fixed_header">
          <thead>
            <tr className="text-base font-bold flex items-center">
              <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[15%]">
                <div className="font-bold tble-th-txt text-left">Created Date</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[30%]">
                <div className="font-bold tble-th-txt text-left">
                  Account Code
                </div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[25%]">
                <div className="font-bold tble-th-txt text-left">
                  Account Description
                </div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-normal text-white w-[15%]">
                <div className="font-bold tble-th-txt text-left">
                  Linked to Account Code
                </div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[15%]">
                <div className="font-bold tble-th-txt text-left">Action</div>
              </th>
            </tr>
          </thead>

          <tbody className="scrollit text-sm custom-tbody globle-setting-tbody">
            {props.accountCodeTable && props.accountCodeTable.length > 0 ? (
              <>
                {props.accountCodeTable.map((code, index) => {
                  return (
                    <tr className="custom-tbl-td flex items-center" key={index}>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[15%]">
                        {code.updatedAt}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[30%]">
                        {code.financeAccountCode}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[25%]">
                        {code.financeAccountCodeDescription}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[15%]">
                        {code.linkedAcCode}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[15%] gst-edit-btn">
                        <span className="flex items-center">
                          <button
                            className="action-btn mr-4 flex items-center"
                            aria-controls="add-edit-modal"
                            onClick={() => {
                              props.getAccountCodeDataById(index);
                            }}
                          >
                            <p className="ml-2 tool-tip-txt underline text-[#81a3b8]">
                              Edit
                            </p>
                          </button>
                          <ActionDeleteButton
                            onActionClick={() => props.deleteAccountCode(code.financeAccountCodeId)}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                <tr className="grid justify-items-center">
                  <td className="text-lg text-[#181818]	">No Records Found</td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CreateAccountCodeTable;
