import React, { useState } from 'react';
import moment from 'moment';
import { isNullString } from '../../../utils/Utils';
import { formatPrice } from '../../common/FormatePrice';

function EventsBookings(props) {
  const [openTab, setOpenTab] = useState(1);
  return (
    <>
      {
        props && props.eventBookingList?.length > 0 ?
          (
            <>
              {
                props.eventBookingList.map((bookingData, key) => {
                  return (
                    <>
                      <div className=''>
                        <div className="2xl:py-10 lg:pt-7 2xl:pt-0 lg:pb-5 guest-event-booking-main">
                          <div className="grid grid-cols-12 gap-6 w-full ">
                            <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-4 items-center p-[25px]">
                              <div className="grid grid-cols-12 gap-6">
                                <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                  <div className="flex items-center justify-between">
                                    <div className="left-side w-[50%]">
                                      <h2 className="text-xl font-bold text-[#b92262] 2xl:mb-3">
                                        {bookingData.eventName}
                                      </h2>
                                      <h2 className="text-xl theme-color 2xl:mb-3">
                                        {bookingData.companyName}
                                      </h2>
                                    </div>

                                    {bookingData.lessonList &&
                                      bookingData.lessonList.length >
                                      0 ? (
                                      <div className="right-side w-[50%] conferance-data-showing">
                                        <div className="custom-accordian">
                                          <div className="col">
                                            <div className="tabs">
                                              <div className="tab">
                                                <input
                                                  type="checkbox"
                                                  id={`Acc_B/${key}_L/`}
                                                />
                                                <label
                                                  className="text-xl text-[#aa3361] tab-label"
                                                  htmlFor={`Acc_B/${key}_L/`}
                                                >
                                                  View session
                                                  details
                                                </label>
                                                {bookingData.lessonList.map(
                                                  (
                                                    les,
                                                    lesIndex
                                                  ) => {
                                                    return (
                                                      <div
                                                        className="tab-content"
                                                        key={
                                                          lesIndex
                                                        }
                                                      >
                                                        <h3 className="text-[#181818] text-lg">
                                                          {/* 16 Nov 2022 (Wed), 12:00 PM - 2:00 PM */}
                                                          {moment(
                                                            les.eventLessonDate
                                                          ).format(
                                                            "DD MMM YYYY (ddd),"
                                                          )}
                                                          {
                                                            les.eventStartTime
                                                          }{" "}
                                                          -{" "}
                                                          {
                                                            les.eventEndTime
                                                          }
                                                        </h3>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {/* <div className="right-side w-[50%]">
                                      <h2 className="text-xl theme-color 2xl:mb-3">
                                        3 Jan 2022 (Mon), 9:00AM - 1:00PM
                                      </h2>
                                      <h2 className="text-xl font-bold text-[#b92262] 2xl:mb-3">
                                        <div>+ View session details</div>
                                      </h2>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="2xl:col-span-2  lg:col-span-3 col-span-12 mt-[10px] mr-[0px]">
                              <div className="flex justify-end items-end">
                                <div className="right-status-label">
                                  <span className="lg:text-[16px] text-[14px] text-white font-semibold bg-[#7a9763] 2xl:px-5 md:px-3 2xl:py-3 lg:px-3 lg:py-3 md:px-3 md:py-3 py-3 px-3">
                                    {bookingData.bookingStatus}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className="mt-2 border-t border-t-[#c9c9c9]"></hr>
                          <div className="grid grid-cols-12 gap-6 w-full p-[25px]">
                            <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-4 items-center">
                              <div className="grid grid-cols-12 gap-6">
                                <div className="2xl:col-span-12 lg:col-span-12 col-span-12">
                                  <div className="grid grid-cols-12 gap-6">
                                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                      <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] font-bold theme-color w-[50%]">
                                          Booking Ref:
                                        </h2>
                                        <h2 className="lg:text-[16px] text-[14px] font-bold theme-color w-[50%]">
                                          {bookingData.bookingReferenceNo}
                                        </h2>
                                      </div>
                                      <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          Booking Date:
                                        </h2>
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          {moment(bookingData.bookingDate).format("DD MMM YYYY")}
                                        </h2>
                                      </div>
                                      <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          Attendee Name:
                                        </h2>
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          {bookingData.firstName}  {bookingData.lastName}
                                        </h2>
                                      </div>
                                      <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">Type:</h2>
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]"> {bookingData.eventTypeName}</h2>
                                      </div>
                                      <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          Optional Add-on:
                                        </h2>
                                        {bookingData.addOnList.length > 0 ?
                                          <div>
                                            {bookingData.addOnList.map((addon, addonIndex) => {
                                              return (
                                                <div className=" flex flex-col" key={addonIndex}>
                                                  <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[60%] whitespace-nowrap" >
                                                    {addon.eventAddOnType}
                                                  </h2>
                                                </div>
                                              );
                                            })}
                                          </div>
                                          : "-"}
                                      </div>
                                    </div>
                                    <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                                      <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] font-bold theme-color w-[50%]">
                                          Total (GST):
                                        </h2>
                                        <h2 className="lg:text-[16px] text-[14px] font-bold theme-color w-[50%]">
                                          {/* {`$${bookingData.totalPrice}`} */}
                                        {formatPrice(bookingData.totalPrice)}
                                          {/* {`$${parseFloat((bookingData.totalPrice + bookingData.addOnPrice - bookingData.discountPrice) + Number(bookingData.totalPrice + bookingData.addOnPrice - bookingData.discountPrice) * 9 / 100).toFixed(2)}`} */}
                                        </h2>
                                      </div>
                                      <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          GST :
                                        </h2>
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          {/* {`$${bookingData.gstPrice}`} */}
                                          {formatPrice(bookingData.gstPrice)}
                                          {/* {`$${parseFloat(Number(bookingData.totalPrice + bookingData.addOnPrice - bookingData.discountPrice) * 9 / 100).toFixed(2)}`} */}
                                        </h2>
                                      </div>
                                      <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">Discount:</h2>
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          {bookingData.discountPrice >
                                            0
                                            ? `$-${formatPrice(parseFloat(
                                              bookingData.discountPrice
                                            ).toFixed(2))}`
                                            : "N.A"}
                                        </h2>
                                      </div>
                                      <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          Base Price:
                                        </h2>
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          {/* {`$${parseFloat(bookingData.subTotalPrice-bookingData.addOnPrice).toFixed(2)}`} */}
                                         {formatPrice(bookingData.subTotalPrice-bookingData.addOnPrice)}
                                        </h2>
                                      </div>
                                      <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          Add On Price:
                                        </h2>
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          {
                                            // bookingData.bookingStatusId !== 2 && bookingData.paymentVia !== "Credit Card" ? bookingData.paidPrice.toFixed(2) :
                                            `$${formatPrice(parseFloat(
                                              bookingData.addOnPrice
                                            ).toFixed(2))}`
                                          }
                                        </h2>
                                      </div>
                                      {/* <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          Price Paid:Add On Price:
                                        </h2>
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          {bookingData.bookingStatusId !== 2 && bookingData.paymentVia !== "Credit Card" ? bookingData.paidPrice.toFixed(2) :
                                            `$${parseFloat(
                                              bookingData.addOnPrice +
                                              bookingData.totalPrice -
                                              bookingData.discountPrice
                                            ).toFixed(2)}`
                                          }
                                        </h2>
                                      </div> */}
                                      {/* <div className="flex items-center mb-1">
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          Attendee Name:
                                        </h2>
                                        <h2 className="lg:text-[16px] text-[14px] theme-color w-[50%]">
                                          {bookingData.firstName}  {bookingData.lastName}
                                        </h2>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="2xl:col-span-2 lg:col-span-3 col-span-12">
                              <div className="right-btn w-full mt-5">
                                {bookingData.bookingStatusId === 2 || bookingData.bookingStatusId === 7 ?
                                  <div className="flex items-center justify-end mb-5">
                                    <button
                                      className="2xl:px-5 lg:px-4 pt-3 py-2 btn-pink-border text-xl border text-[#b92262]"
                                      onClick={() => props.getInvoiceReceiptPdfByBookingId(bookingData.bookingId, "Invoice")}
                                    >
                                      <span className="text-[#b92262] font-semibold tracking-normal underline">
                                        View Invoice
                                      </span>
                                    </button>
                                  </div>
                                  : null}
                                {bookingData.bookingStatusId === 2 ?
                                  <div className="flex items-center justify-end">
                                    <button
                                      className="2xl:px-7 lg:px-5 px-5 pt-3 py-2 btn-pink-border text-xl border text-[#b92262]"
                                      onClick={() => props.getInvoiceReceiptPdfByBookingId(bookingData.bookingId, "Receipt")}
                                    >
                                      <span className="text-[#b92262] font-semibold tracking-normal underline">
                                        View Receipt
                                      </span>
                                    </button>
                                  </div>
                                  : null}

                              </div>
                            </div>
                            {isNullString(bookingData.creditNotePath) !== "" ? (
                              <>
                                <div className="col-span-12 2xl:pt-7 lg:mt-4 mt-2  flex items-center justify-end mb-5 w-[100%]">
                                  <button className="2xl:px-7 lg:px-5 px-5 py-2 btn-pink-border lg:w-[300px] text-xl border text-[#aa3361]"
                                    onClick={() => {
                                      props.financeGetCreditNoteInvoice(bookingData.creditNotePath)
                                    }}>
                                    <span className="lg:text-[14px] text-[16px] text-[#aa3361] font-semibold tracking-normal underline">
                                      Download Credit Note
                                    </span>
                                  </button>
                                </div>
                              </>
                            ) : null}
                            {/* <div className='2xl:col-span-12 lg:col-span-12 col-span-12'>
                              <div className="flex items-center justify-end">
                                <button
                                  className="2xl:px-[15px] lg:px-[10px] py-2 btn btn-pink text-white"
                                // onClick={() => props.generateCreditNote()}
                                >
                                  <span className="text-xl text-white font-semibold tracking-normal">
                                    Generate Credit Note
                                  </span>
                                </button>
                              </div>
                            </div> */}

                            {/* Bottom-section Hide Show on Conditionally Start */}
                            {openTab === 1 &&
                              bookingData.bookingStatusId === 2 ? (
                              <div className="" key={`CancelSec_${key}`}>
                                <div className="">
                                  <button key={`CancelButton_${key}`}
                                    className="booking-request-btn btn btn-pink-custom  text-lg text-white"
                                    onClick={(e) => {
                                      this.setState({ bookingIdToCancel: bookingData.bookingId }, () => {
                                        //this.cancelBooking(bookingData);
                                        e.stopPropagation();
                                        // this.setState({ setRequesttocancelModalOpen: true });
                                      })
                                    }}
                                  >
                                    Request to Cancel
                                  </button>
                                </div>
                              </div>
                            ) : null}
                            {openTab === 1 &&
                              bookingData.bookingStatusId === 5 ? (
                              <div className="grid grid-cols-1 gap-6 items-center justify-end" key={`PendingCancelSec_${key}`}>
                                <div className="xl:col-span-3 lg:col-span-3 col-span-12 flex justify-end w-full">
                                  <button className="booking-request-btn btn btn-light-gray text-lg text-white cursor-not-allowed">
                                    Pending Cancellation
                                  </button>
                                </div>
                              </div>
                            ) : null}
                            {/* Bottom-section Hide Show on Conditionally End */}
                          </div>
                          {bookingData.guestList &&
                            bookingData.guestList.length > 0 ? (
                            <div className="w-full bg-[#ffffff] px-[25px] py-[25px] ">
                              <div className="custom-accordian bottom-acc">
                                <div className="col">
                                  <div className="tabs">
                                    <div className="tab ">
                                      <input
                                        type="checkbox"
                                        id={`Acc_?B/${key}_L`}
                                      />
                                      <label
                                        className="text-xl text-[#aa3361] tab-label bg-[#ffffff] py-3 w-full block"
                                        htmlFor={`Acc_?B/${key}_L`}
                                      >
                                        Invited Guest(s)
                                      </label>
                                      <div className="tab-content">
                                        <div className="px-2 2xl:pb-5  lg:pt-4 pt-2">
                                          <h2 className="text-xl text-[#181818] 2xl:mb-3">
                                            My Invited Guest
                                            Information
                                          </h2>

                                          {bookingData.guestList.map(
                                            (guest, guestIndex) => {
                                              return (
                                                <div
                                                  className="border-b pb-4 last:border-b-0"
                                                  key={guestIndex}
                                                >
                                                  <div className="grid grid-cols-12 gap-6 w-full ">
                                                    <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-2 lg:mt-2 items-center">
                                                      <div className="grid grid-cols-12 gap-6">
                                                        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                                          <div className="flex items-baseline justify-between">
                                                            <div className="left-side w-[50%]">
                                                              {/* <h2 className="text-xl text-[#181818] 2xl:mb-3">
                                                        My Invited Guest Information
                                                      </h2> */}
                                                              <h2 className="text-xl text-[#181818] 2xl:mb-3 mb-2 font-bold">
                                                                Guest{" "}
                                                                {guestIndex +
                                                                  1}
                                                              </h2>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {/* <hr className="mt-2 border-t border-t-[#c9c9c9]"></hr> */}
                                                  <div className="grid grid-cols-12 gap-6 w-full ">
                                                    <div className="col-span-12 lg:mt-0 mt-2 items-center">
                                                      <div className="grid grid-cols-12 gap-6">
                                                        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                                                          <div className="grid grid-cols-12 gap-6">
                                                            <div className="xl:col-span-10 lg:col-span-10 col-span-12">
                                                              <div className="grid grid-cols-12 gap-6">
                                                                <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                                                  <div className="flex items-center mb-1">
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[40%]">
                                                                      Booking
                                                                      Ref:
                                                                    </h2>
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[60%]">
                                                                      {guest.bookingReferenceNo}
                                                                    </h2>
                                                                  </div>
                                                                  <div className="flex items-center mb-1">
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[40%]">
                                                                      First
                                                                      Name:
                                                                    </h2>
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[60%]">
                                                                      {guest.firstName}
                                                                    </h2>
                                                                  </div>
                                                                  <div className="flex items-center mb-1">
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[40%]">
                                                                      Mobile:
                                                                    </h2>
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[60%]">
                                                                      {guest.contactNo}
                                                                    </h2>
                                                                  </div>
                                                                  <div className="flex items-center mb-1">
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[40%] block">
                                                                      Company:
                                                                    </h2>
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[60%] block">
                                                                      {guest.companyName}
                                                                    </h2>
                                                                  </div>
                                                                </div>
                                                                <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                                                                  <div className="flex items-center mb-1">
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[40%]">
                                                                      Last
                                                                      Name:
                                                                    </h2>
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[60%]">
                                                                      {guest.lastName}
                                                                    </h2>
                                                                  </div>
                                                                  <div className="flex items-center mb-1">
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[40%]">
                                                                      Email:
                                                                    </h2>
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[60%]">
                                                                      {guest.email}
                                                                    </h2>
                                                                  </div>
                                                                  <div className="flex items-center mb-1">
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[40%]">
                                                                      Job
                                                                      Title:
                                                                    </h2>
                                                                    <h2 className="lg:text-[16px] text-[14px] text-[#181818] w-[60%]">
                                                                      N.A.
                                                                    </h2>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="xl:col-span-2 lg:col-span-2 col-span-12">
                                                              <div className="justify-end flex w-full items-start">
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                            :
                            null
                          }
                        </div>

                      </div>
                    </>
                  )
                })
              }
            </>
          )
          :
          null
      }
    </>
  )
}
export default EventsBookings