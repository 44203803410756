import React from "react";
import AddEditOrganisationKeyRole from "../../modal/membership/AddEditOrganisationKeyRole";

function OrganisationRole(props) {
  return (
    <>
      <div className="p-[25px]">
        <div className="grid grid-cols-12 gap-6 w-full items-center">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="overflow-x-auto">
              <div className="maping-table">
                <table className="w-full bg-white rounded-sm contribution-tble">
                  {/* Table body */}
                  <tbody className="text-sm custom-tbody">
                    {props.organisationKeyRoleList.map((organisation, key) => (
                      <tr className="custom-tbl-td-contribution" key={key}>
                        <td className="w-[25%] whitespace-nowrap text-[#b92262] text-xl font-bold text-left pb-2">
                          {`${organisation.firstName} ${organisation.lastName}`}
                        </td>
                        <td className="w-[25%] p-0 whitespace-nowrap text-[#181818] font-medium text-xl text-left pb-2">
                          {organisation.role}
                        </td>
                        <td className="w-[50%] p-0 whitespace-nowrap text-[#181818] text-xl text-right pb-2">
                          {organisation.isFinance ? null :
                            <button
                              onClick={(e) => {
                                props.onModalOnOff(true, organisation.organisationRoleId)
                              }}
                              className="underline text-xl text-[#b92262] plush-btn"
                            >
                              Edit Profile
                            </button>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="add-edite-profile-popup">
          <AddEditOrganisationKeyRole
            salutation={props.salutation}
            jobFunctionList={props.jobFunctionList}
            organisationRole={props.organisationRole}
            organisationRolesList={props.organisationRolesList}
            organisationKeyRoleDetail={props.organisationKeyRoleDetail}
            organisationKeyRoleModal={props.setOrganisationKeyRoleModal}
            handleChange={props.handleChange}
            setFilterParameters={props.setFilterParameters}
            handleChangeMultiDropdown={props.handleChangeMultiDropdown}
            validStateOrganisationKeyRole={props.validStateOrganisationKeyRole}
            addEditOraganisation={props.addEditOraganisation}
            validateField={props.validateField}
            setModalOpen={() => props.onModalOnOff(false)}
            onClose={(e) => {
              e.stopPropagation();
              props.onModalOnOff(false);
            }}
            deleteOrganisationRole={props.deleteOrganisationRole}
          />
        </div>
      </div>

    </>
  );
}
export default OrganisationRole;