import React from "react";
import ModalBasic from "../../../component/ModalBasic";

const SendForApproveModal = (props) => {
    return (
        <ModalBasic
            id="send-for-approve-modal"
            modalOpen={props.setSendApproveModal}
            setModalOpen={props.setOpenModal}
            title=""
        >
            <div className="text-center pb-2">
                <h1 className="text-xl text-[#181818] font-normal leading-loose p-8 py-4">
                    {/* {`${props.companyName} got your request , We will share the update on ${props.email}.`} */}
                    This application has been sent for approval
                </h1>
                <button
                    className="bg-[#AA3361] text-base text-[#fff] font-bold px-12 py-2 my-4"
                    onClick={props.onClose}
                >
                    Ok
                </button>
            </div >
        </ModalBasic>
    );
}
export default SendForApproveModal;