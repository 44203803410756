import React from 'react';
import DropdownSelect from '../../dropdown/Dropdown';
import TextInput from '../../UIComponent/TextInput';
import ValidationText from '../../../utils/validation/ValidationText';

import { Selector } from "../../allTypesInputComponents/AllTypeInputComponents";

function IndividualOtherInformation(props) {
  let flag = false;
  if (props.getToKnowUs.length > 0 && props?.otherInformation.aboutUsId > 0) {
    let arr2 = props?.getToKnowUs?.find((arr) => arr.id === props?.otherInformation.aboutUsId);
    flag = (arr2.name === "Other channel, please specify") ? true : false;

  }
  return (
    <div className='p-[25px]'>
      <div className="grid grid-cols-12 gap-6 w-full ">

        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                What are you interested in?
                <span className="text-[#AA3361]">*</span> {"(You can select more than one option) "}
              </h2>
              <Selector
                // disabled={true}
                isMulti
                drpIdentity={"Interested_In"}
                options={props.companyInterestType}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={props.handleChangeMultiDropdown}
                allowSelectAll={true}
                setFilterParameters={props.setFilterParameters}
                value={props.otherInformation.selectedInterestedIns}
              />
              <ValidationText error={props.validStateOtherInformation.error.selectedInterestedIns} />
              {/* <DropdownSelect
                  // disabled={true}
                  drpIdentity={"Interested_In"}
                  optionArray={props.companyInterestType}
                  setFilterParameters={props.setFilterParameters}
                  value={props.otherInformation.interestedInId}
                /> */}
              {/* <ValidationText error={props.validStateOtherInformation.error.interestedInId} /> */}
            </div>
          </div>
        </div>
        {
          props.otherInformation.otherCompanyInterestsFlag ?
            <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
              <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12">
                  <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                    Other Interests
                    <span className="text-[#AA3361]">*</span>
                  </h2>
                  <TextInput
                    placeholder=""
                    name="otherCompanyInterests"
                    value={props.otherInformation.otherCompanyInterests}
                    identity="Other_Information"
                    handleChange={props.handleChange}
                  // onBlur={() => props.validateField("otherCompanyInterests", "Other_Information")}
                  />
                  <ValidationText error={props.validStateOtherInformation.error.otherCompanyInterests} />
                </div>
              </div>
            </div>
            :
            null
        }

        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                Preferred Channel of Communication
                <span className="text-[#AA3361]">*</span> {"(You can select more than one option) "}
              </h2>
              <Selector
                // disabled={true}
                isMulti
                drpIdentity={"Communication_Channel"}
                options={props.preferredChannelType}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={props.handleChangeMultiDropdown}
                allowSelectAll={true}
                setFilterParameters={props.setFilterParameters}
                value={props.otherInformation.selectedCommunicationChannels}
              />
              {/* <DropdownSelect
                  // disabled={true}
                  drpIdentity={"Communication_Channel"}
                  optionArray={props.preferredChannelType}
                  setFilterParameters={props.setFilterParameters}
                  value={props.otherInformation.preferredChanneId}
                /> */}
              <ValidationText error={props.validStateOtherInformation.error.selectedCommunicationChannels} />
            </div>
          </div>
        </div>
        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                Subscribe to E-newsletter
                <span className="text-[#AA3361]">*</span> {"(You can select more than one option) "}
              </h2>
              <Selector
                // disabled={true}
                isMulti
                drpIdentity={"E_Newsletter"}
                options={props.eNewsType}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={props.handleChangeMultiDropdown}
                allowSelectAll={true}
                // setFilterParameters={props.setFilterParameters}
                value={props.otherInformation.selectedEnewsletters}
              />
              {/* <DropdownSelect
                  // disabled={true}
                  drpIdentity={"E_Newsletter"}
                  optionArray={props.eNewsType}
                  setFilterParameters={props.setFilterParameters}
                  value={props.otherInformation.eNewsLetterId}
                /> */}
              <ValidationText error={props.validStateOtherInformation.error.selectedEnewsletters} />
            </div>
          </div>
        </div>
        <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12">
              <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                How did you hear about us?
                <span className="text-[#AA3361]">*</span>
              </h2>
              <DropdownSelect
                drpIdentity={"Get_To_Know"}
                optionArray={props.getToKnowUs}
                setFilterParameters={props.setFilterParameters}
                value={props.otherInformation.aboutUsId}
              />
              <ValidationText error={props.validStateOtherInformation.error.aboutUsId} />
            </div>
          </div>
        </div>
        {
          (flag) &&
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Others
                  <span className="text-[#AA3361]">*</span>
                </h2>
                <TextInput
                  placeholder=""
                  name="knowAboutUsOthers"
                  value={props.otherInformation.knowAboutUsOthers}
                  identity="Other_Information"
                  handleChange={props.handleChange}
                // onBlur={() => props.validateField("knowAboutUsOthers", "Other_Information")}
                />
                <ValidationText error={props.validStateOtherInformation.error.knowAboutUsOthers} />
              </div>
            </div>
          </div>

        }
        <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
          <div className="flield-sec">
            <label className="text-[#181818] text-[20px] big-bullet-points font-bold 2xl:mb-1 inline-block mobile-14">
              Company Facebook
            </label>
            <div className="flield-sec">
              <TextInput
                placeholder=""
                name="companyFacebook"
                value={props.otherInformation.companyFacebook}
                identity="Other_Information"
                handleChange={props.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
          <div className="flield-sec flex flex-col">
            <label className="text-[#181818] text-[20px] big-bullet-points font-bold  2xl:mb-1 inline-block mobile-14 w-full">
              Company Instagram
            </label>
            <TextInput
              placeholder=""
              type="text"
              name="companyInstagram"
              value={props.otherInformation.companyInstagram}
              identity="Other_Information"
              handleChange={props.handleChange}
            />
          </div>
        </div>
        <div className="input-sec-texts 2xl:col-span-6 lg:col-span-6 col-span-12">
          <div className="flield-sec">
            <label className="text-[#181818] text-[20px] big-bullet-points font-bold 2xl:mb-1 inline-block mobile-14">
              Company LinkedIn
            </label>
            <div className="flield-sec">
              <TextInput
                placeholder=""
                type="text"
                name="companyLinkedIn"
                value={props.otherInformation.companyLinkedIn}
                identity="Other_Information"
                handleChange={props.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="xl:col-span-12 lg:col-span-12 col-span-12">
        <div className="flex items-center justify-end">
          <button
            className="btn btn-pink text-xl text-white font-semibold"
            onClick={() => props.addEditIndividualOtherInformation()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  )
}
export default IndividualOtherInformation;