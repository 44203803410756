import React from "react";
import { CheckboxInput } from "../../allTypesInputComponents/AllTypeInputComponents";

const QuotationSectionWithBorder = (props) => {
  return (
    <>
      <div className="border-solid border border-[#181818] w-full mb-8">
        <div className=" grid grid-cols-grid-3 border-b border-[#181818]">
          <div className="flex justify-between px-4">
            <div className="text-xl text-left py-4 pl-4 text-[#181818] font-bold">
              {props.title}
            </div>
            <div className="flex items-center">
              <div className="text-xl text-left py-4 pl-4 text-[#181818] font-bold">
                {props.No}
              </div>
              <div className="text-xl text-left py-4 pl-4 text-[#181818] font-bold">
                {` Company Requires Quotation: ${
                  
                  props.taskListDetail.sendQuotation === true ? "Yes" : "No"
                }`}
              </div>
              <div className="text-xl text-left py-4 pl-4 text-[#181818] font-bold">
                {props.Yes}
              </div>
            </div>
          </div>
        </div>
        {props.children}
      </div>
    </>
  );
};
export default QuotationSectionWithBorder;
