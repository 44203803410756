import React from "react";
import ModalBasic from "../../../component/ModalBasic";

const EscalateModal = (props) => {
    return (
        <ModalBasic
            id="send-invoice-modal"
            modalOpen={props.setSendInvoiceModal}
            setModalOpen={props.setOpenModal}
            title=""
            noCloseIcon={true}
        >
            <div className="text-center pb-2">
                <h1 className="text-xl text-[#181818] font-normal leading-loose p-8 py-4">
                    Membership application has been escalated.
                </h1>
                <button
                    className="bg-[#AA3361] text-base text-[#fff] font-bold px-12 py-2 my-4"
                    onClick={props.onClose}
                >
                    Ok
                </button>
            </div >
        </ModalBasic>
    );
}
export default EscalateModal;