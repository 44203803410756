import React from "react";
import moment from "moment";
import ActionDeleteButton from "../UIComponent/ActionTableComponent/ActionDeleteButton";

function GstTable(props) {
  return (
    <div>
      <div className="mb-2 overflow-x-auto">
        <table className="stretegic-member-tbl w-full mx-auto my-5 fixed_header">
          <thead>
            <tr className="text-base font-bold flex">
              <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[28%]">
                <div className="font-bold tble-th-txt text-left">Start Date</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[28%]">
                <div className="font-bold tble-th-txt text-left">End Date</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[28%]">
                <div className="font-bold tble-th-txt text-left">Percentage</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 tbl-th 2xl:whitespace-nowrap lg:whitespace-normal text-white w-[16%]">
                <div className="font-bold tble-th-txt text-left">Action</div>
              </th>
            </tr>
          </thead>

          <tbody className="scrollit text-sm custom-tbody globle-setting-tbody">
            {props.gstTableData && props.gstTableData.length > 0 ? (
              <>
                {props.gstTableData.map((gst, gstIndex) => {
                  return (
                    <tr className="custom-tbl-td flex items-center" key={gstIndex}>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[28%]">
                        {moment(gst.gstStartDate).format("D MMM YYYY")}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[28%]">
                        {moment(gst.gstEndDate).format("D MMM YYYY")}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[28%]">
                        {`${gst.gstPercentage}%`}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#181818] w-[16%] gst-edit-btn">
                        <span className="flex items-center">
                          <button
                            className="action-btn mr-4 flex items-center"
                            aria-controls="add-edit-modal"
                            onClick={() => {
                              props.getGstDataById(gstIndex);
                            }}
                          >
                            <p className="ml-2 mr-2 tool-tip-txt underline text-[#81a3b8]">
                              Edit
                            </p>
                          </button>
                          <ActionDeleteButton
                            onActionClick={() =>
                              props.gstDelete(gst.financeGSTId)
                            }
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                <tr className="grid justify-items-center">
                  <td className="text-lg text-[#181818]	">No Records Found</td>
                </tr>
              </>
            )}
            {/* <tr className="grid justify-items-center">
              <td className="text-lg text-[#181818]	">No Records Found</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default GstTable;
