import React from "react"
const TextInput = (props) => {
    return (
        <input
            className={`w-full outline-none text-[16px] appearance-none form-input rounded-none ${props.className} ${props.isCurserAllowed === false ? 'cursor-not-allowed bg-[#e9e9ea]  border-[#181818]  text-[#181818]' : ''}`}
            placeholder={props.placeholder}
            type="text"
            name={props.name}
            value={props.value}
            min={props.min}
            max={props.max}
            maxLength={props.maxLength}
            onChange={(event) => props.handleChange(event, props.identity)}
            onBlur={props.onBlur ? (e) => props.onBlur(props.identity) : undefined}
            disabled={props.disabled === true ? true : false}
            autoComplete={props.autoComplete ? props.autoComplete : "off"}
        />
    );
}
export default TextInput;
