import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import React, { useState } from "react";
import AddEditOrganisationEmployee from "../../modal/membership/AddEditOrganisationEmployee";

function EmployeeList(props) {
  const [open, setOpen] = useState(0);
  const [openEmapSection, setOpenEmapSection] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = (value) => setOpen(open === value ? null : value);
  return (
    <>
      <div className="p-[25px]">
        {
          props?.organisationEmployeeList.map((emp, key) => {
            return (
              <Accordion open={open === key} className="border-b border-[#181818] last:border-b-0" key={key}>
                <div className="relative sub-accordian">
                  <AccordionHeader
                    onClick={() => { handleOpen(key); setOpenEmapSection(key); setIsOpen(!isOpen) }}
                    className={`transition-colors accordian-header-btn`}
                  >
                    <div className="navigation-icon mr-3 text-[#b92262]">
                      {
                        openEmapSection == key && isOpen === true ?
                          <span className="minus-sign">
                            <img src="/minus.png" className="w-[15px] h-[15px]" />
                          </span>
                          :
                          <span className="minus-sign">
                            <img src="/plus.png" className="w-[15px] h-[15px]" />
                          </span>
                      }
                    </div>
                    <table className="w-full bg-white rounded-sm contribution-tble">
                      {/* Table body */}
                      <tbody className="text-sm custom-tbody">
                        <tr className="custom-tbl-td-contribution">
                          <td className="w-[25%] whitespace-nowrap text-[#b92262] text-xl font-bold text-left">
                            {`${emp.firstName} ${emp.lastName}`}
                          </td>
                          <td className="w-[25%] p-0 whitespace-nowrap text-[#181818] text-xl text-left">
                            {emp.designation}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </AccordionHeader>
                  {emp.isFinanceDepartmentRecord ? null :
                    <button
                      onClick={(e) => {
                        props.empModalOnOff(true, emp.individualMemberId, false);
                        // props.isEditable(true);
                        props.setAddEditModel(true);
                        e.stopPropagation();
                      }}
                      className="underline text-xl text-[#b92262] plush-btn absolute font-medium right-[65px] top-[13px]"
                    >
                      Edit Profile
                    </button>
                  }
                </div>
                <AccordionBody className="pt-0 text-base font-normal mb-16">
                  <div className="tab-content leading-normal px-12">
                    <h2 className="text-xl text-[#181818] 2xl:mb-1 flex items-center">
                      <span className="w-[15%]">Email:</span>{" "}
                      <span className="ml-5">{emp.email}</span>
                    </h2>
                    <h2 className="text-xl text-[#181818] 2xl:mb-1 lg:mb-5 flex items-center">
                      <span className="w-[15%]">Contact Number:</span>{" "}
                      <span className="ml-5">{emp.contactNo}</span>
                    </h2>
                  </div>
                </AccordionBody>
              </Accordion>
            )
          })
        }
      </div>
      <AddEditOrganisationEmployee
        salutation={props.salutation}
        jobFunctionList={props.jobFunctionList}
        organisationRolesList={props.organisationRolesList}
        employeeDetails={props.employeeDetails}
        setOrganisationEmployeeModal={props.setOrganisationEmployeeModal}
        handleChange={props.handleChange}
        setFilterParameters={props.setFilterParameters}
        handleChangeMultiDropdown={props.handleChangeMultiDropdown}
        ediCorporateEmployeeDetails={props.ediCorporateEmployeeDetails}
        validStateOrgEmployee={props.validStateOrgEmployee}
        validStateSearchByEmail={props.validStateSearchByEmail}
        isCreateNewOrgRoleForEmployee={props.isCreateNewOrgRoleForEmployee}
        validate={props.validate}
        searchIndividualMemberByEmailForOrgRole={props.searchIndividualMemberByEmailForOrgRole}
        deleteEmployee={props.deleteEmployee}
        isEmployeeModalEditable={props.isEmployeeModalEditable}
        validateField={props.validateField}
        onClose={(e) => {
          e.stopPropagation();
          props.empModalOnOff(false);
        }}
        setModalOpen={() => props.empModalOnOff(false)}
      />
    </>
  );
}
export default EmployeeList;