import React from 'react'
import DropdownSelect from '../../dropdown/Dropdown'
import DatePicker from '../../datePicker/DatePicker'
import TextInput from "../../UIComponent/TextInput";
import moment from "moment";


function IndividualaccountInformation(props) {
  return (
    <>
      <div>
        <div className="grid grid-cols-12 gap-6 w-full p-[25px]">
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Account Status
                </h2>
                <DropdownSelect
                   disabled={true}
                  drpIdentity={"Account_Status"}
                  optionArray={props.status}
                  setFilterParameters={props.setFilterParameters}
                  value={props.siisIndividualAccountInformation.accountStatus ? props.siisIndividualAccountInformation.accountStatus : 7}
                />
                {/* <Validation error={props.validState.error.isAccountActive} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Reasons for Leaving
                </h2>
                <DropdownSelect
                  drpIdentity={"Reason_For_Leaving"}
                  optionArray={props.reason}
                  setFilterParameters={props.setFilterParameters}
                  value={props.siisIndividualAccountInformation.deletionReasonId ? props.siisIndividualAccountInformation.deletionReasonId : 0}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Joined Date
                </h2>
                <div className="">
                  <DatePicker
                    name="individualJoinedDate"
                    handleChange={props.handleChange}
                    identity="SIISInformation"
                    value={props.siisIndividualAccountInformation.individualJoinedDate ? moment(props.siisIndividualAccountInformation.individualJoinedDate).format("yyyy-MM-DD") : ''}
                  />
                </div>
                {/* <Validation error={props.validState.error.corporateJoinedDate} /> */}
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Updated Date
                </h2>
                  {props.siisIndividualAccountInformation.updatedAt === "" || props.siisIndividualAccountInformation.updatedAt === null ?
                    <TextInput
                      disabled={true}
                      placeholder=""
                      name="updatedAt"
                      value="None"
                      identity="BasicInformation"
                    />
                    :
                    <DatePicker
                      isDisable={true}
                      name="updatedAt"
                      handleChange={props.handleChange}
                      identity="updatedAt"
                      value={moment(props.siisIndividualAccountInformation.updatedAt).format("yyyy-MM-DD")}
                    />
                  }
              </div>
            </div>
          </div>
          <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <h2 className="text-xl text-[#181818] font-bold 2xl:mb-1">
                  Updated by
                </h2>
                <DropdownSelect
                  disabled={true}
                  drpIdentity={"Updated_By"}
                  optionArray={props.updated_By}
                  setFilterParameters={props.setFilterParameters}
                  value={props.siisIndividualAccountInformation.updatedBy ? props.siisIndividualAccountInformation.updatedBy : 1}
                />
              </div>
            </div>
          </div>
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
            <div className="flex items-center justify-end">
              <button
                className="btn btn-pink text-xl text-white font-semibold"
                onClick={() => props.addEditIndividualSIISInformation()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default IndividualaccountInformation