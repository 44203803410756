export const DeleteButtonIcon = () => (
  <svg
    className="menu-svg"
    width="27"
    height="27"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 135 135"
  >
    <title>Delete</title>
    <g transform="translate(0.000000,135.000000) scale(0.100000,-0.100000)">
      <path
        d="M504 1336 c-32 -14 -64 -67 -64 -105 0 -20 -6 -21 -147 -23 -124 -3 -148 -5 -148 -18 0 -10 12 -16 32 -18 28 -3 33 -7 33 -30 0 -72 41 -1021 46 -1049 3 -19 17 -42 35 -57 l31 -26 354 0 c386 0 398 2 422 56 9 18 19 196 33
                      543 11 284 23 526 25 539 3 17 11 22 34 22 23 0 30 4 30 20 0 19 -7 20 -168 20 l-168 0 -11 43 c-7 23 -24 53 -38 67 -25 24 -33 25 -163 27 -100 2 -145 -1 -168 -11z m305 -43 c11 -10 24 -33 27 -50 l7 -33 -186 0 -186 0 10 33 c17 60
                      36 67 179 67 107 0 131 -3 149 -17z m305 -225 c-3 -57 -14 -300 -23 -541 -16 -390 -20 -440 -36 -457 -17 -19 -32 -20 -376 -20 l-359 0 -14 23 c-12 18 -19 120 -36 497 -11 261 -20 503 -20 538 l0 62 435 0 435 0 -6 -102z"
        fill="#181818"
      />
      <path
        d="M436 964 c-10 -25 -7 -747 3 -763 27 -42 31 8 31 390 0 326 -2 389 -14 389 -8 0 -17 -7 -20 -16z"
        fill="#181818"
      />
      <path
        d="M665 968 c-3 -7 -4 -184 -3 -393 3 -330 5 -380 18 -380 13 0 15 50 15 389 0 303 -3 390 -12 393 -7 3 -15 -1 -18 -9z"
        fill="#181818"
      />
      <path
        d="M895 968 c-3 -7 -4 -184 -3 -393 3 -330 5 -380 18 -380 13 0 15 50 15 389 0 303 -3 390 -12 393 -7 3 -15 -1 -18 -9z"
        fill="#181818"
      />
    </g>
  </svg>
);

export const EditButtonIcon = () => (
  <svg
    className="menu-svg"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 88 88"
  >
    <title>Edit</title>
    <g transform="translate(0.000000,88.000000) scale(0.100000,-0.100000)">
      <path
        d="M645 871 c-11 -5 -142 -132 -290 -282 l-271 -274 -41 -145 c-23 -80 -38 -149 -34 -153 5 -4 74 11 153 34 l146 41 282 283 c332 333 318 302 197 422 -72 72 -105 89 -142 74z m123 -105 c40 -41 72 -79 72 -85 0 -16 -90 -111
                            -104 -111 -6 0 -49 38 -96 85 l-84 85 49 50 c28 29 58 50 70 50 14 0 49 -28 93 -74z m-198 -91 l34 -36 -191 -191 -191 -191 -16 32 c-10 19 -24 31 -36 31 -11 0 -20 3 -20 7 0 9 372 383 380 383 4 0 21 -16 40 -35z m103 -103 l37 -38
                            -194 -187 c-107 -103 -197 -187 -200 -187 -3 0 -6 8 -6 19 0 11 -12 23 -30 31 -16 7 -30 15 -30 19 0 7 373 381 380 381 3 0 23 -17 43 -38z m-488 -320 c12
                            -21 36 -45 58 -57 32 -17 37 -24 35 -49 -3 -25 -9 -31 -48 -42 l-45 -14 -51 53 -51 52 14 48 c12 41 18 47 41 47 21 0 32 -8 47 -38z m-51 -171 c-3 -3 -23 -11 -45 -17 -44 -13 -45 -12 -28 50 l11 38 34 -33 c18 -18 31 -35 28 -38z"
        fill="#181818"
      />
    </g>
  </svg>
);

export const CancelButtonIcon = () => (
  <svg
    className="menu-svg"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 122.88 122.88"
  >
    <title>Cancel</title>
    <path
      className="cls-1"
      d="M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0Z"
      fill="#181818"
    />
    <path
      className="cls-2"
      d="M35.38,49.72c-2.16-2.13-3.9-3.47-1.19-6.1l8.74-8.53c2.77-2.8,4.39-2.66,7,0L61.68,46.86,73.39,35.15c2.14-2.17,3.47-3.91,6.1-1.2L88,42.69c2.8,2.77,2.66,4.4,0,7L76.27,61.44,88,73.21c2.65,2.58,2.79,4.21,0,7l-8.54,8.74c-2.63,2.71-4,1-6.1-1.19L61.68,76,49.9,87.81c-2.58,2.64-4.2,2.78-7,0l-8.74-8.53c-2.71-2.63-1-4,1.19-6.1L47.1,61.44,35.38,49.72Z"
      fill="#181818"
    />
  </svg>
);

export const SettingsButtonIcon = () => (
  <svg
    className="menu-svg"
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 478 478"
  >
    <title>Setting</title>
    <path
      d="M454.2,189.101l-33.6-5.7c-3.5-11.3-8-22.2-13.5-32.6l19.8-27.7c8.4-11.8,7.1-27.9-3.2-38.1l-29.8-29.8
			c-5.6-5.6-13-8.7-20.9-8.7c-6.2,0-12.1,1.9-17.1,5.5l-27.8,19.8c-10.8-5.7-22.1-10.4-33.8-13.9l-5.6-33.2
			c-2.4-14.3-14.7-24.7-29.2-24.7h-42.1c-14.5,0-26.8,10.4-29.2,24.7l-5.8,34c-11.2,3.5-22.1,8.1-32.5,13.7l-27.5-19.8
			c-5-3.6-11-5.5-17.2-5.5c-7.9,0-15.4,3.1-20.9,8.7l-29.9,29.8c-10.2,10.2-11.6,26.3-3.2,38.1l20,28.1
			c-5.5,10.5-9.9,21.4-13.3,32.7l-33.2,5.6c-14.3,2.4-24.7,14.7-24.7,29.2v42.1c0,14.5,10.4,26.8,24.7,29.2l34,5.8
			c3.5,11.2,8.1,22.1,13.7,32.5l-19.7,27.4c-8.4,11.8-7.1,27.9,3.2,38.1l29.8,29.8c5.6,5.6,13,8.7,20.9,8.7c6.2,0,12.1-1.9,17.1-5.5
			l28.1-20c10.1,5.3,20.7,9.6,31.6,13l5.6,33.6c2.4,14.3,14.7,24.7,29.2,24.7h42.2c14.5,0,26.8-10.4,29.2-24.7l5.7-33.6
			c11.3-3.5,22.2-8,32.6-13.5l27.7,19.8c5,3.6,11,5.5,17.2,5.5l0,0c7.9,0,15.3-3.1,20.9-8.7l29.8-29.8c10.2-10.2,11.6-26.3,3.2-38.1
			l-19.8-27.8c5.5-10.5,10.1-21.4,13.5-32.6l33.6-5.6c14.3-2.4,24.7-14.7,24.7-29.2v-42.1
			C478.9,203.801,468.5,191.501,454.2,189.101z M451.9,260.401c0,1.3-0.9,2.4-2.2,2.6l-42,7c-5.3,0.9-9.5,4.8-10.8,9.9
			c-3.8,14.7-9.6,28.8-17.4,41.9c-2.7,4.6-2.5,10.3,0.6,14.7l24.7,34.8c0.7,1,0.6,2.5-0.3,3.4l-29.8,29.8c-0.7,0.7-1.4,0.8-1.9,0.8
			c-0.6,0-1.1-0.2-1.5-0.5l-34.7-24.7c-4.3-3.1-10.1-3.3-14.7-0.6c-13.1,7.8-27.2,13.6-41.9,17.4c-5.2,1.3-9.1,5.6-9.9,10.8l-7.1,42
			c-0.2,1.3-1.3,2.2-2.6,2.2h-42.1c-1.3,0-2.4-0.9-2.6-2.2l-7-42c-0.9-5.3-4.8-9.5-9.9-10.8c-14.3-3.7-28.1-9.4-41-16.8
			c-2.1-1.2-4.5-1.8-6.8-1.8c-2.7,0-5.5,0.8-7.8,2.5l-35,24.9c-0.5,0.3-1,0.5-1.5,0.5c-0.4,0-1.2-0.1-1.9-0.8l-29.8-29.8
			c-0.9-0.9-1-2.3-0.3-3.4l24.6-34.5c3.1-4.4,3.3-10.2,0.6-14.8c-7.8-13-13.8-27.1-17.6-41.8c-1.4-5.1-5.6-9-10.8-9.9l-42.3-7.2
			c-1.3-0.2-2.2-1.3-2.2-2.6v-42.1c0-1.3,0.9-2.4,2.2-2.6l41.7-7c5.3-0.9,9.6-4.8,10.9-10c3.7-14.7,9.4-28.9,17.1-42
			c2.7-4.6,2.4-10.3-0.7-14.6l-24.9-35c-0.7-1-0.6-2.5,0.3-3.4l29.8-29.8c0.7-0.7,1.4-0.8,1.9-0.8c0.6,0,1.1,0.2,1.5,0.5l34.5,24.6
			c4.4,3.1,10.2,3.3,14.8,0.6c13-7.8,27.1-13.8,41.8-17.6c5.1-1.4,9-5.6,9.9-10.8l7.2-42.3c0.2-1.3,1.3-2.2,2.6-2.2h42.1
			c1.3,0,2.4,0.9,2.6,2.2l7,41.7c0.9,5.3,4.8,9.6,10,10.9c15.1,3.8,29.5,9.7,42.9,17.6c4.6,2.7,10.3,2.5,14.7-0.6l34.5-24.8
			c0.5-0.3,1-0.5,1.5-0.5c0.4,0,1.2,0.1,1.9,0.8l29.8,29.8c0.9,0.9,1,2.3,0.3,3.4l-24.7,34.7c-3.1,4.3-3.3,10.1-0.6,14.7
			c7.8,13.1,13.6,27.2,17.4,41.9c1.3,5.2,5.6,9.1,10.8,9.9l42,7.1c1.3,0.2,2.2,1.3,2.2,2.6v42.1H451.9z"
      fill="#939393"
    />
    <path
      d="M239.4,136.001c-57,0-103.3,46.3-103.3,103.3s46.3,103.3,103.3,103.3s103.3-46.3,103.3-103.3S296.4,136.001,239.4,136.001
			z M239.4,315.601c-42.1,0-76.3-34.2-76.3-76.3s34.2-76.3,76.3-76.3s76.3,34.2,76.3,76.3S281.5,315.601,239.4,315.601z"
      fill="#939393"
    />
  </svg>
);

export const CloseButtonIcon = () => (
  <svg className="w-4 h-4 fill-current">
    <title>Close</title>
    <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" fill="#AA3361"></path>
  </svg>
);
