import moment from "moment/moment";
import { isNullString } from "../../../utils/Utils";
import React, { useState } from "react";
import GenerateCreditNote from "./GenerateCreditNote";
import { formatPrice } from "../../common/FormatePrice";

function FinancialsRecord(props) {
  const [generateCreditNote, setGenerateCreditNote] = useState(false);
  const [isInvoiceCancel, setIsInvoiceCancel] = useState(false);
  return (
    <>
      {props.corporateFinanceInfo &&
        props.corporateFinanceInfo.length > 0 &&
        props.corporateFinanceInfo.map((value, key) => {
          return (
            <React.Fragment key={key}>
              <div className=" bg-white border-b border-[#f3f3f3] pb-7 p-[25px]">
                <div className="grid grid-cols-12 gap-6 w-full ">
                  <div className="2xl:col-span-9 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-4 items-center">
                    <div className="grid grid-cols-12 gap-2">
                      <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                        <div className="flex items-start justify-between">
                          <div className="left-side">
                            <h2 className="text-xl theme-color 2xl:mb-3">
                              Invoice Number :{" "}
                              <span>{value.membershipPaymentInvoiceNo}</span>
                            </h2>
                            <h2 className="text-xl theme-color 2xl:mb-3">
                              Date :{" "}
                              <span>
                                {moment(value.paymentDate).format(
                                  "DD MMM YYYY"
                                )}
                              </span>
                            </h2>
                            {/* <h2 className="text-xl theme-color 2xl:mb-3">
                              Payment Method : <span>{value.paymentVia}</span>
                            </h2> */}
                            <h2 className="text-xl theme-color 2xl:mb-3">
                              Payment Status :{" "}
                              <span>{value.paymentStatus}</span>
                            </h2>
                          </div>
                          <div className="right-side">
                            <h2 className="text-xl theme-color 2xl:mb-3">
                              Promotion Code :{" "}
                              <span className="">
                                {isNullString(value.voucherCode) === ""
                                  ? "N.A"
                                  : value.voucherCode}
                              </span>
                            </h2>
                            <h2 className="text-xl theme-color 2xl:mb-3">
                              Amount (Billable) :{" "}
                              <span>
                                {/* {`$${parseFloat(value.totalAmount).toFixed(2)}`} */}
                             {formatPrice(value.totalAmount)}
                              </span>
                            </h2>
                            <h2 className="text-xl theme-color ">
                              Amount (Paid) :{" "}
                              <span>
                                {value.paymentStatus === "Success" ? (
                                  <>
                                    {/* {`$${parseFloat(value.totalAmount).toFixed(2)}`} */}
                                    {formatPrice(value.totalAmount)}
                                  </>
                                ) : (
                                  <>
                                    {value.paidPriceByReconciliation ? (
                                      <>
                                        {/* {`$${parseFloat(value.paidPriceByReconciliation).toFixed(2)}`} */}
                                       {formatPrice(value.paidPriceByReconciliation)}
                                      </>
                                    ) : (
                                      "$0.00"
                                    )}
                                  </>
                                )}{" "}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="xl:col-span-12 lg:col-span-12 col-span-12"></div>
                    </div>
                  </div>
                  <div className="2xl:col-span-3 lg:col-span-3 col-span-12">
                    <div className="relative flex">
                      <div className="right-status-label absolute 2xl:top-[8px] 2xl:right-[-25px] xl:top-[8px] xl:right-[-25px]  lg:top-[10px] lg:right-[-20px]">
                        <span
                          className={`lg:text-[16px] text-[14px] text-white font-semibold bg-[#7a9763] 2xl:px-5 md:px-3 2xl:py-3 lg:px-3 lg:py-3 md:px-3 md:py-3 py-3 px-3 ${
                            value.paymentStatus === "Success"
                              ? "bg-[#7a9763]"
                              : "bg-[#ffae00]"
                          }`}
                        >
                          {value.paymentStatus === "Success"
                            ? "Paid"
                            : value.paymentStatus}
                        </span>
                      </div>
                      <div className="right-btn mt-20 w-full">
                        <div className="flex items-center justify-end mb-5">
                          <button
                            className="2xl:px-7 lg:px-5 py-2 btn btn-pink-border text-xl border"
                            onClick={() =>
                              props.getCorporateFinanceReceiptInvoice(
                                value.membersReceiptId,
                                false
                              )
                            }
                          >
                            <span className="text-xl font-semibold tracking-normal underline">
                              View Invoice
                            </span>
                          </button>
                        </div>

                        {value.isReceiptGenerated === true &&
                        (value.paymentStatus === "Success" ||
                          value.paymentStatus === "Pending Cancellation") ? (
                          <>
                            <div className="flex items-center justify-end mb-5">
                              <button
                                className="2xl:px-7 lg:px-5 py-2 btn btn-pink-border text-xl border "
                                onClick={() =>
                                  props.getCorporateFinanceReceiptInvoice(
                                    value.membershipPaymentId,
                                    true
                                  )
                                }
                              >
                                <span className="text-xl font-semibold tracking-normal underline">
                                  View Receipt
                                </span>
                              </button>
                            </div>
                          </>
                        ) : null}
                        {value.isReceiptGenerated === true &&
                        value.creditNotePath !== null &&
                        value.paymentStatus === "Cancelled" ? (
                          <>
                            <div className="flex items-center justify-end mb-5">
                              <button
                                className="2xl:px-7 lg:px-5 py-2 btn btn-pink-border text-xl border "
                                onClick={() =>
                                  props.getCorporateFinanceReceiptInvoice(
                                    value.membershipPaymentId,
                                    null
                                  )
                                }
                              >
                                <span className="text-xl font-semibold tracking-normal underline">
                                  View Credit Note
                                </span>
                              </button>
                            </div>
                          </>
                        ) : null}
                        {value.isReceiptGenerated === true &&
                        value.paymentStatus === "Success" ? (
                          <>
                            {(value.membershipPaymentTypeId === 1 ||
                              value.membershipPaymentTypeId === 3 ||
                              value.membershipPaymentTypeId === 2) &&
                            value.isAvailableForCreditNote === true ? (
                              <div className="flex items-center justify-end">
                                <button
                                  className="2xl:px-[15px] lg:px-[10px] py-2 btn btn-pink text-white"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setGenerateCreditNote(true);
                                    setIsInvoiceCancel(false);
                                    props.getCreditNoteInfoForCorporateMember(
                                      value.membershipPaymentId,
                                      value.paymentStatus,
                                      value.isReceiptGenerated
                                    );
                                    props.getCancellationReasonForCreditNote();
                                    props.addEditCreditNote.membershipPaymentId =
                                      value.membershipPaymentId;
                                  }}
                                >
                                  <span className="text-xl text-white font-semibold tracking-normal">
                                    Generate Credit Note
                                  </span>
                                </button>
                              </div>
                            ) : null}
                          </>
                        ) : value.paymentStatus === "Pending" &&
                          value.membershipPaymentTypeId !== 6 ? (
                          <>
                            <div className="flex items-center justify-end">
                              <button
                                className="2xl:px-7 lg:px-5 py-2 btn btn-pink-border text-xl border "
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setGenerateCreditNote(true);
                                  setIsInvoiceCancel(true);
                                  props.getCreditNoteInfoForCorporateMember(
                                    value.membershipPaymentId,
                                    value.paymentStatus,
                                    value.isReceiptGenerated
                                  );
                                  props.getCancellationReasonForCreditNote();
                                  props.addEditCreditNote.membershipPaymentId =
                                    value.membershipPaymentId;
                                }}
                              >
                                <span className="text-xl font-semibold tracking-normal underline">
                                  Cancel Invoice
                                </span>
                              </button>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      <div className="add-edite-profile-popup">
        <GenerateCreditNote
          generateCreditNote={generateCreditNote}
          setOpenModal={() => setGenerateCreditNote(false)}
          corporateCreditNotePaymentInfo={props.corporateCreditNotePaymentInfo}
          cancellationReason={props.cancellationReason}
          setFilterParameters={props.setFilterParameters}
          isOtherCancellationReason={props.isOtherCancellationReason}
          addEditCreditNote={props.addEditCreditNote}
          handleChange={props.handleChange}
          addFinanceCreditNote={props.addFinanceCreditNote}
          validate={props.validate}
          validCreditNote={props.validCreditNote}
          validate2={props.validate2}
          validCreditNote2={props.validCreditNote2}
          isInvoiceCancel={isInvoiceCancel}
          isLoading={props.isLoading}
        />
      </div>
    </>
  );
}

export default FinancialsRecord;
